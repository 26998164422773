import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import escConstants from "@shared/constants";
import { useRoot } from "@shared/context/RootProvider";

export interface ICheckAuthProps {
  children: React.ReactNode;
  redirectTo?: string;
}
const CheckAuthElement = ({ redirectTo = "/", children }: ICheckAuthProps) => {
  const token = localStorage.getItem(escConstants.LOCAL_STORAGE_KEY.accessKey);

  const { auth: user } = useRoot();
  const userId = user?._id;

  if (!!token && !!userId) {
    return <Navigate to={redirectTo} replace />;
  }
  return (
    <div>
      {children} || <Outlet />
    </div>
  );
};

export default CheckAuthElement;
