// import Image from "next/image";
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { IfrmaeWrapper } from "./style";
import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";
// import { ImPlay3 } from 'react-icons/im'
interface IProps {
  videoId: string;
  autoPlay?: boolean;
  className?: string;
}

const VideoIframe: React.FC<IProps> = (props) => {
  const { videoId, autoPlay } = props;
  const videoURL = `https://www.youtube.com/embed/${videoId}${
    autoPlay ? "?autoplay=1" : ""
  }`;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const defaultHeight = 495;
  const [videoHeight, setVideoHeight] = useState<number>(
    iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
  );

  const handleChangeVideoWidth = useCallback(() => {
    const ratio =
      window.innerWidth > 990
        ? 1.0
        : window.innerWidth > 522
        ? 1.2
        : window.innerWidth > 400
        ? 1.45
        : 1.85;
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    setVideoHeight(Math.floor(height / ratio));
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleChangeVideoWidth);
    const ratio =
      window.innerWidth > 990
        ? 1.0
        : window.innerWidth > 522
        ? 1.2
        : window.innerWidth > 400
        ? 1.45
        : 1.85;
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    setVideoHeight(Math.floor(height / ratio));
    return function cleanup() {
      window.removeEventListener("resize", handleChangeVideoWidth);
    };
  }, [videoHeight, handleChangeVideoWidth]);

  return (
    <StyledIframe
      ref={iframeRef}
      src={videoURL}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="iframe-video"
      style={{
        borderRadius: "20px",
      }}
    />
  );
};

export default VideoIframe;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 350px;
  max-width: 800px;

  @media (min-width: ${screenSizes.md}) {
    max-width: 100%;
    height: 400px;
  }

  @media (min-width: ${screenSizes.lg}) {
    flex: 1.2;
  }

  @media (min-width: ${screenSizes.xl}) {
    flex: 1.3;
  }
`;
