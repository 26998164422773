import IndividualCardSection from "./individualCardSection";
import solarPNG from "../../../assets/images/homeCardIndividualPage-img/ventilation.png";
import { Typography } from "@shared/designComponents";
import { useTheme } from "styled-components";
import { EnergyfixCTASection } from "../../Shared";

const VentilationCard = () => {
  const theme = useTheme();
  return (
    <div>
      <IndividualCardSection
        imgUrl={solarPNG}
        sn="03"
        title="Ventilation"
        description={
          <>
            <Typography size={"sm"}>
              Ensuring proper ventilation is essential for a healthy living
              environment. Sealing up a drafty house isn&#39;t about preventing
              fresh air exchange; it&#39;s about managing it effectively. MVHR
              systems also regulate moisture levels in kitchens and bathrooms,
              and help neutralize various odors, promoting a more pleasant
              indoor atmosphere.
            </Typography>
          </>
        }
      />
      <EnergyfixCTASection />
    </div>
  );
};

export default VentilationCard;
