import styled from "styled-components";

export const StyledDropdownWrapper = styled.div`
  position: relative;

  .dropdow-trigger {
    position: relative;
    cursor: pointer;
  }

  .dropdown-children {
    position: absolute;
    top: 35px;
    z-index: 1200;
  }
`;
