import styled from "styled-components";

export const CheckBoxStyled = styled.input`
  position: relative;
  &:hover {
    cursor: pointer;
  }

  &::before {
    content: "";
    position: relative;
    top: 0px;
    left: 0px;
    display: block;
    width: 14px;
    height: 14px;
    background: #fff;
  }

  &::after {
    content: "";
    position: relative;
    bottom: 14px;
    left: 0px;
    display: block;
    width: 14px;
    height: 14px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 3px;
    background: #fff;
  }
  &:checked::after {
    border: 1px solid ${({ theme }) => theme.colors.green};
    background: url("/assets/icons/check-green.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const LabelStyled = styled.label`
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
