import { useRef, useState } from "react";
import { useTheme } from "styled-components";
import {
  ColorText,
  Container,
  Flex,
  Image,
  Scroller,
  Typography,
} from "@shared/designComponents";
import { financeData } from "./financeData";
import {
  DescriptionStyled,
  FinanceStyled,
  IndividualFinanceStyled,
  ProviderWrapperStyled,
} from "./style";
import BreadCrumbs from "@shared/designComponents/BreadCrumbs";
import { COST_GRANT_PATH, FINANCE_PATH } from "../../../routes/routePaths";

type ProviderKeyType = keyof typeof financeData.providers;

const breadcrumbs = [
  {
    label: "Cost & Grants",
    link: COST_GRANT_PATH,
  },
  {
    label: "Finance",
    link: `${COST_GRANT_PATH}/${FINANCE_PATH}`,
  },
];

const FinancePage = () => {
  const theme = useTheme();

  const [selectedProvider, setSelectedProvider] =
    useState<ProviderKeyType>("aib");

  const financerWrapperRef = useRef<HTMLDivElement>(null);

  const textColor = theme.colors.gray61;
  const selectedFinancerDescription =
    financeData.providers[selectedProvider].description;

  const refactorText = (text: string) => {
    const percentEuroSignEmphasisRegex = /(\b\d+(?:\.\d+)?%|€\d+(?:,\d+)?\b)/g;
    const linkRegex = /\[\[(.*?)\]\((.*?)\)\]/g;
    return text
      .replace(
        percentEuroSignEmphasisRegex,
        "<span class='strong color'>$1</span>"
      )
      .replace(linkRegex, '<a href="$2" target="_blank">$1</a>');
  };

  return (
    <FinanceStyled>
      <Container marginVertical={50}>
        <BreadCrumbs
          breadcrumbs={breadcrumbs}
          style={{ margin: "0px 0px 30px" }}
        />
        <Typography
          size="_4xl"
          fontWeight="semibold"
          style={{ marginBottom: "12px" }}
        >
          Financing your&nbsp;
          <ColorText color={theme.colors.primary}>
            Home Energy Upgrade
          </ColorText>
        </Typography>
        <Typography color={textColor}>{financeData?.subtitle}</Typography>

        <Flex direction="column" gap="30px" margin="50px 0px">
          {Array.isArray(financeData?.description) &&
            financeData?.description.map((desc) => {
              return (
                <Typography key={desc.substring(0, 30)} color={textColor}>
                  {desc}
                </Typography>
              );
            })}
        </Flex>
        <Typography
          color={textColor}
          fontWeight="medium"
          size="xl"
          style={{ marginBottom: "30px" }}
        >
          Select a <span className="strong color"> Provider </span> to see what
          <span className="strong"> Finance</span> options they offer.
        </Typography>
        <Scroller wrapperRef={financerWrapperRef}>
          <ProviderWrapperStyled ref={financerWrapperRef}>
            {Array.isArray(Object.entries(financeData?.providers)) &&
              Object.entries(financeData?.providers).map(([key, financer]) => {
                return (
                  <IndividualFinanceStyled
                    key={financer.label}
                    direction="column"
                    gap="1rem"
                    $isActive={selectedProvider === financer.key}
                    onClick={() => {
                      setSelectedProvider(key as ProviderKeyType);
                    }}
                  >
                    <div className="finance-image">
                      <Image src={financer.image} />
                    </div>
                    <Typography className="finance-label">
                      {financer.label}
                    </Typography>
                  </IndividualFinanceStyled>
                );
              })}
          </ProviderWrapperStyled>
        </Scroller>

        <Flex margin="30px 0px" gap="18px" direction="column">
          {selectedFinancerDescription &&
          Array.isArray(selectedFinancerDescription) ? (
            selectedFinancerDescription?.map((desc) => {
              return (
                <DescriptionStyled
                  key={desc.substring(0, 30)}
                  dangerouslySetInnerHTML={{
                    __html: refactorText(desc || ""),
                  }}
                ></DescriptionStyled>
              );
            })
          ) : (
            <DescriptionStyled
              dangerouslySetInnerHTML={{
                //@ts-expect-error TODO: fix this
                __html: refactorText(selectedFinancerDescription),
              }}
            ></DescriptionStyled>
          )}
        </Flex>
      </Container>
    </FinanceStyled>
  );
};

export default FinancePage;
