import { useTheme } from "styled-components";
import Container from "@shared/designComponents/Container";
import { ColorText, SectionTitle } from "@shared/designComponents";
import { CardWrapper, CardsContainer, SectionStyled } from "./style";
import Card from "@shared/designComponents/Card";
import { cardsData } from "./cardsData";

const PropertyDocuments = () => {
  const theme = useTheme();
  return (
    <SectionStyled>
      <Container enableMargin={false}>
        <SectionTitle style={{ textAlign: "center" }}>
          <ColorText color={theme.colors.secondary}>Vacant Property</ColorText>{" "}
          Documents
        </SectionTitle>
        <CardsContainer>
          {cardsData.map((cardData) => (
            <CardWrapper key={cardData.heading}>
              <Card
                variant="withoutCountAndButton"
                imgurl={cardData.imgUrl}
                heading={cardData.heading}
                description={cardData.description}
                footer={cardData.footer}
              />
            </CardWrapper>
          ))}
        </CardsContainer>
      </Container>
    </SectionStyled>
  );
};

export default PropertyDocuments;
