import { Typography } from "@shared/designComponents";
import theme from "@shared/theme";
import { ButtonsStyled } from "./style";

export const paymentsTable = {
  header: [
    { name: "Description", label: "description" },
    { name: "Currency", label: "currency" },
    { name: "Amount", label: "amount" },
    { name: "Payment Date", label: "createdAt" },
  ],
  body: [
    [
      {
        label: "work_title",
        value: (
          <div>
            <Typography
              color={theme.colors.footerBlack}
              fontWeight="medium"
              size={"md"}
            >
              New Roof
            </Typography>
          </div>
        ),
      },
      {
        label: "amount",
        value: (
          <div>
            <Typography size={"sm"}>€ 7500.00</Typography>
          </div>
        ),
      },

      {
        label: "status",
        value: (
          <>
            <ButtonsStyled>
              <Typography
                size={"sm"}
                fontWeight="normal"
                color={theme.colors.white}
              >
                In Progress
              </Typography>
            </ButtonsStyled>
          </>
        ),
      },
    ],
    [
      {
        label: "work_title",
        value: (
          <div>
            <Typography
              fontWeight="semibold"
              color={theme.colors.footerBlack}
              size={"xs"}
            >
              TOTAL COST
            </Typography>
          </div>
        ),
      },
      {
        label: "amount",
        value: (
          <div>
            <Typography size={"sm"} color={theme.colors.primary}>
              € 7500.00
            </Typography>
          </div>
        ),
      },
      {
        label: "status",
        value: "",
      },
    ],
    [
      {
        label: "work_title",
        value: (
          <div>
            <Typography
              fontWeight="semibold"
              color={theme.colors.footerBlack}
              size={"xs"}
            >
              TOTAL GRANT RELIEF{" "}
            </Typography>
          </div>
        ),
      },
      {
        label: "amount",
        value: (
          <div>
            <Typography size={"sm"} color={theme.colors.primary}>
              € 21500.00
            </Typography>
          </div>
        ),
      },
      {
        label: "status",
        value: "",
      },
    ],
    [
      {
        label: "work_title",
        value: (
          <div>
            <Typography
              fontWeight="semibold"
              color={theme.colors.footerBlack}
              size={"xs"}
            >
              TOTAL GRANT RELIEF{" "}
            </Typography>
          </div>
        ),
      },
      {
        label: "amount",
        value: (
          <div>
            <Typography size={"sm"} color={theme.colors.error}>
              € -14000.00
            </Typography>
          </div>
        ),
      },
      {
        label: "status",
        value: "",
      },
    ],
  ],
};
