import styled from "styled-components";
import { flexSpaceBetween, selectDisable } from "@shared/style/reusableStyle";
import { Flex } from "@shared/designComponents";

export const FinanceStyled = styled.div`
  & .strong {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    color: ${({ theme }) => theme.colors.secondary};
  }

  & .color {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ProviderWrapperStyled = styled.div`
  ${flexSpaceBetween(20)};
  align-items: start;
  height: calc(100% + 60px);

  max-width: 100%;
  overflow: auto;
  padding: 0px 0px 20px;
`;

export const IndividualFinanceStyled = styled(Flex)<{ $isActive?: boolean }>`
  min-width: 230px;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.7)};
  ${selectDisable};

  & .finance-image {
    transition: all 0.3s;
    border: 4px solid
      ${({ $isActive, theme }) =>
        $isActive ? theme.colors.borderLime : theme.colors.transparent};
  }
  & .finance-label {
    font-weight: ${({ theme, $isActive }) =>
      $isActive ? theme.fontWeights.semibold : theme.fontWeights.normal};
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.secondary : theme.colors.financeLabel};
  }
`;

export const DescriptionStyled = styled.div`
  font-family: "Poppins";
  line-height: 1.6rem;
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.gray61};

  & .strong {
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    color: ${({ theme }) => theme.colors.secondary};
  }

  & .color {
    color: ${({ theme }) => theme.colors.primary};
  }

  & a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
`;
