import { styled } from "styled-components";
import { devices } from "../../theme/device";

export const CarouselContainer = styled.div`
  width: 100%;

  @media ${devices.laptop} {
    width: 70%;
  }
  
  .alice-carousel {
    background-color: ${({ theme }) => theme.colors.gray.light};
    padding: 0 0 17px 20px;
    border-radius: 10px;
    position: relative;

    & .alice-carousel__dots {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .alice-carousel__stage {
    display: flex;
    justify-content: stretch;

    & .alice-carousel__stage-item {
      flex: 1;
      height: auto;
    }
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 10px;
`;

export const CustomDot = styled.div<{ $isActive: boolean }>`
  width: ${({ $isActive }) => ($isActive ? "23px" : "7px")};
  height: 7px;
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.primary : theme.colors.primaryDull};
  border-radius: ${({ $isActive }) => ($isActive ? "5px" : "50%")};
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, width 0.3s;
`;
