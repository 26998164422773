import { Outlet } from "react-router-dom";
import GlobalStyle from "@shared/style/globalStyle";
import RootProvider from "@shared/context/RootProvider";
import { ThemeProvider } from "styled-components";
import theme from "@shared/theme";
import { Provider } from "react-redux";
import store from "./store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "@shared/designComponents/ScrollToTop";

function App() {
  return (
    <RootProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <GlobalStyle />
          <Outlet />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnHover
            theme="light"
            progressStyle={{ background: theme.colors.primary }}
          />
        </ThemeProvider>
      </Provider>
    </RootProvider>
  );
}

export default App;
