import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const PaymentsStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.grayFA};
  padding: 13px;

  @media (min-width: ${screenSizes.md}) {
    padding: 0;
  }
`;

export const ButtonsStyled = styled.div`
  border-radius: 18px;
  height: 31px;
  width: 104px;
  background-color: ${({ theme }) => theme.colors.blue.strong};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${screenSizes.md}) {
    margin: 0 auto;
  }
`;
