import { styled } from "styled-components";
import { screenSizes } from "../../theme/screen";
import { FontWeightType } from "../../theme/fontWeights";
import { SizesType } from "../../theme/sizes";

type TypographySize = keyof SizesType;

export interface ITypography {
  size?: TypographySize | TypographySize[];
  fontFamily?: string;
  fontWeight?: keyof FontWeightType;
  color?: string;
}

export const Tag = styled.div<ITypography>`
  color: ${({ color }) => color};
  font-size: ${({ theme, size }) =>
    Array.isArray(size)
      ? `${theme.sizes[size[0]]}`
      : `${theme.sizes[size || "md"]}`};
  font-weight: ${({ theme, fontWeight }) =>
    theme.fontWeights[fontWeight || "normal"]};
  font-family: ${({ fontFamily }) => fontFamily};
  line-height: normal;
  @media (min-width: ${screenSizes.md}) {
    font-size: ${({ theme, size }) =>
      Array.isArray(size)
        ? `${theme.sizes[size[1]]}`
        : `${theme.sizes[size || "md"]}`};
  }

  @media (min-width: ${screenSizes.lg}) {
    font-size: ${({ theme, size }) =>
      Array.isArray(size)
        ? `${theme.sizes[size[2] || size[1]]}` // Use small size if medium size is not provided.
        : `${theme.sizes[size || "md"]}`};
  }

  @media (min-width: ${screenSizes.xl}) {
    font-size: ${({ theme, size }) =>
      Array.isArray(size)
        ? `${theme.sizes[size[3] || size[2]]}` // Use medium size if large size is not provided.
        : `${theme.sizes[size || "md"]}`};
  }
`;
