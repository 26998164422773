import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestAPI } from "@shared/utils";
import cityConfig from "@shared/apiConfigs/cities"; // Import your city API configuration
import { RootState } from "..";

const initialState = {
  isFetchingCities: false,
  cities: [],
};

export const getAllCities = createAsyncThunk(
  "city/getAllCities",
  async (params, thunkAPI) => {
    try {
      const response = await requestAPI(cityConfig.getAllCities({ params }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch cities");
    }
  }
);

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCities.pending, (state) => {
        state.isFetchingCities = true;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.isFetchingCities = false;
        state.cities = action.payload;
      })
      .addCase(getAllCities.rejected, (state) => {
        state.isFetchingCities = false;
      });
  },
});

export const selectIsFetchingCities = (state: RootState) =>
  state.city.isFetchingCities;

export const selectCities = (state: RootState) => state.city.cities;

export default citySlice.reducer;
