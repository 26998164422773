import React from "react";
import IndividualCardSection from "./individualCardSection";
import renewablePNG from "../../../assets/images/homeCardIndividualPage-img/heat-pump.png";
import { ColorText, Typography } from "@shared/designComponents";
import { useTheme } from "styled-components";
import { EnergyfixCTASection } from "../../Shared";

const RenewableCard = () => {
  const theme = useTheme();
  return (
    <div>
      <IndividualCardSection
        imgUrl={renewablePNG}
        sn="01"
        title="Renewable Energy Heat Pump"
        description={
          <>
            <Typography size={"sm"} style={{ marginBottom: 20 }}>
              Enhance the coziness of your living space by upgrading to a heat
              pump, which not only improves your homes comfort but also slashes
              heating expenses, lowers energy consumption, and minimizes
              greenhouse gas emissions.
            </Typography>
            <Typography size={"sm"}>
              Take advantage of the SEAI grant, offering funds ranging from
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}> €3,500 </ColorText>
              </Typography>
              to
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}> €6,500</ColorText>
              </Typography>
              , to make this eco-friendly transition.
            </Typography>
          </>
        }
      />
      <EnergyfixCTASection />
    </div>
  );
};

export default RenewableCard;
