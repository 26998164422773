import styled from "styled-components";
import { devices } from "@shared/theme/device";

export const MainWrapperStyle = styled.div`
  display: block;
  .large-device-content {
    position: sticky;
    top: 100px;
    display: none;
    height: 50vh;
    margin-top: 50px;

    & :nth-child(4) {
      text-align: start;
    }

    @media (${devices.laptop}) {
      display: block;
    }
  }
  & .section-title {
    margin-bottom: 40px;
  }
  & .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .title-content {
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
  }

  @media (${devices.laptop}) {
    display: grid;
    grid-template-columns: 340px 1fr;
    gap: 0 41px;
  }
`;
export const CustomerCharterWrapperStyle = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    min-height: 232px;
    width: 100%;

    @media (${devices.laptop}) {
      display: none;
    }

    & :nth-child(4) {
      text-align: start;
    }
  }
`;
