import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const SectionWrapperStyled = styled.section`
  background-color: ${({ theme }) => theme.colors.grayFA};
`;

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 36px;
  gap: 30px;

  @media (min-width: ${screenSizes.lg}) {
    flex-direction: row;
    gap: 100px;
    justify-content: center;
    align-content: center;
  }
`;

export const LeftStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  @media (min-width: ${screenSizes.lg}) {
    max-width: 300px;
    align-items: normal;
    justify-content: flex-start;
  }
`;
export const RightStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const FormSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.border};
    }
  }
`;
