import moment from 'moment';

export const humanReadableDate = (timestamp: any, format = 'Do MMM, YYYY') => {
  const readableFormat = moment(timestamp).format(format);
  return readableFormat;
};

export const humanReadableDateWithTime = (
  timestamp: string | Date,
  format = 'MMM Do, YYYY hh:mm A'
) => {
  const readableFormat = moment(timestamp).format(format);
  return readableFormat;
};

export const humanReadableDateDifference = (
  timestamp: string | Date,
  format = 'MMM Do, YYYY'
) => {
  const now = moment();
  const targetDate = moment(timestamp);
  const diffInSeconds = now.diff(targetDate, 'seconds');
  if (diffInSeconds > 0) {
    return (
      moment.duration(diffInSeconds, 'seconds').humanize({ d: 7, w: 4 }) +
      ' ago'
    );
  }
  return targetDate.format(format);
};

const mapDaysWithIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const mapFullDaysWithIndex = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const getMonthsDates = (year: number, month: number) => {
  const reqDate = new Date(`${year}/${month}/1`);
  const lastDateOfMonth = moment(reqDate).daysInMonth();
  const startDayOfMonth = moment(reqDate).day();

  return Array.from({ length: lastDateOfMonth }, (_, idx) => ({
    date: idx + 1,
    day: mapDaysWithIndex[(startDayOfMonth + idx) % 7],
    fullDay: mapFullDaysWithIndex[(startDayOfMonth + idx) % 7],
  }));
};
