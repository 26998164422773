import styled from "styled-components";
import { devices } from "@shared/theme/device";

export const MainWrapperStyle = styled.div`
  display: block;
  .large-device-content {
    position: sticky;
    top: 100px;
    display: none;
    height: 50vh;
    margin-top: 50px;
    @media (${devices.laptop}) {
      display: block;
    }
  }
  & .section-title {
    margin-bottom: 40px;
  }
  & .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .title-content {
      display: flex;
      gap: 20px;
      flex-direction: column;

      & .policy-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        & .single-policy {
          display: flex;
          gap: 12px;
          flex-direction: column;

          & .img-content {
            display: flex;
            align-items: center;
            gap: 18px;
          }

          & .title {
            display: flex;
            gap: 5px;
          }
        }
      }
    }
  }

  @media (${devices.laptop}) {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 0 120px;
  }
`;
export const TermsWrapperStyle = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    min-height: 232px;
    width: 100%;

    @media (${devices.laptop}) {
      display: none;
    }
  }
`;
