import styled from "styled-components";
import Typography from "@shared/designComponents/Typography";
import { devices } from "@shared/theme/device";

export const RetrofitBannerWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const RetrofitBannerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${devices.laptop}) {
    flex-direction: row-reverse;
    gap: 48px;
    align-items: start;
  }

  & .img-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    img {
      width: 100%;
    }
    @media (${devices.laptop}) {
      img {
        width: auto;
        max-width: 196px;

        &:last-child {
          max-width: 404px;
        }
      }
    }

    & :last-child {
      grid-column: 1 / -1;
      grid-row: auto;
    }
  }

  & .container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Description = styled(Typography)`
  text-align: justify;
`;
