import Table from "@shared/designComponents/Table";
import theme from "@shared/theme";
import { contractTable } from "./grantsData";
import { ContractStyled } from "./style";
import { DataType } from "..";

const ContractTab = ({ data }: { data: DataType[] }) => {
  return (
    <ContractStyled>
      <Table
        data={{
          header: contractTable.header,
          body: data,
        }}
        style={{ backgroundColor: theme.colors.grayFA }}
      />
    </ContractStyled>
  );
};

export default ContractTab;
