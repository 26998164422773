import styled from "styled-components";
import { devices } from "../../theme/device";

export const CardWrapperStyled = styled.div<{
  $variant: "default" | "withoutCountAndButton";
}>`
  background-color: transparent;
  width: ${({ $variant }) => ($variant === "default" ? "240px" : "286px")};
  padding-right: 40px;
  padding-top: 20px;
  height: 100%;

  @media ${devices.mobileXS} {
    padding-right: 20px;
  }
`;

export const CardStyled = styled.div<{
  $variant: "default" | "withoutCountAndButton";
}>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: ${({ $variant }) => ($variant === "default" ? "16px" : "0")};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  & .card-heading {
    font-weight: 600;
    font-size: 16px;
    margin: 16px 0;
  }

  & .card-description {
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 20px;
    color: #616161;
    /* height: 70px; */
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ImageWrapperStyled = styled.div<{
  $variant: "default" | "withoutCountAndButton";
}>`
  background-color: #f5faff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
`;

export const CountStyled = styled.div`
  height: 33px;
  width: 33px;
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  color: white;
  text-align: center;
  right: -10px;
  top: -10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const ImageStyled = styled.img`
  max-width: 60px;
`;

export const ContentWrapperStyled = styled.div<{
  $variant: "default" | "withoutCountAndButton";
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $variant }) =>
    $variant === "default" ? "space-between" : "flex-start"};
`;
