import {
  FormSectionWrapper,
  LeftStyled,
  RightStyled,
  SectionWrapperStyled,
  WrapperStyled,
} from "./style";
import {
  Button,
  ColorText,
  Input,
  Modal,
  SectionTitle,
  Typography,
} from "@shared/designComponents";
import { Form, Formik } from "formik";
import { validation } from "./validation";
import { useTheme } from "styled-components";
import { ReactComponent as MailIcon } from "../../../assets/icons/Mail.svg";
import { requestAPI } from "@shared/utils";
import faqConfig from "@shared/apiConfigs/faqs";
import { toast } from "react-toastify";
import { useState } from "react";

const AskQuestion = () => {
  const theme = useTheme();
  const [isSubmit, setIsSubmit] = useState(false);
  const handleSubmit = async (
    values: {
      email: string;
      subject: string;
      message: string;
    },
    { resetForm }: { resetForm: () => void }
  ) => {
    try {
      await requestAPI(faqConfig.submitQuery(values));
      resetForm();
      setIsSubmit(true);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };
  return (
    <SectionWrapperStyled>
      <WrapperStyled>
        <LeftStyled>
          <Typography size={["sm", "xl", "xl"]} fontWeight="medium">
            <ColorText color={theme.colors.primary}>EnergyFix</ColorText>
            <ColorText color={theme.colors.secondary}> Office Hours</ColorText>
          </Typography>
          <Typography size={["xs", "sm", "sm"]}>
            Monday to Friday: 9am-5pm
          </Typography>
          <Typography size="xs">
            <span style={{ color: "red" }}>*</span>We endeavor to reply to all
            enquires within 3 business days.
          </Typography>
        </LeftStyled>
        <RightStyled>
          <Typography
            variant="h2"
            size={["xl", "_2xl", "_2xl"]}
            color={theme.colors.secondary}
            fontWeight="medium"
          >
            Still have a questions ?
          </Typography>
          <Typography size="sm">
            Email us and{" "}
            <ColorText color={theme.colors.primary}>EnergyFix</ColorText>{" "}
            customer assistant will answer you shortly
          </Typography>

          <Formik
            initialValues={{
              email: "",
              subject: "",
              message: "",
            }}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={validation}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              touched,
              errors,
              isValid,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormSectionWrapper>
                  <Typography size="xs" color={theme.colors.gray61}>
                    Email Address
                  </Typography>
                  <Input
                    name="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    iconRight={<MailIcon />}
                    placeholder="Enter your email address"
                  />
                  {touched.email && errors.email && (
                    <Typography size="xs" color={theme.colors.red.strong}>
                      {errors.email}
                    </Typography>
                  )}
                </FormSectionWrapper>
                <FormSectionWrapper>
                  <Typography size="xs" color={theme.colors.gray61}>
                    Subject
                  </Typography>
                  <Input
                    placeholder="Enter your subject"
                    value={values.subject}
                    name="subject"
                    onChange={(e) => setFieldValue("subject", e.target.value)}
                  />
                  {touched.subject && errors.subject && (
                    <Typography size="xs" color={theme.colors.red.strong}>
                      {errors.subject}
                    </Typography>
                  )}
                </FormSectionWrapper>
                <FormSectionWrapper>
                  <Typography size="xs" color={theme.colors.gray61}>
                    Message
                  </Typography>
                  <Input
                    as="textarea"
                    placeholder="Type your message"
                    name="message"
                    value={values.message}
                    onChange={(e) => setFieldValue("message", e.target.value)}
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: 400,
                    }}
                  />
                  {touched.message && errors.message && (
                    <Typography size="xs" color={theme.colors.red.strong}>
                      {errors.message}
                    </Typography>
                  )}
                </FormSectionWrapper>
                <Button
                  fullWidth
                  htmlType="submit"
                  isDisabled={!isValid || !dirty}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
          <Modal open={isSubmit} onClose={() => setIsSubmit(false)}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <img src="/assets/icons/successTick.svg" />
              <SectionTitle style={{ fontSize: "20px" }}>
                Your <ColorText color={theme.colors.primary}>Message</ColorText>{" "}
                sent successfully
              </SectionTitle>
            </div>
          </Modal>
        </RightStyled>
      </WrapperStyled>
    </SectionWrapperStyled>
  );
};

export default AskQuestion;
