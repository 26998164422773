import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface ImageWithDefaultProps extends React.ComponentPropsWithoutRef<"img"> {
  src: string;
  fallbackSrc?: string;
  errorSrc?: string;
  alt?: string;
  className?: string;
  style?: React.CSSProperties;
}

type StylePropsType = {
  objectFit?: React.CSSProperties["objectFit"];
  radius?: React.CSSProperties["borderRadius"];
  height?: React.CSSProperties["height"];
  width?: React.CSSProperties["width"];
};

const defaultImage =
  "https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg?cs=srgb&dl=pexels-binyamin-mellish-106399.jpg&fm=jpg";

const brokenImagePLaceholder =
  "https://via.placeholder.com/500?text=Image+Not+Supported";

const noImagePLaceholder =
  "https://via.placeholder.com/500?text=Image+Not+Found";

const Image: React.FC<ImageWithDefaultProps & StylePropsType> = ({
  src,
  fallbackSrc = noImagePLaceholder,
  errorSrc = brokenImagePLaceholder,
  alt,
  className,
  ...restProps
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [imageLoadingError, setImageLoadingError] = useState(false);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const handleImageError = () => {
    setImageSrc(errorSrc);
    setImageLoadingError(true);
  };

  if (!imageSrc) {
    return (
      <StyledImage
        src={fallbackSrc}
        onError={handleImageError}
        alt={alt}
        className={`${className} noImage`}
        loading="lazy"
        {...restProps}
      />
    );
  }
  return (
    <StyledImage
      src={imageSrc}
      onError={handleImageError}
      alt={alt}
      className={`${className} ${imageLoadingError ? "noImage" : ""}`}
      loading="lazy"
      {...restProps}
    />
  );
};

const StyledImage = styled.img<StylePropsType>`
  height: ${({ height }) => height || "100%"};
  width: ${({ width }) => width || "100%"};
  object-fit: ${({ objectFit }) => objectFit || "cover"};
  border-radius: ${({ radius }) => radius || "0px"};
  filter: blur(0px); /* Initial blur effect */
  animation: fadeIn 1.5s ease-in-out;
  background: transparent;

  &.noImage {
    object-fit: contain;
  }

  @keyframes fadeIn {
    from {
      filter: blur(10px);
      opacity: 0;
    }
    to {
      filter: blur(0);
      opacity: 1;
    }
  }
`;

export default Image;
