import React from "react";
import { CircularStepperStyled } from "./style";
import Typography from "../Typography";

type Props = {
  activeIndex?: number;
  steps: string[];
  style?: React.CSSProperties;
  width?: string;
  onClick?: (index: number) => void;
};

function CircularStepper({
  activeIndex = 1,
  steps,
  style = {},
  width = "100%",
  onClick,
}: Props) {
  return (
    <CircularStepperStyled $width={width} style={style}>
      <div className="steps-wrapper">
        {steps.map((step, idx) => (
          <div
            key={`${step}`}
            className={`step ${activeIndex >= idx + 1 ? "active" : ""}`}
            onClick={() => onClick && onClick(idx + 1)}
          >
            {idx + 1}
            {activeIndex === idx + 1 ? (
              <Typography className="step__label">{step}</Typography>
            ) : null}
          </div>
        ))}
      </div>
    </CircularStepperStyled>
  );
}

export default CircularStepper;
