import { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { ContainerStyled } from "./style";
export type FluidType = boolean | "sm" | "md" | "lg" | "xl" | "xxl";

export type ContainerProps = {
  children: ReactNode;
  fluid?: FluidType;
  enableMargin?: boolean;
  marginVertical?: number;
  style?: CSSProperties;
  backgroundColor?: string;
};

export default function Container({
  children,
  fluid = false,
  enableMargin = true,
  marginVertical = 100,
  backgroundColor = 'transparent',
  ...props
}: PropsWithChildren<ContainerProps>) {
  return (
    <ContainerStyled
      $bgColor={backgroundColor}
      $fluid={fluid}
      $marginVertical={marginVertical}
      $enableMargin={enableMargin}
      {...props}
    >
      {children}
    </ContainerStyled>
  );
}
