import { styled } from "styled-components";
import { StyledButton } from "./style";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download-icon.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/icons/view-icon.svg";

const GrantsCardsFooter = ({
  pdfUrl,
  name,
}: {
  pdfUrl: string;
  name: string;
}) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", `${name}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleView = () => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <Wrapper>
      <StyledButton onClick={handleDownload}>
        <DownloadIcon />
        Download
      </StyledButton>
      <StyledButton onClick={handleView} $secondaryButton>
        <ViewIcon />
        View
      </StyledButton>
    </Wrapper>
  );
};

export default GrantsCardsFooter;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
