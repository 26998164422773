import { PropsWithChildren } from "react";
import styled from "styled-components";
import { CSS } from "styled-components/dist/types";
import { selectDisable } from "../../style/reusableStyle";
import AccordionProvider, { useAccordion } from "./AccordionProvider";

interface IAccordionComposition {
  Header: React.FC<PropsWithChildren>;
  Content: React.FC<PropsWithChildren>;
}

const Accordion = ({
  children,
  initialValue,
}: PropsWithChildren<{ initialValue?: boolean }>) => {
  return (
    <AccordionProvider initialValue={initialValue ?? false}>
      {children}
    </AccordionProvider>
  );
};

function AccordionHeader({
  children,
  className,
  enableIcon = true,
}: PropsWithChildren<{ className?: string; enableIcon?: boolean }>) {
  const { expanded, toggle } = useAccordion();
  return (
    <AccordionHeaderStyle
      onClick={toggle}
      className={`accordion-header ${className}`}
    >
      {children}
      {enableIcon && (
        <img
          src="/assets/icons/Down.svg"
          width={14}
          height={14}
          style={{
            transform: `${expanded ? "rotate(180deg)" : "rotate(0)"}`,
            transition: "transform 0.2s ease-in-out",
          }}
        />
      )}
    </AccordionHeaderStyle>
  );
}

function AccordionContent({
  children,
  animationType = "height",
}: PropsWithChildren<{ animationType?: "display" | "height" }>) {
  const { expanded } = useAccordion();

  const style: CSS.Properties =
    animationType === "height"
      ? {
          maxHeight: expanded ? "800px" : "0px",
          visibility: expanded ? "visible" : "hidden",
          transition: "all 0.2s ease-in-out",
          fontSize: "14px",
        }
      : {
          display: expanded ? "block" : "none",
        };

  return <div style={style}>{children}</div>;
}

Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;

export default Accordion;

const AccordionHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${selectDisable};
`;
