import styled from "styled-components";
import { ThemeType } from "../../theme";
import { devices } from "../../theme/device";

export const ProfileCardWrapperStyle = styled.div<{ theme: ThemeType }>`
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  max-width: 300px;
  & .card {
    width: 100%;
    position: relative;
    display: flex;
    gap: 20px;
    flex-direction: column;

    & .image-wrapper {
      position: relative;
      z-index: 0;

      &::after {
        content: "";
        position: absolute;
        border-radius: 15px 15px 0px 0px;

        width: 100%;
        height: 190px;
        z-index: -1;
        left: 0;
        background-color: ${({ theme }) => theme.colors.profileBack};
        bottom: 2px;

        @media (${devices.mobileSM}) {
          height: 230px;
        }
      }
    }
    & .text {
      display: flex;
      flex-direction: column;
      max-width: 296px;
      gap: 8px;

      & .client-name {
        color: ${({ theme }) => theme.colors.primary};
        text-transform: uppercase;
        text-align: justify;
      }
      & .position {
        color: ${({ theme }) => theme.colors.secondary};
        text-align: justify;
      }
    }
  }
`;
