import { ImageStyled, WrapperStyled } from "./style";

const ImageSection = () => {
  return (
    <WrapperStyled>
      <ImageStyled src="/assets/images/aboutsection.png" alt="about image" />
    </WrapperStyled>
  );
};

export default ImageSection;
