import AliceCarousel from "react-alice-carousel";
import { CarouselContainer, CustomDot } from "./style";
import "react-alice-carousel/lib/alice-carousel.css";
import { ReactNode } from "react";

const responsive = {
  0: { items: 1 },
  600: { items: 2 },
  1025: { items: 3 },
};

const Carousel = ({
  carouselRef,
  items,
}: {
  carouselRef?: any;
  items: ReactNode[];
}) => {

  const renderDotItems = ({isActive} : {isActive: boolean}) => {
    return (
      <CustomDot
          $isActive={isActive}
        />
    )
  }
  return (
    <>
    <CarouselContainer>
      <AliceCarousel
        items={items}
        responsive={responsive}
        autoPlayInterval={3000}
        disableButtonsControls
        mouseTracking={true}
        renderDotsItem={renderDotItems}
        ref={carouselRef}
      />
    </CarouselContainer>
    </>
  );
};

export default Carousel;
