import IndividualCardSection from "./individualCardSection";
import { Typography } from "@shared/designComponents";
import { useTheme } from "styled-components";
import airtightnessPNG from "../../../assets/images/homeCardIndividualPage-img/airtightness.png";
import { EnergyfixCTASection } from "../../Shared";

const AirtightnessCard = () => {
  const theme = useTheme();
  return (
    <div>
      <IndividualCardSection
        imgUrl={airtightnessPNG}
        sn="04"
        title="Airtightness"
        description={
          <>
            <Typography size={"sm"}>
              While insulation effectively seals a house thermally, pesky
              draughts can undermine its benefits, leading to increased
              expenses. A leaky house, especially during winter, results in cold
              spots and inflated bills.
            </Typography>
          </>
        }
      />
      <EnergyfixCTASection />
    </div>
  );
};

export default AirtightnessCard;
