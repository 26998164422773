import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const TabStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 14px;
  margin-top: 50px;
  margin-bottom: 24px;
  padding-bottom: 24px;
  overflow-x: auto;

  .tab-item {
    position: relative;
    cursor: pointer;
    width: 100%;

    .title {
      white-space: nowrap;
      text-align: left;
    }

    &.active {
      color: ${({ theme }) => theme.colors.secondary};

      span {
        position: absolute;
        bottom: -14px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  @media (min-width: ${screenSizes.md}) {
    margin-bottom: 48px;

    .tab-item {
      &.active {
        span {
          bottom: -18px;
        }
      }
    }
  }

  @media (min-width: ${screenSizes.lg}) {
    margin-bottom: 64px;
  }
`;
