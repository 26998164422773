import React, { ReactNode } from "react";
import Typography, { ITypographyComponent } from "../Typography";
import styled from "styled-components";
import { devices } from "../../theme/device";

interface TitleProps extends ITypographyComponent {
  children: ReactNode;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";
}

const SectionTitle: React.FC<TitleProps> = ({ variant= "h1" ,children, ...restProps }) => {
  return (
    <Typo variant={variant} {...restProps}>
      {children}
    </Typo>
  );
};

export default SectionTitle;

const Typo = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.lg};
  line-height: 30px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  @media (${devices.tablet}) {
    font-size: ${({ theme }) => theme.sizes._2xl};
    line-height: 36px;
  }
  @media (${devices.laptopXL}) {
    font-size: ${({ theme }) => theme.sizes._4xl};
    line-height: 46px;
  }
`;
