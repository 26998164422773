import { ReactComponent as FacebookIcon } from "../../../assets/icons/icon-facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/icon-linkedin.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/icon-instagram.svg";

import { IconWrapper, WrapperStyled } from "./style";

const SocialLinks = () => {
  return (
    <WrapperStyled>
      <IconWrapper to="#">
        <FacebookIcon />
      </IconWrapper>
      <IconWrapper to="https://www.linkedin.com/company/energyfix/about/">
        <LinkedinIcon />
      </IconWrapper>
      <IconWrapper to="https://www.instagram.com/energyfix.ie/">
        <InstagramIcon />
      </IconWrapper>
    </WrapperStyled>
  );
};

export default SocialLinks;
