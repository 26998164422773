const colors = {
  primary: "#26C9A4",
  primaryDull: "#7FFEE1",
  secondary: "#20435D",
  financeLabel: "#949494",
  error: "#FF5D50",
  tableBody: "#424242",
  white: "#FFFFFF",
  profileBack: "#E9F4FF",
  primaryHover: "#51D4B6",
  secondaryHover: "#4A677C",
  dropdownHover: "#E4F2FF",
  black: "#000000",
  yellow: { strong: "#FFCC28", faded: "#FFFED1" },
  red: { strong: "#FF3838", faded: "#FECEC3" },
  blue: { strong: "#0383C5", faded: "#AFE1F2", light: "#F0F8FB" },
  gray: {
    darker: "#B3B3B3",
    dark: "#4B4A54",
    light: "#F6F6F6",
    lighter: "#F0F0F0",
  },

  mustardGreen: "#757438",
  darkText: "#1F1F1F",
  normalText: "#3D3D3D",
  lightText: "#616161",
  disableText: "#C7C7C7",
  disableButtonText: "#D4F4ED",
  border: "#C7C7C7",
  disable: "#E0E0E0",
  defaultButton: "#EBEBEB",
  gray8A: "#8a8a8a",
  gray8C: "#8c8a8a",
  grayFA: "#fafafa",
  gray61: "#616161",
  transparent: "transparent",
  scrollThumb: "#717171",
  borderLime: "#C3FF86",

  footerBlack: "#333333",
  stepperText: "#4B4A54",
  yellowGreen: "#DEE71E",

  // colors from admin
  ad_primary: "#5E6469",
  ad_secondary: "#05055cff",
  secondaryText: "#333333",
  gray99: "#fcfcfc",
  green: { strong: "#00C67F", faded: "#CEFBE3" },
  capriBlue: { strong: "#00BDFF", faded: "#CEF6FF" },
  munsellBlue: { strong: "#008AA8", faded: "#CCFEFF" },
  OrangeBrown: { strong: "#9D7000", faded: "#FFF0CA" },
  darkViolet: { strong: "#A100DA", faded: "#EAB3FE" },
  darkSlateGray: { strong: "#2F4858", faded: "#9AB1C0" },
  ad_disableButtonText: "#B3B3B3",
  ad_border: "#8A8A8A",
  lightBackground: "#F0F0F0",
  lighterBackground: "#F5F5F5",
  tabText: "#616161",
  popoverBg: "#FAFAFA",
  sidebarBg: "#05055b",
  status: {
    off: "#7B8089",
    standBy: "#64D9FF",
    completed: "#00E200",
    caution: "#FAD800",
    warning: "#FFAF3D",
    error: "#FF2A04",
  },
};
export default colors;

export type ColorsType = typeof colors;
