import Table from "@shared/designComponents/Table";
import theme from "@shared/theme";
import { grantsTable } from "./grantsData";
import { GrantsStyled } from "./style";
import { DataType } from "..";

const GrantsTab = ({ data }: { data: DataType[] }) => {
  return (
    <GrantsStyled>
      <Table
        data={{
          header: grantsTable.header,
          body: data,
        }}
        style={{ backgroundColor: theme.colors.grayFA }}
      />
    </GrantsStyled>
  );
};

export default GrantsTab;
