import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import authConfig from "@shared/apiConfigs/auth";

import React, { useState } from "react";
import Modal from "@shared/designComponents/Modal";
import SectionTitle from "@shared/designComponents/SectionTitle";
import ColorText from "@shared/designComponents/SectionTitle/ColorText";
import Typography from "@shared/designComponents/Typography";
import Input from "@shared/designComponents/Input";
import Button from "@shared/designComponents/Button";
import queryString from "query-string";
import { requestAPI } from "@shared/utils";
import { Container } from "@shared/designComponents";

import { toast } from "react-toastify";

const UpdatePassword = () => {
  const location = useLocation();

  const parsedQueryString = queryString.parse(location.search);

  const token: any = parsedQueryString.token;
  const email: any = parsedQueryString.email;

  const initialValues = {
    email: email,
    token: "",
    password: "",
    confirmPassword: "",
  };

  const navigate = useNavigate();

  const handleUpdatePassword = async (values: typeof initialValues) => {
    try {
      await requestAPI(
        authConfig.updatePassword({
          token: token ?? "",
          email: email ?? "",
          password: values.password,
          passwordConfirmation: values.confirmPassword,
        })
      );
      toast.success(
        "Password Updated Successfully. Please, sigin with new password"
      );
      navigate("/?openLogin=true");
    } catch (error) {
      toast.error("Something went wrong, please try again");
      console.error(error);
    }
  };
  return (
    <Container marginVertical={10}>
      <SectionTitle style={{ fontSize: "20px" }}>
        Welcome to <ColorText color="#26C9A4">energyfix</ColorText>
      </SectionTitle>
      <SectionTitle style={{ fontSize: "16px" }}>
        <ColorText color="#20435D">Sign In</ColorText> to get access to
        <ColorText color="#26C9A4"> energyFix</ColorText>
      </SectionTitle>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleUpdatePassword}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <Typography>Email Address</Typography>
                <Input
                  iconRight={<img src="/assets/icons/mail.svg" />}
                  name="email"
                  value={values.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  disabled
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "5px",
                }}
              >
                <Typography>Password</Typography>
                <Input
                  iconRight={<img src="/assets/icons/password.svg" />}
                  name="password"
                  value={values.password}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  placeholder="Enter Password"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginBottom: "5px",
                }}
              >
                <Typography>Confirm Password</Typography>
                <Input
                  iconRight={<img src="/assets/icons/password.svg" />}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={(e) =>
                    setFieldValue("confirmPassword", e.target.value)
                  }
                  placeholder="Confirm Password"
                />
              </div>

              <Button>Update Password</Button>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default UpdatePassword;
