import React, { CSSProperties } from "react";

const ColorText = ({
  children,
  color = "black",
  className,
  style,
}: {
  children: React.ReactNode;
  color?: string;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <span style={{ ...style, color: `${color}` }} className={className}>
      {children}
    </span>
  );
};

export default ColorText;
