export const TEST_ROUTE = "/test";
export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const ABOUTUS_ROUTE = "/about-us";
export const RETROFIT_ROUTE = "/what-is-retrofit";
export const RETROFIT_ROUTE_RENEWABLE = "renewable-energy-heat-pump";
export const RETROFIT_ROUTE_INSULATION = "insulation";
export const RETROFIT_ROUTE_VENTILATION = "ventilation";
export const RETROFIT_ROUTE_AIRTIGHTNESS = "airtightness";
export const RETROFIT_ROUTE_DOOR = "door-and-window-upgrades";
export const RETROFIT_ROUTE_SOLAR = "solar-energy-systems";
export const SERVICES_ROUTE = "/services";
export const FAQS_ROUTE = "/faqs";
export const REGISTER_INTEREST_PATH = "/register-interest";
export const CUSTOMER_CHARTER_ROUTE = "/customer-charter";
export const TERMS_POLICY_ROUTE = "/terms";
export const COST_GRANT_PATH = "/cost-and-fees";
export const FINANCE_PATH = "finance";
export const VACANT_HOMES_GRANT_PATH = "vacant-homes-grant";
export const PROFILE_ROUTE = "profile";
export const UPDATE_PASSWORD_ROUTE = "/clients/claim";
export const USER_DASHBOARD_ROUTE = "/user/panel";
