import { styled } from "styled-components";
import { devices } from "@shared/theme/device";
import { Typography } from "@shared/designComponents";

export const RetroBottomStyle = styled.div`
  & .container {
    width: 100%;
    min-height: 385px;
    background-color: ${({ theme }) => theme.colors.profileBack};
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding: 10px;

    & .wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 50px;
      gap: 30px;

      & .text-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
      }

      & .links {
        display: flex;

        & .link-text {
          z-index: 99999;
        }

        & .img {
          max-width: 256px;
          position: absolute;
          margin: 40px 0 0 auto;

          @media (${devices.tablet}) {
            margin: auto 0 0 40px;
          }
        }
      }
    }
  }
`;

export const Typo = styled(Typography)`
  line-height: 26px;
`;
