import checkmarkPNG from "../../../assets/images/userDashboard/Check.png";
import clockPNG from "../../../assets/images/userDashboard/VectorClock.png";
import { Image, Typography } from "@shared/designComponents";

import { StepperStyled } from "./style";
import colors from "@shared/theme/colors";
import theme from "@shared/theme";

export type StatusType = "Inactive" | "Pending" | "Done";

interface Props {
  steps: {
    status: StatusType;
    title: string;
  }[];
}

const bgColors = {
  Inactive: colors.white,
  Pending: colors.primaryDull,
  Done: colors.primary,
};

function Stepper({ steps }: Props) {
  return (
    <StepperStyled>
      {steps.map(({ title, status }, i) => (
        <div key={i} className={`step ${status}`}>
          <div
            className="step-status"
            style={{
              backgroundColor: bgColors[status],
            }}
          >
            {status === "Done" ? (
              <Image
                src={checkmarkPNG}
                alt="checkmarkPNG"
                objectFit="contain"
              />
            ) : status === "Pending" ? (
              <Image src={clockPNG} alt="clockPNG" objectFit="contain" />
            ) : status === "Inactive" ? (
              <Typography color={theme.colors.border} size={"md"}>
                {i}
              </Typography>
            ) : null}
          </div>
          {status === "Inactive" ? (
            <Typography
              size={"sm"}
              className="step-title"
              color={theme.colors.border}
            >
              {title}
            </Typography>
          ) : (
            <Typography size={"sm"} className="step-title">
              {title}
            </Typography>
          )}
        </div>
      ))}
    </StepperStyled>
  );
}

export default Stepper;
