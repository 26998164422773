import { useRoot } from "@shared/context/RootProvider";
import {
  ColorText,
  Container,
  SectionTitle,
  Typography,
} from "@shared/designComponents";
import theme from "@shared/theme";
import { useEffect, useState } from "react";
import userDashboardConfig from "@shared/apiConfigs/userDashboard";
import { requestAPI } from "@shared/utils";
import Document from "./Documents";
import Grants from "./Grants";
import Stepper, { StatusType } from "./Stepper";
import { UserDashboardStyled } from "./style";
import Tab from "./Tab";
import UpdateData from "./Updates/updateDataComponent";
import WorkStages from "./WorkStages";
import Payments from "./Payments";
interface FormatDataType {
  status: StatusType;
  title: string;
}
const tabItems = [
  {
    label: "Updates",
    children: <UpdateData />,
  },
  {
    label: "Grants",
    children: (
      <div>
        <Grants />
      </div>
    ),
  },
  {
    label: "Work Stages",
    children: (
      <div>
        <WorkStages />
      </div>
    ),
  },
  {
    label: "Documents",
    children: (
      <div>
        <Document />
      </div>
    ),
  },
  {
    label: "Payments",
    children: (
      <div>
        <Payments />
      </div>
    ),
  },
];
const UserDashboard = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [userData, setUserData] = useState({});
  const { auth } = useRoot();
  const { _id: id } = auth;

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };
  const fetchUserData = async () => {
    try {
      const data = await requestAPI(
        userDashboardConfig.getDataOfLoggedInUser(id)
      );
      setUserData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);
  console.log(userData);

  const formatData = () => {
    const orderedKeys = [
      "assessmentBooked",
      "assessmentDate",
      "ber",
      "firstName",
      "lastName",
      "email",
      "createdAt",
    ];
    const newData = orderedKeys
      .map((key): FormatDataType | undefined => {
        const value = userData[key];
        if (value === undefined) return undefined; // Skip if the key is not in userData

        switch (key) {
          case "assessmentBooked":
            return { status: value, title: "Assessment Booked" };
          case "assessmentDate":
            return {
              status: "Pending",
              title: `Assessment on ${new Date(value).toLocaleString()}`,
            };
          case "ber":
            return {
              status: "Inactive",
              title: "BER Report Completed",
            };
          case "firstName":
            return {
              status: "Inactive",
              title: "Contract Discussions in Progress",
            };
          case "lastName":
            return {
              status: "Inactive",
              title: "Contract Signed",
            };
          case "email":
            return {
              status: "Inactive",
              title: "Work in Progress",
            };
          case "createdAt":
            return {
              status: "Inactive",
              title: "Work Completed",
            };
        }
      })
      .filter(Boolean); // Remove undefined items
    return newData;
  };
  return (
    <Container marginVertical={40}>
      <UserDashboardStyled>
        <div className="heading">
          <SectionTitle className="section-title">
            <Typography fontWeight="semibold" size={"xl"}>
              <ColorText color={theme.colors.secondary}> User </ColorText>
              <ColorText color={theme.colors.primary}> Dashboard </ColorText>
            </Typography>
          </SectionTitle>
        </div>
        <div className="stepper">
          <Typography
            size={["sm", "sm", "md"]}
            className="section-subtitle"
            color={theme.colors.secondary}
          >
            EnergyFix Progress Bar
          </Typography>
          <Stepper steps={formatData()} />
        </div>
        <div className="tab">
          <Tab
            tabItems={tabItems}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
        </div>
      </UserDashboardStyled>
    </Container>
  );
};

export default UserDashboard;
