import { TERMS_POLICY_ROUTE } from "../../../routes/routePaths";
import { FooterLinkStyled, FooterLinkTextStyled } from "../style";
import { TextWrapperStyled, WrapperStyled } from "./style";

const InfoText = () => {
  return (
    <WrapperStyled>
      <TextWrapperStyled>
        {/* <FooterLinkStyled to="#">
          <FooterLinkTextStyled>Info</FooterLinkTextStyled>
        </FooterLinkStyled>
        <FooterLinkTextStyled>.</FooterLinkTextStyled>
        <FooterLinkStyled to="#">
          <FooterLinkTextStyled>Support</FooterLinkTextStyled>
        </FooterLinkStyled>
        <FooterLinkTextStyled>.</FooterLinkTextStyled>
        <FooterLinkStyled to="#">
          <FooterLinkTextStyled>Marketing</FooterLinkTextStyled>
        </FooterLinkStyled>
      </TextWrapperStyled>
      <TextWrapperStyled>
        <FooterLinkStyled to={TERMS_POLICY_ROUTE}>
          <FooterLinkTextStyled>Terms of Use</FooterLinkTextStyled>
        </FooterLinkStyled>
        <FooterLinkTextStyled>.</FooterLinkTextStyled> */}
        <FooterLinkStyled to={TERMS_POLICY_ROUTE}>
          <FooterLinkTextStyled>Privacy Policy</FooterLinkTextStyled>
        </FooterLinkStyled>
      </TextWrapperStyled>
    </WrapperStyled>
  );
};

export default InfoText;
