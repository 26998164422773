import Typography from "../Typography";
import { ProfileDataType } from "../../components/About/CardSection/cardData";
import { ProfileCardWrapperStyle } from "./style";
const ProfileCard = ({ name, post, imgUrl }: ProfileDataType) => {
  return (
    <ProfileCardWrapperStyle>
      <div className="card">
        <div className="image-wrapper">
          <img src={imgUrl} alt="Profile Image" />
        </div>
        <div className="text">
          <Typography className="client-name" size="md" fontWeight="bold">
            {name}
          </Typography>
          <Typography className="position" size="md" fontWeight="bold">
            {post}
          </Typography>
        </div>
      </div>
    </ProfileCardWrapperStyle>
  );
};

export default ProfileCard;
