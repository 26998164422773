import styled from "styled-components";
import { flexCenter, flexSpaceBetween } from "../../style/reusableStyle";

export const CircularStepperStyled = styled.div<{ $width: string }>`
  ${flexCenter()};
  width: ${({ $width }) => $width || "100%"};
  padding: 5px 5px;
  margin: 0 auto;
  & .steps-wrapper {
    width: 100%;
    position: relative;
    ${flexSpaceBetween()};
    z-index: ${({ theme }) => theme.zIndices.docked};

    &::before {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      top: calc(50% - 1px);
      background-color: ${({ theme }) => theme.colors.secondary};
      z-index: ${({ theme }) => theme.zIndices.hide};
    }
  }

  & .step {
    position: relative;
    transition: all 0.3s;
    ${flexCenter()};
    flex-shrink: 0;
    border: 1px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 50%;
    height: 34px;
    width: 34px;
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    font-size: ${({ theme }) => theme.sizes.sm};
    color: ${({ theme }) => theme.colors.stepperText};
    font-family: "Poppins";
    background-color: ${({ theme }) => theme.colors.white};
    
    &.active {
      background-color: ${({ theme }) => theme.colors.secondary};
      color: ${({ theme }) => theme.colors.white};
    }

    &__label {
      position: absolute;
      top: calc(100% + 10px);
      text-align: center;
      font-weight: ${({ theme }) => theme.fontWeights.normal};
      font-size: ${({ theme }) => theme.sizes.sm};
      color: ${({ theme }) => theme.colors.secondary};
      max-width: 200px;
      
    }
  }
`;
