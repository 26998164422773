import GrantsCardsFooter from "./CardFooter";
import grantApplicationFormPDF from "./pdfs/grant-application-form.pdf";
import grantFAQsPDF from "./pdfs/grant-faqs.pdf";
import grantSchemePDF from "./pdfs/grant-scheme.pdf";
import editDocumentIcon from "../../../../assets/icons/edit-document-icon.svg";
import faqIcon from "../../../../assets/icons/faqs.svg";

export const cardsData = [
  {
    imgUrl: editDocumentIcon,
    heading: "Application Form",
    description: "Vacant Property Refurbishment Grant",
    footer: (
      <GrantsCardsFooter
        pdfUrl={grantApplicationFormPDF}
        name="Grant Application Form"
      />
    ),
  },
  {
    imgUrl: faqIcon,
    heading: "FAQs",
    description: "Vacant Property Refurbishment Grant",
    footer: <GrantsCardsFooter pdfUrl={grantFAQsPDF} name="Grant FAQs" />,
  },
  {
    imgUrl: editDocumentIcon,
    heading: "Scheme Outline",
    description: "Vacant Property Refurbishment Grant",
    footer: (
      <GrantsCardsFooter pdfUrl={grantSchemePDF} name="Grant Scheme Outline" />
    ),
  },
];
