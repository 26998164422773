import { ReactNode } from "react";
import { ITypography } from "../Typography/style";
import { Typo } from "./style";

interface TitleProps extends ITypography {
  children: ReactNode;
}

const PageTitle = ({ children, ...restProps }: TitleProps) => {
  return (
    <Typo variant="h1" {...restProps}>
      {children}
    </Typo>
  );
};

export default PageTitle;
