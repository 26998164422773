import { ServiceSection, ServicesHeader } from "../../components/Services";

const Services = () => {
  return (
    <>
      <ServicesHeader />
      <ServiceSection />
    </>
  );
};

export default Services;
