import { spawn } from "child_process";
import insulationPNG from "../../../assets/images/homeCard-img/image-16.png";
import ventilationPNG from "../../../assets/images/homeCard-img/image-18.png";
import airtightnessPNG from "../../../assets/images/homeCard-img/image-20.png";
import doorPNG from "../../../assets/images/homeCard-img/image-21.png";
import solarPNG from "../../../assets/images/homeCard-img/image-22.png";
import renewablePNG from "../../../assets/images/homeCard-img/image-26.png";
import { ColorText } from "@shared/designComponents";
export interface CardDataType {
  imgUrl: string;
  title: string;
  shortDescription: string;
  fullDescription: string;
  href: string;
}
export const homeCardData: CardDataType[] = [
  {
    title: "Renewable Energy Heat Pump",
    shortDescription:
      "Enhance the coziness of your living space by upgrading to a heat pump, which not only improves your home's comfort but also slashes heating expenses, lowers energy consumption, and minimizes greenhouse gas emissions.",
    fullDescription:
      "Enhance the coziness of your living space by upgrading to a heat pump, which not only improves your home's comfort but also slashes heating expenses, lowers energy consumption, and minimizes greenhouse gas emissions. Take advantage of the SEAI grant, offering funds ranging from €3,500 to €6,500, to make this eco-friendly transition.",
    imgUrl: renewablePNG,
    href: "/what-is-retrofit/renewable-energy-heat-pump",
  },
  {
    title: "Insulation",
    shortDescription:
      "Upgrading insulation can significantly decrease heating expenses and enhance warmth. Upgrading your roof or attic insulation can cut heat loss by up to 30%, making it one of the most cost-effective energy-saving measures.",
    fullDescription: ` Upgrading insulation can significantly decrease heating expenses and enhance warmth. Upgrading your roof or attic insulation can cut heat loss by up to 30%, making it one of the most cost-effective energy-saving measures. Avail yourself of the SEAI grant for attic insulation, offering support ranging from €800 to €1,500. \n Insulate your external walls to reduce heat loss by up to 35%. Cavity, external and/or internal insulation can be used. The SEAI grant available range from €700 - €8,000. `,
    imgUrl: insulationPNG,
    href: "/what-is-retrofit/insulation",
  },
  {
    title: "Ventilation",
    shortDescription:
      "Ensuring proper ventilation is essential for a healthy living environment. Sealing up a drafty house isn't about preventing fresh air exchange; it's about managing it effectively.",
    fullDescription:
      "Ensuring proper ventilation is essential for a healthy living environment. Sealing up a drafty house isn't about preventing fresh air exchange; it's about managing it effectively. MVHR systems also regulate moisture levels in kitchens and bathrooms, and help neutralize various odors, promoting a more pleasant indoor atmosphere.",
    imgUrl: ventilationPNG,
    href: "/what-is-retrofit/ventilation",
  },
  {
    title: "Airtightness",
    shortDescription:
      "While insulation effectively seals a house thermally, pesky draughts can undermine its benefits, leading to increased expenses.",
    fullDescription:
      "While insulation effectively seals a house thermally, pesky draughts can undermine its benefits, leading to increased expenses. A leaky house, especially during winter, results in cold spots and inflated bills.",
    imgUrl: airtightnessPNG,
    href: "/what-is-retrofit/airtightness",
  },
  {
    title: "Door and Window Upgrades",
    shortDescription:
      "Draughts through windows and doors are uncomfortable and increase your heating bills. Upgrade your doors and windows to current standards to reduce outside noise and heat loss by 10%.",
    fullDescription:
      "Draughts through windows and doors are uncomfortable and increase your heating bills. Upgrade your doors and windows to current standards to reduce outside noise and heat loss by 10%. The SEAI grants available for windows range from €1,500 - €4,000. The SEAI grant for external doors is €800 per door (max. 2). ",
    imgUrl: doorPNG,
    href: "/what-is-retrofit/door-and-window-upgrades",
  },
  {
    title: "Solar Energy Systems",
    shortDescription:
      "Despite Ireland's climate, solar energy remains a viable option, capable of fulfilling 50-60% of your electricity or hot water requirements while cutting down on energy expenses. ",
    fullDescription:
      "Despite Ireland's climate, solar energy remains a viable option, capable of fulfilling 50-60% of your electricity or hot water requirements while cutting down on energy expenses. Consider installing a solar thermal system for hot water or solar panels for electricity generation. The SEAI offers grants of €1,200 for solar water heating and up to €2,400 for solar panels to support these eco-friendly initiatives.",
    imgUrl: solarPNG,
    href: "/what-is-retrofit/solar-energy-systems",
  },
];
