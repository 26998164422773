import { useRoot } from "@shared/context/RootProvider";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Update from ".";
import userDashboardConfig from "@shared/apiConfigs/userDashboard";
import { requestAPI } from "@shared/utils";

interface UpdateDataType {
  _id: string;
  leadId: string;
  refName: string;
  title: string;
  description: string;
  activity: string;
  createdAt: string;
  updatedAt: string;
}
const UpdateData = () => {
  const [activity, setActivity] = useState<UpdateDataType[]>([]);
  const { auth } = useRoot();
  const { _id: id } = auth;
  const getAllActivies = async () => {
    try {
      const { data } = await requestAPI(
        userDashboardConfig.getUpdatesByUserId(id)
      );
      setActivity(data);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };
  useEffect(() => {
    getAllActivies();
  }, []);
  return (
    <div>
      {activity.map((data, i) => (
        <Update
          key={i}
          title={data.activity.charAt(0).toUpperCase() + data.activity.slice(1)}
          name={data.refName}
          date={data.updatedAt}
          //   nameColor={data.nameColor}
        />
      ))}
    </div>
  );
};

export default UpdateData;
