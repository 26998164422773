export const costAndGrantTable = {
  header: [
    { name: "Grant Name", label: "grant_name" },
    { name: "Types of Homes", label: "types_of_home" },
    { name: "Grant Value", label: "grant_value" },
  ],
  body: [
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
    [
      { label: "grant_name", value: "Heat Pump Systems" },
      { label: "types_of_home", value: ["All Houses", "Appartments"] },
      { label: "grant_value", value: ["6,500", "4,500"] },
    ],
  ],
};
