import { ColorText, SectionTitle, Typography } from "@shared/designComponents";
import theme from "@shared/theme";

const PageNotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
        alignItems: "center",
        justifyContent: "center",
        margin: "150px auto",
      }}
    >
      <img src="/assets/images/pagenotfound.png" alt="page not found" />
      <SectionTitle>
        <ColorText color={theme.colors.error}>Page Not Found</ColorText>
      </SectionTitle>
    </div>
  );
};

export default PageNotFound;
