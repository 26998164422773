import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  gap: 30px;

  @media (min-width: ${screenSizes.md}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 426px;
  z-index: 500;
  flex-shrink: 0;
`;

export const ContentWrapper = styled.div`
  max-width: 530px;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
