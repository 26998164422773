import { styled } from "styled-components";
import { Button, Typography } from "@shared/designComponents";
import { devices } from "@shared/theme/device";

export const BannerWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.grayFA};
  padding: 40px 0;

  @media (${devices.tablet}) {
    padding: 50px 0;
  }
`;

export const ServiceSectionWrapperStyled = styled.div<{
  $isEvenSection: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 30px;

  & .service-image-wrapper {
    display: flex;
    justify-content: center;
  }

  @media (${devices.laptop}) {
    flex-direction: ${({ $isEvenSection }) =>
      $isEvenSection ? "row" : "row-reverse"};
    justify-content: flex-end;
    gap: 60px;

    & .button {
      margin-left: 50px;
    }
  }
`;

export const SectionNumberStyled = styled(Typography)`
  margin-right: 10px;
  line-height: 36px;

  @media (${devices.tablet}) {
    line-height: 46px;
  }
`;

export const BannerTitleStyled = styled(Typography)`
  line-height: 32px;

  @media (${devices.tablet}) {
    line-height: 46px;
  }
`;

export const DescriptionStyled = styled(Typography)`
  line-height: 26px;
  margin-left: 0px;
  text-align: justify;
  margin-bottom: 20px;

  @media (${devices.laptop}) {
    margin-left: 50px;
    margin-right: 50px;
    max-width: 510px;
  }
`;
