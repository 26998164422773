import Table from "@shared/designComponents/Table";
import theme from "@shared/theme";
import { WorkStagesStyled } from "./style";
import { workStagesTable } from "./workStagesData";
import { useEffect, useState } from "react";
import { requestAPI } from "@shared/utils";
import userDashboardConfig from "@shared/apiConfigs/userDashboard";
import { toast } from "react-toastify";
import { useRoot } from "@shared/context/RootProvider";
import { formatStagesTableData } from "./helperFunction";

export interface WorkStagesDataType {
  name: string;
  amount: number;
  status: string;
  createdAt?: string;
  updatedAt?: string;
}
const WorkStages = () => {
  const [stagesData, setStagesData] = useState<WorkStagesDataType[]>([]);
  const { auth } = useRoot();
  const { _id: id } = auth;
  const getStages = async () => {
    try {
      const { data } = await requestAPI(
        userDashboardConfig.getWorkStagesByUserId(id)
      );
      setStagesData(data);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    getStages();
  }, []);
  return (
    <WorkStagesStyled>
      <Table
        data={{
          header: workStagesTable.header,
          body: formatStagesTableData(stagesData),
        }}
        style={{ backgroundColor: theme.colors.grayFA }}
      />
    </WorkStagesStyled>
  );
};

export default WorkStages;
