import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestAPI } from "@shared/utils";
import leadConfig from "@shared/apiConfigs/leads";
import { LeadData } from "@shared/apiConfigs/leads";
import { openModal } from "./exampleSlice";
import { RootState } from "..";

const initialState = {
  isCreatingLead: false,
  counter: 0,
};

export const createLead = createAsyncThunk(
  "lead/createLead",
  async (data: LeadData, thunkAPI) => {
    try {
      await requestAPI(leadConfig.createLead(data));
      thunkAPI.dispatch(openModal());
      return true;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Creating lead slice
const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    increment: (state) => {
      state.counter++;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLead.pending, (state: any) => {
        state.isCreatingLead = true;
      })
      .addCase(createLead.fulfilled, (state: any) => {
        state.isCreatingLead = false;
      })
      .addCase(createLead.rejected, (state) => {
        state.isCreatingLead = false;
      });
  },
});

// lead slice  creating status selector
export const selectIsCreatingLead = (state: RootState) =>
  state.lead.isCreatingLead;

export default leadSlice.reducer;
export const { increment } = leadSlice.actions;
