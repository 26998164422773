import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const UserDashboardStyled = styled.div`
  .stepper {
    & .section-subtitle {
      margin: 20px 0px;
    }

    @media (min-width: ${screenSizes.lg}) {
      position: relative;
      background-color: ${({ theme }) => theme.colors.blue.light};
      height: 200px;
      bottom: 40px;
      margin-top: 100px;
      padding-top: 1%;
      padding-left: 30px;
    }
    @media (min-width: ${screenSizes.xl}) {
      width: 1210px;
    }
  }

  @media (min-width: ${screenSizes.md}) {
    .tab {
      width: 100%;
    }
  }
`;
