import Typography from "../Typography";
import {
  CardStyled,
  CardWrapperStyled,
  ContentWrapperStyled,
  CountStyled,
  ImageStyled,
  ImageWrapperStyled,
} from "./style";
import { ReactNode } from "react";

export type CardProps = {
  heading?: string;
  description?: string;
  imgurl?: string;
  variant?: "default" | "withoutCountAndButton";
  count?: number;
  footer?: ReactNode;
};

export default function Card({
  heading = "Card Title",
  description = "Card Description",
  imgurl = "/assets/images/card.svg",
  variant = "default",
  count = 0,
  footer,
}: CardProps) {
  return (
    <CardWrapperStyled $variant={variant}>
      <CardStyled $variant={variant}>
        {variant === "default" && <CountStyled>{count}</CountStyled>}
        <ImageWrapperStyled $variant={variant}>
          <ImageStyled src={imgurl} alt={heading} />
        </ImageWrapperStyled>
        <ContentWrapperStyled $variant={variant}>
          <div>
            <Typography className="card-heading">{heading}</Typography>
            <Typography className="card-description">{description}</Typography>
          </div>
          {!!footer && footer}
        </ContentWrapperStyled>
      </CardStyled>
    </CardWrapperStyled>
  );
}
