import { ConfigType, ParamsType } from "../utils/requestAPI";
type GrantTableRowDataType = {
  name: string;
  homeType?: string;
  value: string;
};

type GrantTableParams = {
  _id: string;
  name: string;
  homeType: string;
  value: string;
};

const grantTableRowsConfig = {
  createGrantTableRows: (data: GrantTableRowDataType): ConfigType => ({
    method: "post",
    data,
    url: "/grant-tables",
  }),
  updateGrantTable: (
    id: string,
    data: Pick<GrantTableParams, "name" | "homeType" | "value">
  ): ConfigType => ({
    method: "put",
    url: `/grant-tables/${id}`,
    data,
  }),

  getAllGrantTableRows: (params?: ParamsType): ConfigType => ({
    method: "get",
    url: "/grant-tables",
    params,
  }),
  getGrantTableRowsById: (id: string): ConfigType => ({
    method: "get",
    url: `/grant-tables/${id}`,
  }),
  deleteGrantTableRowById: (id: string): ConfigType => ({
    method: "delete",
    url: `/grant-tables/${id}`,
  }),
};

export default grantTableRowsConfig;
