// This can take a single string value for font-size ("xs", "sm", "md",...) or an array of these values (["xs", "sm", "md", "lg"]) for responsive. The four array elements corresponds
// to [480px, 768px, 992px and 1200px] screen sizes.

import { CSSProperties, ReactNode } from "react";
import { ITypography, Tag } from "./style";

export interface ITypographyComponent {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
}

export interface ITypographyProps extends ITypographyComponent, ITypography {}

const Typography = ({
  variant = "p",
  size = "md",
  fontFamily = "Poppins",
  fontWeight = "normal",
  color = "#000",
  style,
  className,
  children,
  ...rest
}: ITypographyProps) => {
  return (
    <Tag
      as={variant}
      size={size}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      color={color}
      className={className}
      style={{ ...style }}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Typography;
