import service from "../serviceData";
import { ServiceWrapperStyled } from "./style";
import ServiceBanner from "./ServicesBanner";

const ServiceSection = () => {
  return (
    <ServiceWrapperStyled>
      {service.section.map((section, idx) => {
        const isEvenSection = idx % 2 !== 0;
        return (
          <ServiceBanner
            key={section.title}
            idx={idx}
            isEvenSection={isEvenSection}
            title={section.title}
            subtitle={section.subtitle}
            description={section.description}
            image={section.image}
          />
        );
      })}
    </ServiceWrapperStyled>
  );
};

export default ServiceSection;
