import { ComponentProps, Fragment, PropsWithChildren, ReactNode } from "react";
import { StyledLargeTable, StyledSmallTable } from "./styled";
import { useWindowSize } from "@uidotdev/usehooks";
import Typography from "../Typography";
import colors from "../../theme/colors";

type TableHeaderType = {
  label: string;
  name: string;
};

export type TableDataType = {
  label: string;
  value: string | string[] | ReactNode;
};
interface TableProps extends ComponentProps<"table"> {
  data: {
    header: TableHeaderType[];
    body: TableDataType[][];
    keyToHide?: string;
  };
}

const Table = ({
  children,
  data,
  keyToHide,
  ...props
}: PropsWithChildren<TableProps>) => {
  const { width } = useWindowSize();

  if (width && width < 768) {
    return (
      <SmallTable tableData={data} keyToHide={keyToHide || "_id"} {...props} />
    );
  }

  return (
    <LargeTable tableData={data} keyToHide={keyToHide || "_id"} {...props} />
  );
};

export function LargeTable({
  tableData,
  keyToHide,
  ...props
}: {
  tableData: { header: TableHeaderType[]; body: TableDataType[][] };
  keyToHide: string;
}) {
  return (
    <StyledLargeTable {...props}>
      <thead>
        <tr>
          {tableData.header.map((head) => {
            return (
              <th key={head.label}>
                <Typography
                  variant="span"
                  color={colors.secondary}
                  size="sm"
                  fontWeight="medium"
                >
                  {head.name}
                </Typography>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {tableData.body.length > 0 ? (
          tableData.body.map((tableRow, index) => {
            return (
              <tr key={`tableRow-${index}`}>
                {tableRow.map((tableData, i) => {
                  if (tableData.label === keyToHide) {
                    return null;
                  }

                  if (
                    typeof tableData.value !== "string" &&
                    !Array.isArray(tableData.value)
                  ) {
                    return (
                      <td key={`${tableData.label}-${index}-${i}`}>
                        {tableData.value}
                      </td>
                    );
                  }
                  if (typeof tableData.value === "string") {
                    return (
                      <td key={`${tableData.label}-${index}-${i}`}>
                        <Typography
                          variant="span"
                          size="sm"
                          color={colors.footerBlack}
                        >
                          {tableData.value}
                        </Typography>
                      </td>
                    );
                  }

                  return (
                    <td
                      key={`${tableData.label}-${index}-${i}`}
                      className="table-data__list"
                    >
                      {tableData.value.map((tableText) => {
                        return (
                          <Typography
                            variant="p"
                            key={tableText}
                            size="sm"
                            color={colors.footerBlack}
                          >
                            {tableText}
                          </Typography>
                        );
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <Typography>No data available</Typography>
        )}
      </tbody>
    </StyledLargeTable>
  );
}

export function SmallTable({
  tableData,
  keyToHide,
  ...props
}: {
  tableData: { header: TableHeaderType[]; body: TableDataType[][] };
  keyToHide: string;
}) {
  console.log(tableData.header);
  return (
    <StyledSmallTable {...props} $borderAt={tableData.header.length}>
      {tableData.body.length > 0 ? (
        tableData.body.map((bodyItem, i) => {
          return (
            <Fragment key={`mini-table-body-${i}`}>
              {bodyItem.map((bodyRow) => {
                console.log(bodyRow.label);
                if (bodyRow.label === keyToHide) {
                  return null;
                }
                return (
                  <tr key={`min-table-row-${bodyRow.label}`}>
                    <td>
                      <Typography size="sm" color={colors.secondary}>
                        {
                          tableData.header.filter(
                            (item) => item.label === bodyRow.label
                          )?.[0]?.name
                        }
                      </Typography>
                    </td>
                    <td className="table-data__list">
                      {typeof bodyRow.value === "string" ? (
                        <Typography
                          variant="span"
                          size="sm"
                          color={colors.footerBlack}
                        >
                          {bodyRow.value}
                        </Typography>
                      ) : typeof bodyRow.value !== "string" &&
                        !Array.isArray(bodyRow.value) ? (
                        bodyRow.value
                      ) : (
                        Array.isArray(bodyRow.value) &&
                        bodyRow.value.map((rowData, index) => {
                          return (
                            <Typography
                              key={`mini-row-data-${i}-${index}`}
                              size="sm"
                              color={colors.footerBlack}
                            >
                              {rowData}
                            </Typography>
                          );
                        })
                      )}
                    </td>
                  </tr>
                );
              })}
            </Fragment>
          );
        })
      ) : (
        <Typography>No data available</Typography>
      )}
    </StyledSmallTable>
  );
}

export default Table;
