import styled from "styled-components";
import { SectionTitle, Typography } from "@shared/designComponents";
import { devices } from "@shared/theme/device";

export const WrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const SectionHeaderStyled = styled(SectionTitle)`
  text-align: center;

  @media (${devices.laptop}) {
    text-align: left;
  }
`;

export const DescriptionContainerStyled = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;

  @media (${devices.laptop}) {
    flex-direction: row;
    gap: 40px;
  }
`;

export const DescriptionStyled = styled(Typography)`
  line-height: 23px;
  text-align: justify;

  @media (${devices.tablet}) {
    line-height: 26px;
  }
`;

export const QuoteStyled = styled(Typography)`
  font-style: oblique;
  text-align: center;
  line-height: 21px;

  @media (${devices.laptop}) {
    text-align: left;
    line-height: 24px;
  }
`;
