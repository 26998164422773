import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const DocumentTabStyled = styled.div`
  max-width: 375px;
  margin: 0 auto;

  @media (min-width: ${screenSizes.md}) {
    max-width: 692px;
    margin: 0 auto;

    .tab {
      width: 100%;
    }
  }
  @media (min-width: ${screenSizes.lg}) {
    max-width: 1000px;
    margin: 0 auto;
  }
  @media (min-width: ${screenSizes.xl}) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;
