import React, { useState } from "react";
import Modal from "@shared/designComponents/Modal";
import SectionTitle from "@shared/designComponents/SectionTitle";
import ColorText from "@shared/designComponents/SectionTitle/ColorText";
import Typography from "@shared/designComponents/Typography";
import Input from "@shared/designComponents/Input";
import Button from "@shared/designComponents/Button";

const Login = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        size="lg"
        isFullWidth={false}
        isCentered={false}
        // title="Your Modal Title"
      >
        <div>
          <SectionTitle style={{ fontSize: "20px" }}>
            Welcome to <ColorText color="#26C9A4">energyfix</ColorText>
          </SectionTitle>
          <SectionTitle style={{ fontSize: "16px" }}>
            <ColorText color="#20435D">Sign In</ColorText> to get access to
            <ColorText color="#26C9A4"> energyFix</ColorText>
          </SectionTitle>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography>Email Address</Typography>
            <Input iconRight={<img src="assets/icons/mail.svg" />} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "5px",
            }}
          >
            <Typography>Password</Typography>
            <Input iconRight={<img src="assets/icons/password.svg" />} />
          </div>
          <Typography
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#20435D",
              marginBottom: "20px",
            }}
          >
            forget password?
          </Typography>

          <Button>Sign In</Button>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
