// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { requestAPI } from "@shared/utils";
// import regionConfig from "../../api/regions";
// import { RootState } from "..";

// const initialState = {
//   isFetchingRegions: false,
//   regions: [],
// };

// export const getAllRegions = createAsyncThunk(
//   "region/getAllRegions",
//   async (params, thunkAPI) => {
//     try {
//       const response = await requestAPI(regionConfig.getAllRegions({ params }));
//       return response.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue("Failed to fetch regions");
//     }
//   }
// );

// const regionSlice = createSlice({
//   name: "region",
//   initialState,
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(getAllRegions.pending, (state) => {
//         state.isFetchingRegions = true;
//       })
//       .addCase(getAllRegions.fulfilled, (state, action) => {
//         state.isFetchingRegions = false;
//         state.regions = action.payload;
//       })
//       .addCase(getAllRegions.rejected, (state) => {
//         state.isFetchingRegions = false;
//       });
//   },
// });

// export const selectIsFetchingRegions = (state: RootState) =>
//   state.region.isFetchingRegions;

// export const selectRegions = (state: RootState) => state.region.regions;

// export default regionSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { requestAPI } from "@shared/utils";
import regionConfig from "@shared/apiConfigs/regions";
import { RootState } from "..";

const initialState = {
  isFetchingRegions: false,
  regions: [],
  isFetchingRegionCities: false,
  regionCities: [],
};

export const getAllRegions = createAsyncThunk(
  "region/getAllRegions",
  async (params, thunkAPI) => {
    try {
      const response = await requestAPI(regionConfig.getAllRegions({ params }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch regions");
    }
  }
);

export const getRegionCities = createAsyncThunk(
  "region/getRegionCities",
  async (regionId: string, thunkAPI) => {
    try {
      const response = await requestAPI(regionConfig.getRegionCities(regionId));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue("Failed to fetch region cities");
    }
  }
);

const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllRegions.pending, (state) => {
        state.isFetchingRegions = true;
      })
      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.isFetchingRegions = false;
        state.regions = action.payload;
      })
      .addCase(getAllRegions.rejected, (state) => {
        state.isFetchingRegions = false;
      })
      .addCase(getRegionCities.pending, (state) => {
        state.isFetchingRegionCities = true;
      })
      .addCase(getRegionCities.fulfilled, (state, action) => {
        state.isFetchingRegionCities = false;
        state.regionCities = action.payload;
      })
      .addCase(getRegionCities.rejected, (state) => {
        state.isFetchingRegionCities = false;
      });
  },
});

export const selectIsFetchingRegions = (state: RootState) =>
  state.region.isFetchingRegions;

export const selectRegions = (state: RootState) => state.region.regions;

export const selectIsFetchingRegionCities = (state: RootState) =>
  state.region.isFetchingRegionCities;

export const selectRegionCities = (state: RootState) =>
  state.region.regionCities;

export default regionSlice.reducer;
