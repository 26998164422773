import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${screenSizes.lg}) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
  }
`;

export const TabsContainer = styled.div`
  position: relative;
  top: auto;

  max-width: 400px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  flex-shrink: 0;
  @media (min-width: ${screenSizes.md}) {
    margin-top: 0px;
  }
  @media (min-width: ${screenSizes.lg}) {
    position: sticky;
    top: 100px;
    margin-top: 90px;
  }
`;

export const Tab = styled.div<{ isActive: boolean }>`
  padding: 10px;
  cursor: pointer;
  min-width: 300px;
  font-size: 16px;

  & .category-tabs-title {
    font-size: 12px;
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.primary : theme.colors.lightText};
  }
`;

export const FAQContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #e9f4ff;
  border-radius: 10px;
`;

export const FAQContent = styled.div``;

export const Header = styled.div`
  display: none;

  @media (min-width: ${screenSizes.lg}) {
    display: block;
  }
`;

export const Headers = styled.div`
  display: block;
  margin-top: 0px;

  @media (min-width: ${screenSizes.md}) {
    margin-bottom: 50px;
  }

  @media (min-width: ${screenSizes.lg}) {
    display: none;
  }
`;
