import React, { useRef } from "react";

import {
  IconLeft,
  IconRight,
  InputContainerStyled,
  InputStyled,
} from "./style";

interface InputProps extends React.ComponentProps<"input"> {
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  className?: string;
  [key: string]: any;
}

const Input: React.FC<InputProps> = ({
  iconLeft,
  iconRight,
  className = "",
  containerStyle = {},
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleContainerClick = (_e: React.MouseEvent<HTMLDivElement>) => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <InputContainerStyled style={containerStyle} onClick={handleContainerClick}>
      {iconLeft && <IconLeft>{iconLeft}</IconLeft>}
      <InputStyled ref={inputRef} {...restProps} />
      {iconRight && <IconRight>{iconRight}</IconRight>}
    </InputContainerStyled>
  );
};

export default Input;
