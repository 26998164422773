import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";
import { devices } from "@shared/theme/device";

export const WrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.blue.light};
  padding: 20px 16px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 30px;
  border-radius: 12px;

  @media (${devices.tablet}) {
    padding: 30px 36px;
  }

  @media (min-width: ${screenSizes.lg}) {
    padding: 50px 47px;
    flex-direction: row;
  }

  @media (min-width: ${screenSizes.xl}) {
    gap: 60px;
  }
`;

export const Section = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;

  & .section-number {
    font-size: 28px;
    line-height: 30px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (min-width: ${screenSizes.lg}) {
    flex: 1;
    justify-content: space-between;
  }

  & iframe {
    @media (min-width: ${screenSizes.lg}) {
      flex: 3;
    }
  }
`;
