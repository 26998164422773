import PropertyDocuments from "../../../components/CostAndGrants/VacantHomesGrant/PropertyDocuments";
import RefurbishmentGrant from "../../../components/CostAndGrants/VacantHomesGrant/RefurbishmentGrant";
import EnergyfixCTASection from "../../../components/Shared/EnergyfixCTASection";

const VacantHomesGrant = () => {
  return (
    <>
      <RefurbishmentGrant />
      <PropertyDocuments />
      <EnergyfixCTASection />
    </>
  );
};

export default VacantHomesGrant;
