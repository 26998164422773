import styled, { useTheme } from "styled-components";
import {
  Button,
  ColorText,
  Image,
  Modal,
  SectionTitle,
  Typography,
} from "@shared/designComponents";
import Card from "@shared/designComponents/Card";
import { homeCardData } from "./cardData";
import { EnergyRetrofitCardStyled } from "./style";
import Container from "@shared/designComponents/Container";
import { useState } from "react";
import { screenSizes } from "@shared/theme/screen";

const EnergyRetrofitCard = () => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState<string[]>([]);
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentImg, setCurrentImg] = useState("");

  const handleOpen = (description: string, title: string, img: string) => {
    const paragraphs = description.split("\n");
    setCurrentDescription(paragraphs);
    setCurrentImg(img);
    setCurrentTitle(title);
    setIsOpen(true);
  };
  const handleClose = () => setIsOpen(false);
  return (
    <Container>
      <EnergyRetrofitCardStyled>
        <Modal open={isOpen} onClose={handleClose} isCentered>
          <PopupStyled className="modal-content">
            <Image
              src={currentImg}
              alt={currentTitle}
              style={{ width: "30%", height: "30%" }}
            />
            <div className="modal-text">
              <Typography
                size={["md", "md", "lg"]}
                className="modal-title"
                color={theme.colors.primary}
                style={{ width: " 100%" }}
              >
                {currentTitle}
              </Typography>
              {currentDescription.map((paragraph, index) => (
                <Typography
                  key={index}
                  size={["sm", "sm", "md"]}
                  className="modal-description"
                  style={{ marginBottom: "10px" }}
                >
                  {paragraph}
                </Typography>
              ))}
            </div>
          </PopupStyled>
        </Modal>

        <div className="heading">
          <SectionTitle className="section-title">
            Key Measures in
            <ColorText color={theme.colors.secondary}> Home </ColorText>
            <ColorText color={theme.colors.primary}>Energy Retrofit</ColorText>
          </SectionTitle>
          <Typography size={["sm", "sm", "md"]} className="section-subtitle">
            <ColorText color={theme.colors.secondary}>
              Enhancing Efficiency and Comfort
            </ColorText>
          </Typography>
        </div>

        <div className="card">
          {homeCardData.map((cardData) => (
            <Card
              key={cardData.title}
              variant="withoutCountAndButton"
              description={cardData.shortDescription}
              imgurl={cardData.imgUrl}
              heading={cardData.title}
              footer={
                <>
                  <Button
                    variant="linkWithArrow"
                    onClick={() =>
                      handleOpen(
                        cardData.fullDescription,
                        cardData.title,
                        cardData.imgUrl
                      )
                    }
                  >
                    Read More
                  </Button>
                </>
              }
            />
          ))}
        </div>
      </EnergyRetrofitCardStyled>
    </Container>
  );
};

export default EnergyRetrofitCard;

export const PopupStyled = styled.div`
  .modal-title {
    margin-top: 20px;
    width: 171px;
  }
  .modal-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  @media (min-width: ${screenSizes.lg}) {
    display: flex;
    gap: 50px;
    align-items: center;

    .modal-title {
      margin-top: 0px;
    }
  }
`;
