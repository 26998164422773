import { AskQuestion, CategoriesTabs } from "../../components/Faqs";

const Faqs = () => {
  return (
    <>
      <CategoriesTabs />
      <AskQuestion />
    </>
  );
};

export default Faqs;
