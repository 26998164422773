import { EnergyRetrofitIndividualCardPageStyled } from "./style";
import {
  ColorText,
  Container,
  Image,
  SectionTitle,
  Typography,
} from "@shared/designComponents";
import theme from "@shared/theme";
import { CardDataType } from "./cardData";

const IndividualCardSection = ({
  imgUrl,
  sn,
  title,
  description,
}: CardDataType) => {
  return (
    <Container marginVertical={50}>
      <EnergyRetrofitIndividualCardPageStyled>
        <div className="heading">
          <SectionTitle className="section-title">
            Key Measures in
            <ColorText color={theme.colors.secondary}> Home </ColorText>
            <ColorText color={theme.colors.primary}>Energy Retrofit</ColorText>
          </SectionTitle>
          <Typography size={["sm", "sm", "md"]} className="section-subtitle">
            <ColorText color={theme.colors.secondary}>
              Enhancing Efficiency and Comfort
            </ColorText>
          </Typography>
        </div>
        <div className="description">
          <Image src={imgUrl} alt="Renewable Energy" className="img" />
          <div className="title-and-title-description">
            <div className="title">
              <Typography
                size={["_2xl", "_2xl", "_4xl"]}
                fontWeight="bold"
                style={{ marginRight: "18px" }}
              >
                <ColorText color={theme.colors.primary}>{sn}. </ColorText>
              </Typography>
              <Typography size={["md", "md", "lg"]} fontWeight="semibold">
                <ColorText color={theme.colors.secondary}>{title}</ColorText>
              </Typography>
            </div>
            <div className="title-description">{description}</div>
          </div>
        </div>
      </EnergyRetrofitIndividualCardPageStyled>
    </Container>
  );
};

export default IndividualCardSection;
