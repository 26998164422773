import IndividualCardSection from "./individualCardSection";
import { ColorText, Typography } from "@shared/designComponents";
import { useTheme } from "styled-components";
import doorPNG from "../../../assets/images/homeCardIndividualPage-img/door-and-window-upgrades.png";
import { EnergyfixCTASection } from "../../Shared";

const DoorCard = () => {
  const theme = useTheme();
  return (
    <div>
      <IndividualCardSection
        imgUrl={doorPNG}
        sn="05"
        title="Door and Window Upgrades"
        description={
          <>
            <Typography size={"sm"}>
              Draughts through windows and doors are uncomfortable and increase
              your heating bills. Upgrade your doors and windows to current
              standards to reduce outside noise and heat loss by
              <Typography fontWeight="semibold" variant="span">
                {" "}
                <ColorText color={theme.colors.primary}> 10%</ColorText>
              </Typography>{" "}
              . The SEAI grants available for windows range from
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}>
                  {" "}
                  €1,500 - €4,000{" "}
                </ColorText>
              </Typography>
              . The SEAI grant for external doors is
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}> €800 </ColorText>{" "}
              </Typography>
              per door (max.2).
            </Typography>
          </>
        }
      />
      <EnergyfixCTASection />
    </div>
  );
};

export default DoorCard;
