import { ConfigType, ParamsType } from "../utils/requestAPI";

export type RegionParams = {
  _id: string;
  name: string;
  code: string;
  domain?: string;
};

const regionConfig = {
  createRegion: (data: Pick<RegionParams, "name" | "code">): ConfigType => ({
    method: "post",
    url: "/regions",
    data,
  }),
  updateRegion: (
    id: string,
    data: Pick<RegionParams, "name" | "code">
  ): ConfigType => ({
    method: "put",
    url: `/region/${id}`,
    data,
  }),
  deleteRegion: (id: string): ConfigType => ({
    method: "delete",
    url: `/regions/${id}`,
  }),
  getRegionById: (id: string): ConfigType => ({
    method: "get",
    url: `/region/${id}`,
  }),
  getAllRegions: (params?: ParamsType): ConfigType => ({
    method: "get",
    url: `/regions`,
    params,
  }),
  getRegionCities: (id: string): ConfigType => ({
    method: "get",
    url: `/region/${id}/cities`,
  }),
};

export default regionConfig;
