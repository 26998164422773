import styled from "styled-components";
import Button from "@shared/designComponents/Button";

export const FormStyled = styled.form`
  display: flex;
`;

export const ButtonStyled = styled(Button)`
  border-radius: 0 5px 5px 0;
  white-space: nowrap;
  min-width: 80px;
  cursor: pointer;
`;
