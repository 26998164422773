import Table from "@shared/designComponents/Table";
import theme from "@shared/theme";
import { grantTable } from "./grantsData";
import { GrantsStyled } from "./style";
import { toast } from "react-toastify";
import { requestAPI } from "@shared/utils";
import userDashboardConfig from "@shared/apiConfigs/userDashboard";
import { useEffect, useState } from "react";
import { useRoot } from "@shared/context/RootProvider";
import { formatGrantsTableData } from "./helperFunction";

export interface GrantsDataType {
  name: string;
  updatedAt: string;
  createdAt: string;
  reliefAmount: number;
}
const Grants = () => {
  const [grantsData, setGrantsData] = useState<GrantsDataType[]>([]);
  const { auth } = useRoot();
  const { _id: id } = auth;
  const getGrants = async () => {
    try {
      const { data } = await requestAPI(
        userDashboardConfig.getGrantsByUserId(id)
      );
      setGrantsData(data);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    getGrants();
  }, []);
  return (
    <GrantsStyled>
      <Table
        data={{
          header: grantTable.header,
          body: formatGrantsTableData(grantsData),
        }}
        style={{ backgroundColor: theme.colors.grayFA }}
      />
    </GrantsStyled>
  );
};

export default Grants;
