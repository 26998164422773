import styled from "styled-components";
import sizes from "@shared/theme/sizes";
import { screenSizes } from "@shared/theme/screen";
import fontWeights from "@shared/theme/fontWeights";
import { devices } from "@shared/theme/device";

export const Spacer = styled.div`
  width: 100%;
  height: 60px;
`;

export const HeaderContainer = styled.header`
  & .top-header {
    background-color: ${({ theme }) => theme.colors.gray.light};
    padding: 12px 37px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      text-align: center;
    }
  }

  & .main-header__wrapper {
    background-color: ${({ theme }) => theme.colors.blue.light};
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;

    & .main-header {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__ham-icon {
        @media (min-width: ${screenSizes.lg}) {
          display: none;
        }
      }
    }
  }
`;

export const MobileHeaderNavigation = styled.nav<{ $open?: boolean }>`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  display: ${(props) => (props.$open ? "flex" : "none")};
  flex-direction: column;
  height: 200vh;

  @media (min-width: ${screenSizes.lg}) {
    display: flex;
    align-items: center;
    background-color: none;
    position: relative;
    flex-direction: row;
    width: auto;
    padding: 0;
    height: auto;
  }

  & .header-nav {
    background-color: ${({ theme }) => theme.colors.blue.light};
    padding: 20px 37px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: ${screenSizes.lg}) {
      flex-direction: row;
      padding: 0;
      align-items: center;
    }

    & .dropdown-children {
      width: 180px;
      background-color: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.border};
      border-radius: 10px;
      margin-top: 15px;
      padding: 5px 0;

      li {
        padding-left: 20px;
      }
    }

    &__list {
      width: 100%;
      max-height: 70vh;
      overflow-y: auto;

      @media (min-width: ${screenSizes.lg}) {
        width: auto;
      }

      & .nav-link {
        display: block;
        margin: 20px 0;

        &:hover span {
          color: #000;
        }

        @media (min-width: ${screenSizes.lg}) {
          display: inline-block;
          margin: 0;
          margin-right: 50px;
        }
      }
    }

    &__btn {
      &--close {
        align-self: flex-end;

        @media (min-width: ${screenSizes.lg}) {
          display: none;
        }
      }

      &--signin {
        align-self: flex-start;
        background-color: ${({ theme }) => theme.colors.secondary};
        padding: 5px 20px;
        border-radius: 29px;
        color: ${({ theme }) => theme.colors.white};
        font-family: "Poppins";
        font-size: ${sizes.md};
        cursor: pointer;
      }
    }

    &__user-profile {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 12px;
      width: 100%;

      @media ${devices.laptop} {
        width: auto;
        margin-top: 0;
      }

      &--img {
        width: 30px;
        height: 30px;
        border-radius: 50%;

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export const HeaderDropdown = styled.div`
  display: none;

  @media (min-width: ${screenSizes.lg}) {
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
  }

  & .header-dropdown-container {
    background-color: #fff;

    & .header-dropdown-nav {
      @media (min-width: ${screenSizes.lg}) {
        padding: 20px 0;
        max-width: 827px;
        margin: 0 auto;
      }

      &__list {
        display: flex;
        gap: 74px;

        &-item1 {
          flex: 1;

          & > a {
            display: inline-block;
            margin-bottom: 1rem;
          }
        }

        &-item2 {
          & > a {
            display: inline-block;
            margin-bottom: 12px;

            &:hover span {
              color: ${({ theme }) => theme.colors.primary};
            }
          }
        }

        &-item3 {
          & > a {
            display: inline-block;
            margin-bottom: 6px;

            &:hover span {
              color: ${({ theme }) => theme.colors.primary};
            }
          }

          &:last-child > a {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
`;

export const MobileHeaderDropdown = styled.div`
  & .mobile-dropdown {
    &__header-firstHeader {
      margin-bottom: 20px;

      & + div > ul {
        margin: 20px 0;

        // second level header
        & > div:nth-child(odd) {
          margin-bottom: 16px;

          & > ul {
            margin-top: 16px;
            & li {
              margin-bottom: 8px;
            }
          }
        }

        & > li {
          margin-bottom: 16px;
          & span {
            font-size: 14px;
            font-weight: 500;
          }
        }

        & > div:nth-child(even) {
          & ul {
            & > div:first-child {
              margin-bottom: 8px;

              & span {
                font-size: ${sizes.xs};
                font-weight: ${fontWeights.semibold};
              }
            }

            & > div:nth-child(2) {
              & > ul li span {
                font-weight: ${fontWeights.normal};
              }
            }

            & li {
              margin-bottom: 8px;

              &:last-child {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
    }
  }
`;

export const AlternateSignIn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
`;

export const SignInWithSocial = styled.div`
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  padding: 5px 39px;
  cursor: pointer;
`;
