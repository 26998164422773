import React, { useEffect, useState } from "react";
import { devices } from "../../theme/device";
import styled, { keyframes } from "styled-components";

export type SizeType = "sm" | "md" | "lg" | number;

const getModalSize = (size: SizeType) => {
  if (typeof size === "number") {
    return size + "px";
  }
  switch (size) {
    case "lg":
      return "40%";
    case "md":
      return "56%";
    case "sm":
      return "37%";

    default:
      return 137;
  }
};

const OverlayStyled = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.overlay};
  background: black;
  opacity: 60%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;

const slideInCenter = keyframes`
  from {
    transform: translate(-50%, 100vh);
  }
  to {
    transform: translate(-50%,-50%);
  }
`;
const slideIn = keyframes`
  from {
    transform: translate(-50%, 100vh);
  }
  to {
    transform: translate(-50%,0%);
  }
`;

const slideOut = keyframes`
  from {
    transform: translate(-50%, 0%);
  }
  to {
    transform: translate(-50%,100vh);
  }
`;

const slideOutCenter = keyframes`
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%,100vh);
  }
`;

const ModalStyled = styled.div<{
  size: SizeType;
  isFullWidth: boolean;
  isCentered?: boolean;
  offset?: {
    top: string;
    left: string;
  };
  isOpen: boolean;
}>`
  border-radius: 6px;
  position: fixed;
  background: white;
  top: ${({ isCentered, isFullWidth }) =>
    isCentered ? "50%" : isFullWidth ? "0px" : "100px"};
  left: 50%;
  transform: ${({ isCentered }) =>
    isCentered ? "translate(-50%, -50%)" : "translate(-50%, 0%)"};

  padding: 50px 35px;
  height: ${({ isFullWidth }) => (isFullWidth ? "calc(100vh - 0px)" : "auto")};
  /* width: ${({ size, isFullWidth }) =>
    isFullWidth ? "100%" : getModalSize(size)}; 
    TODO: This needs to be refactored for responsiveness  
  */
  width: 90%;

  @media ${devices.tablet} {
    width: auto;
    max-width: 715px;
  }

  z-index: ${({ theme }) => theme.zIndices.modal};
  & .close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 40px;
    font-weight: 300;
    margin-left: auto;
  }
  animation: ${(props) =>
      props.isOpen
        ? props.isCentered
          ? slideInCenter
          : slideIn
        : props.isCentered
        ? slideOutCenter
        : slideOut}
    0.4s ease-in-out;
`;
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`;
const ModalTitle = styled.h4`
  font-size: 18px;
  margin: 0px;
`;

interface IModalProps {
  style?: React.CSSProperties;
  open: boolean;
  offset?: {
    top: string;
    left: string;
  };
  onClose: () => void;
  size?: SizeType;
  isFullWidth?: boolean;
  isCentered?: boolean;
  title?: string;
}

const Modal: React.FC<React.PropsWithChildren<IModalProps>> = ({
  style = {},
  offset,

  open = false,
  onClose,
  children,
  size = "md",
  isFullWidth = false,
  isCentered = false,
  title,
}) => {
  const [localStatus, setLocalStatus] = useState(true);

  useEffect(() => {
    if (open) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "unset";
      }
    }
    return () => {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "unset";
      }
    };
  }, [open]);

  const handleClose = () => {
    setLocalStatus(false);
    setTimeout(() => {
      setLocalStatus(true);

      onClose();
    }, 300);
  };

  if (!open) {
    return null;
  }
  return (
    <>
      <OverlayStyled onClick={handleClose} />
      <ModalStyled
        size={size as SizeType}
        isFullWidth={isFullWidth}
        isCentered={isCentered}
        style={style}
        offset={offset}
        isOpen={localStatus}
      >
        <ModalHeader>
          {!!title && <ModalTitle>{title}</ModalTitle>}
          <div
            className="close"
            onClick={() => {
              handleClose();
            }}
          >
            <div>&times;</div>
          </div>
        </ModalHeader>

        {children}
      </ModalStyled>
    </>
  );
};

export default Modal;
