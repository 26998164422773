import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Image from "../Image";
import Typography from "../Typography";
import Button from "../Button";
import Flex from "../Flex";

const OverlayStyled = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndices.overlay};
  background: black;
  opacity: 60%;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;

const slideIn = keyframes`
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100vh);
  }
`;

const ModalStyled = styled.div<{
  offset?: {
    top: string;
    left: string;
  };
  isOpen: boolean;
}>`
  border-radius: 6px;
  position: fixed;
  background: white;
  top: ${({ offset }) => offset?.top || "calc(100vh - 600px)"};
  left: ${({ offset }) => offset?.left || "calc(100vw - 600px)"};
  width: 460px;
  min-height: 500px;
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndices.modal};
  transition: 0.3s;

  @media (max-width: 768px) {
    left: 0px;
    right: 0px;
    width: 100%;
    bottom: 0px;
    top: auto;
  }

  & .close {
    cursor: pointer;
    margin-left: auto;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 20;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.white};
    font-size: 35px;
    background-color: ${({ theme }) => theme.colors.secondary};
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  animation: ${(props) => (props.isOpen ? slideIn : slideOut)} 0.4s ease-in-out;
`;

type Props = {
  style?: React.CSSProperties;
  open: boolean;
  offset?: {
    top: string;
    left: string;
  };
  image: string;
  title: string;
  description: string;
  onClick: () => void;
  onClose: () => void;
};

const HotspotModal = ({
  onClose,
  open,
  style,
  offset,
  image,
  description,
  onClick,
  title,
}: Props) => {
  const [localStatus, setLocalStatus] = useState(true);
  useEffect(() => {
    if (open) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "unset";
      }
    }
    return () => {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "unset";
      }
      setLocalStatus(true);
    };
  }, [open]);

  const handleClose = () => {
    setLocalStatus(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  if (!open) {
    return null;
  }
  return (
    <>
      <OverlayStyled onClick={handleClose} />
      <ModalStyled style={style} offset={offset} isOpen={localStatus}>
        <div
          className="close"
          onClick={() => {
            handleClose();
          }}
        >
          <div>&times;</div>
        </div>
        <Image src={image} height="200px" style={{ marginBottom: "30px" }} />
        <Flex direction="column" gap="20px" padding="20px 30px">
          <Typography fontWeight="semibold" size="xl">
            {title}
          </Typography>

          <Typography>{description}</Typography>

          {/* <Button variant="linkWithArrow" onClick={onClick}>
            Learn More
          </Button> */}
        </Flex>
      </ModalStyled>
    </>
  );
};

export default HotspotModal;
