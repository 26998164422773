import { Container, ReadOnlyEditor } from "@shared/designComponents";
import Table from "@shared/designComponents/Table";
import { TableWrapperStyled } from "./style";
import { costAndGrantTable } from "./tableData";
import { requestAPI } from "@shared/utils";
import grantTableInfoConfig from "@shared/apiConfigs/grantTableRows";
import { useEffect, useState } from "react";
import moment from "moment";
import { object } from "yup";

export interface DataType {
  _id: string;
  homeType: string;
  name: string;
  value: string;
  slug: string;
}

const GrantsTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await requestAPI(
          grantTableInfoConfig.getAllGrantTableRows({ limit: 100 })
        );
        const groupedData: any = {};

        response.data.forEach((item: DataType) => {
          const key = item.name.trim().toLowerCase();

          if (!groupedData[key]) {
            groupedData[key] = {
              name: item.name,
              homeType: [],
              value: [],
            };
          }

          groupedData[key].homeType.push(item.homeType);
          groupedData[key].value.push(item.value);
        });

        const newData = Object.values(groupedData).map((item: any) => {
          return [
            { label: "grant_name", value: item.name },
            {
              label: "types_of_home",
              value: item.homeType.map((value: string) => (
                <ReadOnlyEditor content={value} key={value} />
              )),
            },
            {
              label: "grant_value",
              value: item.value.map((value: string, idx: number) => (
                <ReadOnlyEditor content={value} key={idx} />
              )),
            },
          ];
        });

        setData(newData);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);
  return (
    <TableWrapperStyled>
      <Container enableMargin={false}>
        <Table
          data={{
            header: costAndGrantTable.header,
            body: data,
          }}
        />
      </Container>
    </TableWrapperStyled>
  );
};

export default GrantsTable;
