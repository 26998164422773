import energyConfig from '../config';
import energyConstants from '../constants';

const LOCAL_STORAGE_API_KEY = energyConstants.LOCAL_STORAGE_KEY.api;
let API: string = localStorage.getItem(LOCAL_STORAGE_API_KEY) || '';

export const envAPI: string = energyConfig.API_URL || '';

export const loadInital = async () => {
  const api = await localStorage.getItem(LOCAL_STORAGE_API_KEY);

  if (api) {
    API = api;
  } else {
    API = envAPI;
  }
  return API;
};

export const changeAPI = async (endpoint: string) => {
  if (endpoint) {
    API = endpoint;
    await localStorage.setItem(LOCAL_STORAGE_API_KEY, endpoint);
  }
  return API;
};
export const setDefaultEnvApi = () => {
  API = envAPI;
};

export const getAPI = () => {
  API = localStorage.getItem(LOCAL_STORAGE_API_KEY) || '';
  return API || envAPI;
};
