import { HotspotsSection } from "../../components/Shared";
import EnergyRetrofitCard from "../../components/WhatIsRetrofit/EnergyRetrofitCard";
import RetrofitBanner from "../../components/WhatIsRetrofit/RetrofitBanner";
import EnergyfixCTASection from "../../components/Shared/EnergyfixCTASection";

const WhatIsRetrofit = () => {
  return (
    <>
      <RetrofitBanner />
      <EnergyRetrofitCard />
      <HotspotsSection />
      <EnergyfixCTASection />
    </>
  );
};

export default WhatIsRetrofit;
