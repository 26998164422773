import { ConfigType, ParamsType } from "../utils/requestAPI";

type CityParams = {
  _id: string;
  regionId: string;
  name: string;
};

const cityConfig = {
  createCity: (data: Pick<CityParams, "regionId" | "name">): ConfigType => ({
    method: "post",
    url: "/cities",
    data,
  }),
  updateCity: (
    id: string,
    data: Pick<CityParams, "name" | "regionId">
  ): ConfigType => ({
    method: "put",
    url: `/cities/${id}`,
    data,
  }),
  deleteCity: (id: string): ConfigType => ({
    method: "delete",
    url: `/cities/${id}`,
  }),
  getCityById: (id: string): ConfigType => ({
    method: "get",
    url: `/cities/${id}`,
  }),
  getAllCities: (params?: ParamsType): ConfigType => ({
    method: "get",
    url: `/cities`,
    params,
  }),
};

export default cityConfig;
