import { Link } from "react-router-dom";
import { Button, Typography } from "@shared/designComponents";
import Card from "@shared/designComponents/Card";
import colors from "@shared/theme/colors";
import { REGISTER_INTEREST_PATH } from "../../../routes/routePaths";

export const cardItems = [
  <Card
    key={1}
    count={1}
    heading="Get in Contact"
    description="Fill out our short form to register your interest."
    footer={
      <Link to={REGISTER_INTEREST_PATH}>
        <Button fullWidth>
          <Typography
            variant="span"
            size="sm"
            fontWeight="medium"
            color={colors.white}
          >
            Submit your interest
          </Typography>
        </Button>
      </Link>
    }
  />,
  <Card
    key={2}
    count={2}
    heading="Call Back"
    description="EnergyFix Team will contact you to validate your app and explain the scheme."
  />,
  <Card
    key={3}
    count={3}
    heading="Home Assessment"
    description="An independent assessor will visit your home."
  />,
  <Card
    key={4}
    count={4}
    heading="BER Report"
    description="You will receive your Home energy upgrade report and a member of EnergyFix will be able to answer any questions you may have."
  />,
  <Card
    key={5}
    count={5}
    heading="Decide"
    description="Once you have decided to proceed contact EnergyFix and we will handle the contract and grants process."
  />,
  <Card
    key={6}
    count={6}
    heading="Works commence"
    description="EnergyFix Project Managers will oversee all works and ensure our high quality workmanship is met."
  />,
  <Card
    key={7}
    count={7}
    heading="Post Works BER"
    description="Energyfix will organise your post works BER assessment."
  />,
  <Card
    key={8}
    count={8}
    heading="Works Complete"
    description="Works are complete and you enjoy a warmer and more energy efficient home."
    footer={
      <Link to={REGISTER_INTEREST_PATH}>
        <Button fullWidth>Submit your interest</Button>
      </Link>
    }
  />,
];
