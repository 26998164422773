import styled from "styled-components";
import { devices } from "@shared/theme/device";

export const CarouselWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-bottom: 200px;

  @media (${devices.laptop}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;

  @media (${devices.laptop}) {
    max-width: 330px;
    gap: 70px;
  }

  & .section-header {
    text-align: center;
    max-width: 340px;
    margin: 0 auto;

    @media ${devices.tablet} {
      max-width: 500px;
    }

    @media ${devices.laptop} {
      text-align: left;
    }
  }
`;

export const NavWrapper = styled.div`
  display: none;
  gap: 10px;

  @media (${devices.laptop}) {
    display: flex;
  }
`;
