import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const ProfileWrapperStyled = styled.div`
  margin-top: 30px;
  max-width: 1440px;
  margin: 0 auto;
`;

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
  margin-bottom: 30px;

  @media (min-width: ${screenSizes.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1000px;
  }
`;

export const Interested = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const CheckboxStyled = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  margin-bottom: 30px;
`;
