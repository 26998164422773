export interface CustomerCharterDataType {
  title: string;
  id: number;
  content: string;
}

export const customerCharterData: CustomerCharterDataType[] = [
  {
    title: "Our Commitment to you",
    id: 1,
    content:
      "EnergyFix is a One Stop Shop which looks after all the key stages of a home energy retrofit, from retrofit design through to project completion and payment of SEAI grant funding. Our team of experienced retrofit specialists engage leading contractors to offer you expert guidance through an often-complex process. Our aim is to provide peace of mind, quality assurance and an energy efficient home.",
  },
  {
    title: "Enquiries & Information",
    id: 2,
    content:
      "EnergyFix will handle all enquiries with courtesy and sensitivity, with minimum delays. We aim to have all our information, whether it be by website, phone or email, fully up-to-date and presented in the clearest, most accessible, terms. We aim to answer all calls to our dedicated lines as quickly as possible during opening hours. Our consumer helpline is open Monday to Friday 9.00 a.m. to 5.30 p.m.",
  },
  {
    title: "Independence, Transparency and Value-for-money.",
    id: 3,
    content:
      "We will only recommend energy measures that are required to achieve EnergyFix standard and the targets of grant funding, nothing more.",
  },
  {
    title: "Confidentiality",
    id: 4,
    content:
      "EnergyFix complies with all GDPR legislation and best practice; we shall treat your project and your data in strictest confidence.",
  },
  {
    title: "How You Can Help Us",
    id: 5,
    content:
      "We are a company, growing in size, operating in a relatively new industry. Our reputation is dependent on your satisfaction and so we aim to correct any errors or miscommunications, courteously, respectfully and impartially. Your honest feedback and comments are greatly appreciated so that we can refine and improve our service, to you and others in the future. Once your retrofit is completed you will receive a feedback survey.",
  },
  {
    title: "Issues and Complaints",
    id: 6,
    content:
      "Please reach out to our team on info@energyfix.ie. We will acknowledge your complaint within 5 working days and try to resolve the issue within 10 working days.",
  },
  {
    title: "Complaints Escalation Procedure",
    id: 7,
    content:
      "If we cannot resolve an issue to your satisfaction, or if you feel that we have not lived up to the commitments of our Charter, you can lodge a formal complaint with our Customer Service Manager or you can request a meeting by emailing contact@energyfix.ie. The Customer Service Manager will acknowledge your complaint within 5 working days and try to resolve the issue within 10 working days. If they need to further investigate the matter, they will let you know and advise you when they expect to be able to provide a full response.",
  },
];
