import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const CertificateStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.grayFA};
  padding: 13px;

  @media (min-width: ${screenSizes.md}) {
    padding: 0;
  }
`;
