const breakpoints = {
  base: '0em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  xxl: '96em',
};
export default breakpoints;

export type BreakPointType = typeof breakpoints;
