import { useTheme } from "styled-components";
import { ColorText, Container, SectionTitle } from "@shared/designComponents";
import PageTitle from "@shared/designComponents/PageTitle";
import { SectionTitleStyled } from "./style";

const HeroSection = () => {
  const theme = useTheme();
  return (
    <Container marginVertical={50}>
      <PageTitle>
        <ColorText color={theme.colors.primary}> Brighten </ColorText>

        <ColorText color={theme.colors.secondary}>Your Home, </ColorText>
        <ColorText color={theme.colors.primary}> Lighten </ColorText>
        <ColorText color={theme.colors.secondary}>Your Bill.</ColorText>
        {/* Enhance your
        <ColorText color={theme.colors.secondary}> Home’s </ColorText>comfort
        and
        <ColorText color={theme.colors.secondary}>
          {" "}
          Energy Efficiency{" "}
        </ColorText>
        with an
        <ColorText color={theme.colors.primary}>
          {" "}
          EnergyFix Energy Retrofit
        </ColorText> */}
      </PageTitle>
      {/* <SectionTitleStyled>
        The House Build Retrofit for
        <ColorText color={theme.colors.primary}>Energy Efficiency</ColorText>.
      </SectionTitleStyled> */}
    </Container>
  );
};

export default HeroSection;
