import styled from "styled-components";
import colors from "../../theme/colors";

export const StyledLargeTable = styled.table`
    width: 100%;
    
    & thead {
        background-color: ${colors.gray.lighter};
        border: 1px solid ${colors.gray.lighter};
    }
    
    & th {
        padding: 12px 20px;
    }

    & tbody {
        td {
            padding: 12px 20px;
            border: 1px solid ${colors.disable};
        }
    }

    & .table-data__list p:not(:last-child) {
        margin-bottom: 10px;
    }
`

export const StyledSmallTable = styled.table<{$borderAt: number;}>`
    width: 100%;
    background-color: ${colors.gray.lighter};

    & tr {
        &:nth-child(${(props) => props.$borderAt}n) {
            border-bottom: 1px solid ${colors.gray.darker};
            td {
                padding-bottom: 20px;
            }
        }

        &:nth-child(${(props) => props.$borderAt}n + 1) {
            td {
                padding-top: 20px;
            }
        }

        &:first-child td {
            padding-top: 30px;
        }

        &:last-child {
            border-bottom: 0;

            & td {
                padding-bottom: 30px;
            }
        }

        td {
            padding: 10px 0;
        }
    }

    & .table-data__list p:not(:last-child) {
        margin-bottom: 10px;
    }
`
