import styled from "styled-components";
import { flexStart } from "@shared/style/reusableStyle";
import { screenSizes } from "@shared/theme/screen";

export const SubmitInterestStyled = styled.div`
  ${flexStart};
  align-items: flex-start;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${screenSizes.md}) {
    flex-direction: row;
  }

  & .left-container {
    flex: 1;

    &__heading {
      margin-bottom: 40px;

      & span:first-child {
        display: inline-block;
        margin-bottom: 12px;

        @media (min-width: ${screenSizes.sm}) {
          margin-bottom: 20px;
        }
      }
    }

    &__select-heading {
      display: none;

      @media (min-width: ${screenSizes.md}) {
        display: flex;
      }
    }
  }
  & .right-container {
    flex: 1;
    width: 100%;

    & .form-label {
      margin-bottom: 12px;
    }
  }
`;
