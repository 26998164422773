import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const DocumentTabStyled = styled.div`
  display: flex;
  gap: 14px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  overflow-y: auto;

  .tab-item {
    position: relative;
    cursor: pointer;

    .title {
      white-space: nowrap;
      text-align: left;
    }

    &.active {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  @media (min-width: ${screenSizes.md}) {
    margin-bottom: 30px;
    gap: 30px;
  }

  @media (min-width: ${screenSizes.lg}) {
    margin-bottom: 50px;
  }
`;
