import { humanReadableDateWithTime } from "@shared/utils";
import { PaymentsDataType } from ".";
export const formatPaymentsData = (
  data: PaymentsDataType[]
): PaymentsDataType[] => {
  return data?.map((payment) => {
    const newPaymentData: any = Object.entries(payment)
      .map(([key, value]) => {
        switch (key) {
          case "createdAt":
            return {
              label: "createdAt",
              value: humanReadableDateWithTime(value),
            };
          case "description":
            return { label: "description", value: value };
          case "amount":
            return { label: "amount", value: value };
          case "currency":
            return { label: "currency", value: value.toUpperCase() };
          default:
            return null;
        }
      })
      .filter(Boolean); // Remove null entries

    return newPaymentData;
  });
};
