import { useTheme } from "styled-components";
import { ColorText, Container } from "@shared/designComponents";
import service from "../serviceData";
import {
  DescriptionContainerStyled,
  DescriptionStyled,
  QuoteStyled,
  SectionHeaderStyled,
  WrapperStyled,
} from "./style";

const ServicesHeader = () => {
  const theme = useTheme();
  return (
    <Container marginVertical={50}>
      <WrapperStyled>
        <SectionHeaderStyled>
          <ColorText color={theme.colors.secondary}>OUR</ColorText>&nbsp;
          <ColorText color={theme.colors.primary}>SERVICES</ColorText>
        </SectionHeaderStyled>
        <DescriptionContainerStyled>
          <DescriptionStyled
            size={["sm", "sm", "md"]}
            color={theme.colors.footerBlack}
          >
            {service.description}
          </DescriptionStyled>
          <QuoteStyled color={theme.colors.secondary} size={["sm", "sm", "md"]}>
            &ldquo;{service.quote}&rdquo;
          </QuoteStyled>
        </DescriptionContainerStyled>
      </WrapperStyled>
    </Container>
  );
};

export default ServicesHeader;
