import React, { useState } from "react";
import { openModal } from "../../store/slices/exampleSlice";
import { useDispatch, useSelector } from "react-redux";
import Input from "@shared/designComponents/Input";
import Select, { OptionType } from "@shared/designComponents/Select";
import { Form, Formik } from "formik";
import Modal from "@shared/designComponents/Modal";
import { createLead, increment } from "../../store/slices/leadSlice";
import { LeadData } from "@shared/apiConfigs/leads";
import { AnyAction } from "redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { InfoRowStyled } from "./style";
import Button from "@shared/designComponents/Button";
import Checkbox from "@shared/designComponents/Checkbox";
import Radio from "@shared/designComponents/Radio";
import Typography from "@shared/designComponents/Typography";
import ColorText from "@shared/designComponents/SectionTitle/ColorText";
import SectionTitle from "@shared/designComponents/SectionTitle";
import Card from "@shared/designComponents/Card";
// import AliceCarouselComponent from "../../components/Home/CarouselSection";

import PageTitle from "@shared/designComponents/PageTitle";
import ProfileCard from "@shared/designComponents/ProfileCard";
import { profileData } from "../../components/About/CardSection/cardData";

import RetrofitBanner from "../../components/WhatIsRetrofit/RetrofitBanner";
import EnergyfixCTASection from "../../components/Shared/EnergyfixCTASection";
import {
  AirtightnessCard,
  DoorCard,
  RenewableCard,
  SolarCard,
  VentilationCard,
} from "../../components/WhatIsRetrofit/individualCardSection";
import InsulationCard from "../../components/WhatIsRetrofit/individualCardSection/insulationCard";
import EnergyRetrofitCard from "../../components/WhatIsRetrofit/EnergyRetrofitCard";
import CustomerCharter from "../../components/CustomerCharter";
import UserDashboard from "../../components/UserDashboard";
import Terms from "../../components/TermsOfUse";
import UpdateCard from "../../components/UserDashboard/Updates";
import theme from "@shared/theme";
import { updateData } from "../../components/UserDashboard/Updates/data";

const Test = () => {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [selectedValue, setSelectedValue] = useState("option1");

  const handleRadioChange = (e: any) => {
    setSelectedValue(e.target.value);
  };

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const options: OptionType[] = [
    { label: " Apple", value: "apple" },
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
    // Add more options as needed
  ];
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state: any) => state.example);
  const { isLoading, counter } = useSelector((state: any) => state.lead);
  // const options = [
  //   { label: "Kathmandu", value: "Kathmandu" },
  //   { label: "Pokhara", value: "Pokhara" },
  //   { label: "Biratnagar", value: "Biratnagar" },
  //   { label: "Janakpur", value: "Janakpur" },
  //   { label: "Surkhet", value: "Surkhet" },
  // ];
  // const propertyAge = [
  //   { label: "1990", value: "1990" },
  //   { label: "1990", value: "1990" },
  //   { label: "1990", value: "1990" },
  //   { label: "1990", value: "1990" },
  //   { label: "1990", value: "1990" },
  // ];
  // const ber = [
  //   { label: "A1", value: "A1" },
  //   { label: "B1", value: "B1" },
  //   { label: "C1", value: "C1" },
  // ];

  const handleSelectChange = (selectedValue: string) => {
    setSelectedOption(selectedValue);
  };

  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(e.target.value);
  // };

  const handleFormSubmit = async (values: LeadData) => {
    try {
      const dispatchTyped = dispatch as ThunkDispatch<any, any, AnyAction>;
      await dispatchTyped(createLead(values));
      dispatch(openModal());
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  return (
    <>
      <Terms />
      <CustomerCharter />
      <RetrofitBanner />
      <EnergyfixCTASection />
      <div
        style={{
          display: "flex",
          maxWidth: "1250px",
          margin: "0 auto",
          gap: "20px",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "330px",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <SectionTitle>
            7 steps away from a <ColorText color="#26C9A4"> Greener</ColorText>{" "}
            and <ColorText color="#DEE71E">Warmer </ColorText>home.
          </SectionTitle>
          <Button variant="icon">B</Button>
        </div>
        {/* <div style={{ width: "100%" }}>
          <AliceCarouselComponent />
        </div> */}
      </div>
      <button onClick={handleOpenModal}>Open Modal</button>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        size="lg"
        isFullWidth={false}
        isCentered={false}
        // title="Your Modal Title"
      >
        <div>
          <SectionTitle style={{ fontSize: "20px" }}>
            Welcome to <ColorText color="#26C9A4">energyfix</ColorText>
          </SectionTitle>
          <SectionTitle style={{ fontSize: "16px" }}>
            <ColorText color="#20435D">Sign In</ColorText> to get access to
            <ColorText color="#26C9A4"> energyFix</ColorText>
          </SectionTitle>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography>Email Address</Typography>
            <Input iconRight={<img src="assets/icons/mail.svg" />} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginBottom: "5px",
            }}
          >
            <Typography>Password</Typography>
            <Input iconRight={<img src="assets/icons/password.svg" />} />
          </div>
          <Typography
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "#20435D",
              marginBottom: "20px",
            }}
          >
            forget password?
          </Typography>

          <Button style={{ marginBottom: "20px", width: "100%" }}>
            Sign In
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                textAlign: "center",
                padding: "5px 39px",
                height: "50px",
              }}
            >
              <img src="/assets/icons/google.svg" style={{ width: "40px" }} />
              <Typography>Continue with Google</Typography>
            </div>
            <div
              style={{
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                textAlign: "center",
                padding: "5px 39px",
                height: "50px",
              }}
            >
              <img src="/assets/icons/facebook.svg" style={{ width: "30px" }} />

              <Typography>Continue with Facebook</Typography>
            </div>
          </div>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              color: "#20435D",
            }}
          >
            New to Energyfix? <ColorText color="#26C9A4"> SIGN UP</ColorText>
          </Typography>
        </div>
      </Modal>
      <div style={{ display: "flex", gap: "20px", marginLeft: "20px" }}>
        <Card
          heading="Test Card"
          description="This is a test card description. I am testing some very long long long long long long long long long long long long long long long long long long long long long description here."
        />
        <Card variant="withoutCountAndButton" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {profileData.map((data, idx) => (
          <ProfileCard
            key={idx}
            name={data.name}
            post={data.post}
            imgUrl={data.imgUrl}
            // description={data.description}
          />
        ))}
      </div>
      <div style={{ maxWidth: "400px", margin: "20px auto" }}>
        <div>
          <PageTitle>
            Main Page <ColorText color="red">Title</ColorText> React
          </PageTitle>
        </div>
        <div>
          <SectionTitle>
            Main <ColorText color="green">Title</ColorText> React
          </SectionTitle>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Checkbox
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            labelStyle={{ marginLeft: "8px" }}
            style={{ marginRight: "16px" }}
          >
            I agree to the Terms and Conditions and Privacy Policy.
          </Checkbox>
        </div>
        <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
          <Radio
            name="group1"
            value="option1"
            checked={selectedValue === "option1"}
            onChange={handleRadioChange}
            label="Option 1"
          />
          <Radio
            name="group1"
            value="option2"
            checked={selectedValue === "option2"}
            onChange={handleRadioChange}
            label="Option 2"
          />
        </div>
        <Select
          options={options}
          onSelect={handleSelectChange}
          selected={selectedOption}
          placeholder="Select an option"
          onChange={false}
          searchTerm={searchTerm}
        />
        <Input
          name="emailAddress"
          onChange={(e) => e.target.value}
          placeholder="energyfix@email.com"
          iconRight={<img src="assets/icons/mail.svg" />}
          containerStyle={{ borderRadius: "5px", marginTop: "20px" }}
        />
      </div>
      <div
        style={{
          maxWidth: "400px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography size="md">Typography</Typography>
        <Typography variant="h1" size={["xs", "md", "_5xl", "_8xl"]}>
          H1 Typography
        </Typography>
        <Button variant="primary" size="sm">
          Small Button
        </Button>
        <Button variant="primary" size="md" fullWidth isDisabled>
          Medium Button
        </Button>
        <Button variant="link" isDisabled size="md">
          Link
        </Button>
        <Button variant="linkWithArrow" size="md">
          Link
        </Button>
        <Button variant="icon" isDisabled size="sm">
          S
        </Button>
        <Button variant="icon" size="md">
          M
        </Button>
      </div>
      {isLoading && <p>Loading...</p>}
      <p>{counter}</p>
      <button onClick={() => dispatch(increment())}>Increment</button>
      <div style={{ height: "50px", maxWidth: "600px", margin: "50px auto" }}>
        <button
          onClick={() => dispatch(openModal())}
          style={{
            width: "150px",
            padding: "10px",
            background: "orange",
            color: "black",
            outline: "none",
            border: "none",
          }}
        >
          Open modal
        </button>
      </div>
      {/* {isOpen && <Modal />} */}
      {/* <div style={{ maxWidth: "400px", margin: "0 auto" }}>
        <Formik
          initialValues={{
            addressOne: "",
            addressTwo: "",
            cityId: "",
            eirCode: "",
            regionId: "",
            homeAge: "",
            ber: "",
            homeType: "",
            mprn: "",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
          }}
          enableReinitialize
          onSubmit={handleFormSubmit}
        >
          {({ values, handleSubmit, setFieldValue, touched, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div style={{ marginBottom: "10px" }}>
                  <h2
                    style={{
                      fontWeight: 600,
                      fontSize: 24,
                      marginBottom: "20px",
                    }}
                  >
                    Property Details
                  </h2>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Address Line 1*
                    </h2>
                    <Input
                      name="addressOne"
                      value={values.addressOne}
                      placeholder="Address Line 1"
                      style={{ backgroundColor: "#F5F5F5" }}
                      onChange={(e) =>
                        setFieldValue("addressOne", e.target.value)
                      }
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Address Line 2*
                    </h2>
                    <Input
                      name="addressTwo"
                      placeholder="Address Line 2"
                      value={values.addressTwo}
                      onChange={(e) =>
                        setFieldValue("addressTwo", e.target.value)
                      }
                      style={{ backgroundColor: "#F5F5F5" }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Town/City*
                    </h2>

                    <Select
                      selected={selectOption.client}
                      options={option.client}
                      onSelect={(data) => handleSelect("client", data)}
                      onChange={(e) => filterOption(e, "client")}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Eircode*
                    </h2>
                    <Input
                      name="eirCode"
                      value={values.eirCode}
                      onChange={(e) => setFieldValue("eirCode", e.target.value)}
                      placeholder="Eircode"
                      style={{ backgroundColor: "#F5F5F5" }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      regionId<sub>*</sub>
                    </h2>
                    <Input
                      name="regionId"
                      value={values.regionId}
                      onChange={(e) => setFieldValue("regionId", e.target.value)}
                      placeholder="regionId"
                      style={{ backgroundColor: "#F5F5F5" }}
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Age of Property*
                    </h2>
                    <Select
                      name="ageOfProperty"
                      options={propertyAge}
                      value={values.ageOfProperty}
                      onChange={(e) =>
                        setFieldValue("ageOfProperty", e.target.value)
                      }
                      placeholder="select"
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      BER<sub>*</sub>
                    </h2>
                    <Select
                      name="ber"
                      options={ber}
                      value={values.ber}
                      onChange={(e) => setFieldValue("ber", e.target.value)}
                      placeholder="select"
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Type of Property*
                    </h2>
                    <Select
                      options={options}
                      onChange={() => {}}
                      placeholder="select"
                    />
                  </InfoRowStyled>
                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      MPRN
                    </h2>
                    <Input
                      name="mprn"
                      placeholder="MPRN of Home"
                      value={values.mprn}
                      onChange={(e) => setFieldValue("mprn", e.target.value)}
                      style={{ backgroundColor: "#F5F5F5" }}
                    />
                  </InfoRowStyled>

                  {touched.firstName && errors.firstName && (
                    <div style={{ color: "red", marginBottom: "5px" }}>
                      {errors.firstName}
                    </div>
                  )}

                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      First Name*
                    </h2>

                    <Input
                      name="firstName"
                      value={values.firstName}
                      placeholder="First Name"
                      onChange={(e) =>
                        setFieldValue("firstName", e.target.value)
                      }
                      style={{
                        backgroundColor: "#F5F5F5",
                        maxWidth: "220px",
                      }}
                    />
                  </InfoRowStyled>

                  {touched.lastName && errors.lastName && (
                    <div style={{ color: "red", marginBottom: "5px" }}>
                      {errors.lastName}
                    </div>
                  )}

                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Surname*
                    </h2>

                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      value={values.lastName}
                      onChange={(e) =>
                        setFieldValue("lastName", e.target.value)
                      }
                      style={{
                        backgroundColor: "#F5F5F5",
                        maxWidth: "220px",
                      }}
                    />
                  </InfoRowStyled>

                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Phone Number*
                    </h2>

                    <Input
                      name="mobileNumber"
                      placeholder="Phone Number"
                      value={values.mobileNumber}
                      onChange={(e) =>
                        setFieldValue("mobileNumber", e.target.value)
                      }
                      style={{
                        backgroundColor: "#F5F5F5",
                        maxWidth: "220px",
                      }}
                    />
                  </InfoRowStyled>
                  {touched.email && errors.email && (
                    <div style={{ color: "red", marginBottom: "5px" }}>
                      {errors.email}
                    </div>
                  )}

                  <InfoRowStyled>
                    <h2 style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Email*
                    </h2>

                    <Input
                      name="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      style={{
                        backgroundColor: "#F5F5F5",
                        maxWidth: "220px",
                      }}
                    />
                  </InfoRowStyled>

                  <button
                    style={{
                      margin: "0px 30px",
                      width: "300px",
                      height: "50px",
                      backgroundColor: "green",
                      color: "white",
                      border: "none",
                      outline: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>

      </div> */}
      <EnergyRetrofitCard />
      <RenewableCard />
      <InsulationCard />
      <VentilationCard />
      <AirtightnessCard />
      <DoorCard />
      <SolarCard />
      <UserDashboard />
    </>
  );
};

export default Test;
