import React from "react";
import IndividualCardSection from "./individualCardSection";
import solarPNG from "../../../assets/images/homeCardIndividualPage-img/solar-energy-systems.png";
import { ColorText, Typography } from "@shared/designComponents";
import { useTheme } from "styled-components";
import { EnergyfixCTASection } from "../../Shared";

const RenewableCard = () => {
  const theme = useTheme();
  return (
    <div>
      <IndividualCardSection
        imgUrl={solarPNG}
        sn="06"
        title="Solar Energy Systems"
        description={
          <>
            <Typography size={"sm"}>
              Despite Ireland&apos;s climate, solar energy remains a viable
              option, capable of fulfilling
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}> 50-60% </ColorText>
              </Typography>
              of your electricity or hot water requirements while cutting down
              on energy expenses. Consider installing a solar thermal system for
              hot water or solar panels for electricity generation. The SEAI
              offers grants of
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}> €1,200 </ColorText>
              </Typography>
              for solar water heating and up to
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}> €2,400 </ColorText>
              </Typography>
              for solar panels to support these eco-friendly initiatives.
            </Typography>
          </>
        }
      />
      <EnergyfixCTASection />
    </div>
  );
};

export default RenewableCard;
