const sizes = {
  xs: "0.75rem", //12px
  sm: "0.875rem", //14px
  md: "1rem", //16px
  lg: "1.125rem", //18px
  xl: "1.25rem", //20px
  _2xl: "1.5rem", //24px
  _3xl: "1.875rem", //30px
  _4xl: "2rem", //32px
  _5xl: "2.5rem", // 40px
  _6xl: "3rem", //48px
  _7xl: "3.75rem", // 60px
  _8xl: "4.5rem", //72px
  _9xl: "6rem", //96px
  _10xl: "8rem", //128px
};

// {
//    xs: '0.75rem',
//   sm: '0.875rem',
//   md: '1rem',
//   lg: '1.125rem',
//   xl: '1.25rem',
//   _2xl: '1.5rem',
//   _3xl: '1.875rem',
//   _4xl: '2.25rem',
//   _5xl: '3rem',
//   _6xl: '3.75rem',
//   _7xl: '4.5rem',
//   _8xl: '6rem',
//   _9xl: '8rem',
// }

export default sizes;

export type SizesType = typeof sizes;
