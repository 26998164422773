/* eslint-disable */

import axios from "axios";

import { getAPI } from "./apiEndpointHelpers";
import { toast } from "react-toastify";

export type ParamsType = {
  page?: number;
  limit?: number;
  search?: string;
  sort?: string;
  filter?: string;
  query?: string;
  [key: string]: any;
};
export type MethodType =
  | "get"
  | "post"
  | "delete"
  | "patch"
  | "put"
  | "head"
  | "options";

export type ConfigType = {
  url: string;
  method: MethodType;
  data?: any;
  params?: {
    page?: number;
    limit?: number;
    [key: string]: any;
  };
};
let isNetworkError = false;
const requestAPI = async (config: ConfigType, isDebug = false) => {
  const API_URL = getAPI();
  try {
    const res = await axios({
      ...config,
      url: API_URL + config.url,
    });

    if (res.status >= 200 && res.status < 300) {
      const data = res?.data?.data || res?.data || res;

      if (res.data.status === "error") {
        throw res.data;
      } else {
        return data;
      }
    }
    isNetworkError = false;
  } catch (err: any) {
    if (err.code === "ERR_NETWORK" && !isNetworkError) {
      isNetworkError = true;
      toast.error(err.message, {
        theme: "colored",
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    try {
      if (isDebug) {
        console.error(err.response.data);
      }
    } catch (error) {
      console.error(error);
    }
    if (err && err.response && err.response.data && err.response.data.message) {
      throw err.response.data.message;
    }
    if (err && err.response && err.response.data && err.response.data.error) {
      throw err.response.data.error;
    }
    throw err.message || err;
  } finally {
  }
};
export default requestAPI;
