import styled from "styled-components";
import { devices } from "@shared/theme/device";

export const TableWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.gray.lighter};

  @media (${devices.tablet}) {
    background-color: ${({ theme }) => theme.colors.transparent};
    margin-bottom: 100px;
  }
`;
