import React, { CSSProperties, ReactNode } from "react";
import { IButtonProps, StyledButton } from "./style";
import Loader from "../Loader";
import { ReactComponent as IconRight } from "../../assets/icons/ArrowRight.svg";

interface IButtonComponentProps {
  as?: "button" | "a";
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  fullWidth?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  htmlType?: "submit" | "reset" | "button";
}

const Button = ({
  as = "button",
  variant = "primary",
  reverseIcon = false,
  size = "md",
  href,
  isLoading = false,
  fullWidth = false,
  isDisabled = false,
  onClick,
  className,
  style,
  children,
  htmlType,
  ...rest
}: IButtonComponentProps & IButtonProps) => {
  return (
    <StyledButton
      as={as}
      variant={variant}
      reverseIcon={reverseIcon}
      size={size}
      isDisabled={isDisabled}
      disabled={isDisabled}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      className={className}
      style={{ ...style }}
      type={htmlType}
      {...rest}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {variant === "icon" ? (
            <IconRight />
          ) : (
            <>
              {children && children}
              {variant === "linkWithArrow" && <IconRight />}
            </>
          )}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
