import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const EnergyRetrofitIndividualCardPageStyled = styled.div`
  .heading {
    & .section-title {
      font-weight: 600;
    }

    & .section-subtitle {
      margin: 15px 0 40px;

      @media (min-width: ${screenSizes.md}) {
        margin-bottom: 50px;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (min-width: ${screenSizes.lg}) {
      display: flex;
      flex-direction: row-reverse;
      gap: 30px;
    }

    & .img {
      object-fit: cover;

      @media (min-width: ${screenSizes.md}) {
        border-radius: 5px;
      }
      @media (min-width: ${screenSizes.lg}) {
        border-radius: 10px;
        max-width: 368px;
      }
    }
    & .title-and-title-description {
      & .title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      & .title-description {
        @media (min-width: ${screenSizes.md}) {
          margin-left: 52px;
        }

        @media (min-width: ${screenSizes.lg}) {
          margin-left: 62px;
        }
      }
    }
  }
`;
