import styled from "styled-components";
import { ColorText, Typography } from "@shared/designComponents";

export const ParagraphStyled = styled(Typography)`
  color: ${({ theme }) => theme.colors.footerBlack};
  font-size: ${({ theme }) => theme.sizes.sm};
  line-height: 24px;

  a {
    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FontWeightSemiBold = styled(ColorText)`
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const ContentWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const OrderedListStyled = styled.ol`
  counter-reset: my-counter;
  list-style: none;
  padding-left: 0;
`;

export const ListItemStyled = styled.li`
  padding-left: 30px;
  position: relative;
  margin-top: 12px;

  &:before {
    content: counter(my-counter, decimal-leading-zero) ".";
    counter-increment: my-counter;
    position: absolute;
    left: 0;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.sizes.md};
    line-height: 24px;
  }
`;
