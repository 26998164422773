import { useEffect, useState } from "react";
import Input from "@shared/designComponents/Input";
import theme from "@shared/theme";
import { ButtonStyled, FormStyled } from "./style";
import { toast } from "react-toastify";
import { requestAPI } from "@shared/utils";
import subscriptionConfig from "@shared/apiConfigs/subscription";

const SubscriptionForm = () => {
  const [email, setEmail] = useState("");
  const handleSubscription = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await requestAPI(subscriptionConfig.subscriptionLink(email));
      toast.success("Successfully subscribed to our newsletter");
      setEmail("");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };

  return (
    <FormStyled
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubscription(e)}
    >
      <Input
        containerStyle={{
          borderRadius: "5px 0 0 5px",
          borderRight: "none",
          padding: "4px 15px",
          width: "100%",
        }}
        placeholder="Enter your email address"
        type="text"
        style={{ color: theme.colors.disableText }}
        value={email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)
        }
      />
      <ButtonStyled htmlType="submit" size="sm">
        Sign Up
      </ButtonStyled>
    </FormStyled>
  );
};

export default SubscriptionForm;
