import { useTheme } from "styled-components";
import { ColorText, SectionTitle } from "@shared/designComponents";
import Container from "@shared/designComponents/Container";
import {
  ContentWrapperStyled,
  FontWeightSemiBold,
  ListItemStyled,
  OrderedListStyled,
  ParagraphStyled,
} from "./style";
import BreadCrumbs from "@shared/designComponents/BreadCrumbs";
import {
  COST_GRANT_PATH,
  VACANT_HOMES_GRANT_PATH,
} from "../../../../routes/routePaths";

const breadcrumbs = [
  {
    label: "Cost & Grants",
    link: COST_GRANT_PATH,
  },
  {
    label: "Vacant Home Grants",
    link: `${COST_GRANT_PATH}/${VACANT_HOMES_GRANT_PATH}`,
  },
];

const RefurbishmentGrant = () => {
  const theme = useTheme();
  return (
    <Container marginVertical={50}>
      <BreadCrumbs
        breadcrumbs={breadcrumbs}
        style={{ margin: "0px 0px 30px" }}
      />
      <SectionTitle style={{ marginBottom: "16px" }}>
        Vacant Property{" "}
        <ColorText color={theme.colors.primary}>Refurbishment Grant</ColorText>
      </SectionTitle>
      <ContentWrapperStyled>
        <ParagraphStyled>
          Did you know you can combine the One Stop Shop Grant Scheme with The{" "}
          <FontWeightSemiBold color={theme.colors.secondary}>
            Vacant Property Refurbishment
          </FontWeightSemiBold>{" "}
          Scheme?
        </ParagraphStyled>
        <ParagraphStyled>
          This scheme provides an additional funding amount of up to{" "}
          <FontWeightSemiBold color={theme.colors.secondary}>
            €50,000
          </FontWeightSemiBold>{" "}
          to renovate a vacant property.
          <br />
          <br />
          The requirements include:
          <br />
          <OrderedListStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                Homes built before 1993.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                Vacant for 2 years or more.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                A building that you own or in the process of buying it.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                You must reside in the home as your principle residence once the
                work is complete.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                You must have tax clearance from Revenue and your tax affairs
                must be in order.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                Local Property Tax must be paid.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                You have not received grant funding before.
              </ParagraphStyled>
            </ListItemStyled>
          </OrderedListStyled>
        </ParagraphStyled>
        <ParagraphStyled>
          From 1st May 2023, the following requirements come into force:
          <br />
          <OrderedListStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                Vacant properties built before 2007.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                People who want to rent our their property once they are
                refurbished.
              </ParagraphStyled>
            </ListItemStyled>
          </OrderedListStyled>
        </ParagraphStyled>
        <ParagraphStyled>
          If the home is considered derelict, you can receive an additional{" "}
          <FontWeightSemiBold color={theme.colors.secondary}>
            €20,000
          </FontWeightSemiBold>{" "}
          top up grant. To be considered derelict the home:
          <br />
          <OrderedListStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                Must be on the Derelict Sites Register, or
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                Must be confirmed by the applicant to be derelict (i.e.
                structurally unsound and dangerous).
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                If the home is not already on the Register, an independent
                report prepared by a qualified professional is required along
                with.
              </ParagraphStyled>
            </ListItemStyled>
            <ListItemStyled>
              <ParagraphStyled as="span">
                the application confirming the property is derelict.
              </ParagraphStyled>
            </ListItemStyled>
          </OrderedListStyled>
        </ParagraphStyled>
        <ParagraphStyled>
          To learn more about these two additional grants, please click{" "}
          <a href="">here</a>.
          <br />
          <br />
          EnergyFix does not administer the Vacant Property Refurbishment Scheme
          and you must apply separately for this scheme.
          <br />
          <br />
          However, once approved the grants can be combined to reduce your
          overall out of pocket costs.
        </ParagraphStyled>
      </ContentWrapperStyled>
    </Container>
  );
};

export default RefurbishmentGrant;
