import styled from "styled-components";
import { devices } from "@shared/theme/device";

export const Main = styled.div`
  display: flex;
  max-width: 1050px;
  flex-direction: column;
  margin: 0 auto;
  gap: 70px;
  align-items: center;

  @media (${devices.tablet}) {
    gap: 50px;
  }
`;

export const CardWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  align-items: center;
  justify-content: center;
`;
