import VideoIframe from "./Video";
import { Section, SectionContainer, WrapperStyled } from "./style";
import Typography from "@shared/designComponents/Typography";
import Button from "@shared/designComponents/Button";
import { Container } from "@shared/designComponents";
import colors from "@shared/theme/colors";
import { Link } from "react-router-dom";
import { REGISTER_INTEREST_PATH } from "../../../routes/routePaths";

const VideoSection = () => {
  const youtubeVideoId = "fCbQkamrii0";
  return (
    <Container marginVertical={50}>
      <WrapperStyled>
        <SectionContainer>
          <Section>
            <Typography className="section-number" fontWeight={"bold"}>
              1.
            </Typography>
            <Typography
              size={["sm", "sm", "md"]}
              color={colors.normalText}
              fontWeight={"medium"}
            >
              Improved insulation, better sealing, and efficient heating and
              cooling systems create a more comfortable indoor environment
              year-round.
            </Typography>
          </Section>
          <Section>
            <Typography className="section-number" fontWeight={"bold"}>
              2.
            </Typography>
            <Typography
              size={["sm", "sm", "md"]}
              color={colors.normalText}
              fontWeight={"medium"}
            >
              By reducing energy consumption, homeowners can expect to see
              significant savings on their utility bills over time.
            </Typography>
          </Section>
          <Section>
            <Typography className="section-number" fontWeight={"bold"}>
              3.
            </Typography>

            <Typography
              size={["sm", "sm", "md"]}
              color={colors.normalText}
              fontWeight={"medium"}
            >
              {" "}
              Lower energy usage means reduced greenhouse gas emissions, helping
              to mitigate climate change and protect the environment.
            </Typography>
          </Section>
          <Link to={REGISTER_INTEREST_PATH}>
            <Button
              variant="linkWithArrow"
              style={{ marginLeft: "30px", fontSize: "14px", fontWeight: 400 }}
            >
              Enquire Now
            </Button>
          </Link>
        </SectionContainer>
        <VideoIframe videoId={youtubeVideoId} />
      </WrapperStyled>
    </Container>
  );
};

export default VideoSection;
