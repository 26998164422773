import styled from "styled-components";
import { Typography } from "@shared/designComponents";
import { devices } from "@shared/theme/device";
import { flexStart } from "@shared/style/reusableStyle";
import { screenSizes } from "@shared/theme/screen";

export const TitleContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;

  @media (${devices.tablet}) {
    margin-bottom: 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  svg {
    width: 160px;

    @media (${devices.laptopXL}) {
      width: fit-content;
    }
  }
`;

export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${devices.laptop}) {
    flex-direction: row;
    gap: 40px;
  }
`;

export const ParagraphStyled = styled(Typography)`
  line-height: 21px;
`;

export const SubmitInterestPageStyled = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  @media (min-width: ${screenSizes.md}) {
    gap: 50px;
    flex-direction: row;
  }

  & .left-container {
    flex: 1;

    &__heading {
      margin-bottom: 40px;

      & span:first-child {
        display: inline-block;
        margin-bottom: 12px;

        @media (min-width: ${screenSizes.md}) {
          margin-bottom: 12px;
        }
      }
    }

    &__select-heading {
      display: none;

      @media (min-width: ${screenSizes.md}) {
        display: flex;
      }
    }
  }
  & .right-container {
    flex: 1;
    width: 100%;

    & .form-label {
      margin-bottom: 12px;
    }
  }
`;
