import styled, { css } from "styled-components";
import { Button } from "@shared/designComponents";
import { devices } from "@shared/theme/device";

export const SectionStyled = styled.section`
  background-color: ${({ theme }) => theme.colors.grayFA};
  padding: 50px 0;
`;

export const CardsContainer = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 30px;

  @media (${devices.tablet}) {
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }

  @media (${devices.laptopXL}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const CardWrapper = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
`;

// Styles for ./CardFooter.ts

export const StyledButton = styled(Button)<{ $secondaryButton?: boolean }>`
  border-radius: 29px;
  padding: 7px 10px;
  height: 27px;
  font-size: 12px;
  line-height: 18px;

  ${({ $secondaryButton }) =>
    $secondaryButton &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary};

      &:hover {
        background-color: ${({ theme }) => theme.colors.secondaryHover};
      }
    `}

  svg {
    path {
      fill: #fff;
    }
  }
`;
