import { createElement } from "react";
import styled from "styled-components";
import { ContainerProps, FluidType } from ".";
import { screenSizes } from "../../theme/screen";

export const ContainerStyled = styled.div<{ $fluid : FluidType, $marginVertical: number; $enableMargin: boolean; $bgColor: string; }>`
    width: 100%;
    margin: 0 auto;
    padding: 0 37px;
    background-color: ${({$bgColor}) => $bgColor};
    margin: ${({$enableMargin, $marginVertical}) => $enableMargin ? `${$marginVertical}px auto` : '0 auto'};

    @media (min-width: ${screenSizes.sm}) {
        padding: 0;
        max-width: ${({$fluid}) => (typeof $fluid === "boolean" && $fluid) || (typeof $fluid === "string" && $fluid === "sm")  ? "100%" : "33.75rem"};
    }

    @media (min-width: ${screenSizes.md}) {
        max-width: ${({$fluid}) => (typeof $fluid === "boolean" && $fluid) || (typeof $fluid === "string" && $fluid === "md")  ? "100%" : "45rem"};
    }

    @media (min-width: ${screenSizes.lg}) {
        max-width: ${({$fluid}) => (typeof $fluid === "boolean" && $fluid) || (typeof $fluid === "string" && $fluid === "lg")  ? "100%" : "60rem"};
    }

    @media (min-width: ${screenSizes.xl}) {
        max-width: ${({$fluid}) => (typeof $fluid === "boolean" && $fluid) || (typeof $fluid === "string" && $fluid === "xl")  ? "100%" : "71.25rem"};
    }
    
    @media (min-width: ${screenSizes.xxl}) {
        max-width: ${({$fluid}) => (typeof $fluid === "boolean" && $fluid) || (typeof $fluid === "string" && $fluid === "xxl")  ? "100%" : "82.5rem"};
    }

`