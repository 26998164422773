import { useTheme } from "styled-components";
import {
  Button,
  Container,
  Flex,
  Image,
  Typography,
} from "@shared/designComponents";
import {
  BannerTitleStyled,
  BannerWrapperStyled,
  DescriptionStyled,
  SectionNumberStyled,
  ServiceSectionWrapperStyled,
} from "./style";
import { Link } from "react-router-dom";
import { REGISTER_INTEREST_PATH } from "../../../../routes/routePaths";

type ServiceBannerProps = {
  idx: number;
  isEvenSection: boolean;
  title: string;
  subtitle?: string;
  description: string;
  image: string;
};

const ServiceBanner = ({
  idx,
  isEvenSection,
  title,
  subtitle,
  description,
  image,
}: ServiceBannerProps) => {
  const theme = useTheme();
  return (
    <BannerWrapperStyled>
      <Container enableMargin={false}>
        <ServiceSectionWrapperStyled $isEvenSection={isEvenSection}>
          <div className="service-image-wrapper">
            <Image src={image} objectFit="contain" />
          </div>
          <div>
            <Flex margin="0px 0px 20px 0px">
              <SectionNumberStyled
                color={theme.colors.primary}
                size={["_2xl", "_2xl", "_4xl"]}
                fontWeight="bold"
              >
                0{idx + 1}.
              </SectionNumberStyled>
              <div>
                <BannerTitleStyled
                  color={theme.colors.secondary}
                  size={["md", "md", "_4xl"]}
                  fontWeight="semibold"
                >
                  {title}
                </BannerTitleStyled>
                {subtitle && (
                  <Typography
                    color={theme.colors.footerBlack}
                    size={["sm", "sm", "xl"]}
                    fontWeight="medium"
                  >
                    {subtitle}
                  </Typography>
                )}
              </div>
            </Flex>

            <DescriptionStyled color={theme.colors.footerBlack} size="sm">
              {description}
            </DescriptionStyled>
            <Link to={REGISTER_INTEREST_PATH}>
              <Button variant="linkWithArrow" className="button">
                Enquire now
              </Button>
            </Link>
          </div>
        </ServiceSectionWrapperStyled>
      </Container>
    </BannerWrapperStyled>
  );
};

export default ServiceBanner;
