import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const EnergyRetrofitCardStyled = styled.div`
  .heading {
    text-align: center;
    & .section-title {
      font-weight: 600;
    }

    & .section-subtitle {
      text-align: center;
      margin-bottom: 25px;
      margin-top: 5px;

      @media (min-width: ${screenSizes.md}) {
        margin-bottom: 50px;
        margin-top: 20px;
      }
    }
  }

  .card {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 40px;

    @media (min-width: ${screenSizes.md}) {
      grid-template-columns: 1fr 1fr;
      gap: 50px 20px;
    }

    @media (min-width: ${screenSizes.lg}) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 80px 40px;
    }
  }
`;
