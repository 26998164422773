import React, { ChangeEvent, useState, useEffect, useRef } from "react";

import Input from "../Input";

import { OptionsContainerStyled, SelectWrapper } from "./style";
import Typography from "../Typography";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";
import styled from "styled-components";
import { selectDisable } from "../../style/reusableStyle";
import { useTheme } from "styled-components";

export type OptionType = { label: string; value: string };

interface SelectProps {
  options: OptionType[];
  onSelect: (selectedOption: string) => void;
  selected: string;
  placeholder?: string;
  onChange?: boolean;
  searchTerm?: string;
}

const Select = ({
  options,
  onSelect,
  placeholder = "Select",
  selected,
  onChange,
}: SelectProps) => {
  const [filteredOptions, setFilteredOptions] = useState();
  const selectWrapperRef = useRef();
  const theme = useTheme();
  const [isActive, setIsActive] = useDetectOutsideClick(
    selectWrapperRef,
    false
  );

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);
  const [search, setSearch] = useState("");

  const handleSearch = (e: any) => {
    e.preventDefault();
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (searchTerm === "") {
      setFilteredOptions(options);
    } else {
      const newFilteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(newFilteredOptions);
    }
  };
  const handleOptionClick = (option: OptionType) => {
    onSelect(option.value);
    setIsActive(false);
  };

  const getSelectedValueLabel = () => {
    const selectedValue = options?.find((ob) => ob?.value === selected);
    if (selectedValue) {
      return selectedValue.label;
    }
    return null;
  };
  return (
    <StyledDiv style={{ position: "relative" }} ref={selectWrapperRef}>
      <SelectWrapper onClick={() => setIsActive((prev) => !prev)}>
        <Typography size="sm">
          {getSelectedValueLabel() || (
            <p style={{ color: theme.colors.disableText }}>{placeholder}</p>
          )}
        </Typography>
        {<img src="/assets/icons/Down.svg" />}
      </SelectWrapper>

      {isActive && (
        <OptionsContainerStyled>
          {onChange && (
            <Input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={handleSearch}
              iconLeft={
                <img src="/assets/icons/Search.svg" alt="serach icon" />
              }
              containerStyle={{ borderRadius: "27px" }}
            />
          )}

          <ul>
            {filteredOptions?.map((option, index) => (
              <li
                className="option"
                key={index}
                onClick={() => handleOptionClick(option)}
              >
                {option?.label}
              </li>
            ))}
          </ul>
        </OptionsContainerStyled>
      )}
    </StyledDiv>
  );
};

export default Select;

const StyledDiv = styled.div`
  ${selectDisable}
`;
