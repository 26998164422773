const space = {
  px: "1px",
  "0.5": "0.125rem",
  _1: "0.25rem",
  "1.5": "0.375rem",
  _2: "0.5rem",
  "2.5": "0.625rem",
  _3: "0.75rem",
  "3.5": "0.875rem",
  _4: "1rem",
  _5: "1.25rem",
  _6: "1.5rem",
  _7: "1.75rem",
  _8: "2rem",
  _9: "2.25rem",
  _10: "2.5rem",
  _12: "3rem",
  _14: "3.5rem",
  _16: "4rem",
  _20: "5rem",
  _24: "6rem",
  _28: "7rem",
  _32: "8rem",
  _36: "9rem",
  _40: "10rem",
  _44: "11rem",
  _48: "12rem",
  _52: "13rem",
  _56: "14rem",
  _60: "15rem",
  _64: "16rem",
  _72: "18rem",
  _80: "20rem",
  _96: "24rem",
  maxWidth: "90rem", //1440px
  headerOffset: "3.375rem,", //54px
  sidebarOffset: "18.75rem,", //300px
};

export default space;

export type SpacesType = typeof space;
