import { Typography } from "@shared/designComponents";
import theme from "@shared/theme";

export const contractTable = {
  header: [
    { name: "Title", label: "title" },
    { name: "Document", label: "document" },
    { name: "Updated Date", label: "updated_date" },
    // { name: "Action", label: "action" },
  ],
  body: [
    [
      {
        label: "title",
        value: (
          <div>
            <Typography color={theme.colors.footerBlack} size={"sm"}>
              Sorry, no data available at the moment. Please check back later
              for updates.{" "}
            </Typography>
          </div>
        ),
      },
      {
        label: "document",
        value: (
          <div>
            <Typography color={theme.colors.footerBlack} size={"sm"}>
              Sorry, no data available at the moment. Please check back later
              for updates.{" "}
            </Typography>
          </div>
        ),
      },
      {
        label: "updated_date",
        value: (
          <div>
            <Typography color={theme.colors.footerBlack} size={"sm"}>
              Sorry, no data available at the moment. Please check back later
              for updates.{" "}
            </Typography>
          </div>
        ),
      },
      {
        label: "title",
        value: (
          <div>
            <Typography color={theme.colors.footerBlack} size={"sm"}>
              Sorry, no data available at the moment. Please check back later
              for updates.{" "}
            </Typography>
          </div>
        ),
      },
    ],
  ],
};
