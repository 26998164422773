import ProfileCard from "@shared/designComponents/ProfileCard";
import { profileData } from "./cardData";
import { CardWrapperStyled, Main } from "./style";
import SectionTitle from "@shared/designComponents/SectionTitle";
import ColorText from "@shared/designComponents/SectionTitle/ColorText";
import theme from "@shared/theme";
import { Container } from "@shared/designComponents";

const CardSection = () => {
  return (
    <Container>
      <Main>
        <SectionTitle>
          Meet Our <ColorText color={theme.colors.primary}>EnergyFix</ColorText>{" "}
          <ColorText color={theme.colors.secondary}>Experts</ColorText>
        </SectionTitle>

        <CardWrapperStyled>
          {profileData.map((data, idx) => (
            <ProfileCard
              key={idx}
              name={data.name}
              post={data.post}
              imgUrl={data.imgUrl}
              // description={data.description}
            />
          ))}
        </CardWrapperStyled>
      </Main>
    </Container>
  );
};

export default CardSection;
