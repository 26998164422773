import { useTheme } from "styled-components";
import { ColorText, Container, SectionTitle } from "@shared/designComponents";
import BreadCrumbs from "@shared/designComponents/BreadCrumbs";
import { COST_GRANT_PATH } from "../../../../routes/routePaths";
import { SubtitleStyled } from "./style";

const breadcrumbs = [
  {
    label: "Cost & Grants",
    link: COST_GRANT_PATH,
  },
  {
    label: "SEAI Grants",
    link: `${COST_GRANT_PATH}`,
  },
];

const SEAIGrantsHeader = () => {
  const theme = useTheme();
  return (
    <Container marginVertical={50}>
      <BreadCrumbs
        breadcrumbs={breadcrumbs}
        style={{ margin: "0px 0px 30px" }}
      />
      <SectionTitle>
        One Stop Shop <ColorText color={theme.colors.primary}>Grants</ColorText>
      </SectionTitle>
      <SubtitleStyled size={["sm", "sm", "md"]}>
        Grants available for homeowners for a full energy retrofit through a one
        stop shop.
      </SubtitleStyled>
    </Container>
  );
};

export default SEAIGrantsHeader;
