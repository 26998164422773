import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useRoot } from "@shared/context/RootProvider";
import Header from "../Header";
import { BodyStyled, MainLayoutStyled, MainWrapperStyled } from "./styles";
import { ILayoutProps } from "./type";

export interface ILandingPageLayoutProps extends ILayoutProps {
  extraInfo?: any;
}

const PrivateLayout: React.FunctionComponent<ILandingPageLayoutProps> = ({
  children,
  extraInfo,
}) => {
  const { auth } = useRoot();

  if (!auth) {
    return <Navigate to="/" replace />;
  }

  return (
    <MainLayoutStyled>
      <Header />
      <BodyStyled>
        <div
          style={{
            display: "flex",
            flex: 1,
          }}
        >
          <MainWrapperStyled $noPadding={extraInfo?.noPadding || false}>
            {children || <Outlet />}
          </MainWrapperStyled>
        </div>
      </BodyStyled>
    </MainLayoutStyled>
  );
};

export default PrivateLayout;
