import { ConfigType, ParamsType } from "../utils/requestAPI";

type FaqParams = {
  _id: string;
  title?: string;
  content?: string;
  category?: string;
};

const faqsConfig = {
  createFaqs: (
    data: Pick<FaqParams, "category" | "title" | "content">
  ): ConfigType => ({
    method: "post",
    url: "/faqs",
    data,
  }),

  getAllFaqs: (params?: ParamsType & { category?: string }): ConfigType => ({
    method: "get",
    url: `/faqs`,
    params,
  }),

  updateFaqs: (
    id: string,
    data: Pick<FaqParams, "category" | "title" | "content">
  ): ConfigType => ({
    method: "put",
    url: `/faqs/${id}`,
    data,
  }),

  getFaqsById: (id: string): ConfigType => ({
    method: "get",
    url: `/faqs/${id}`,
  }),

  deleteFaqById: (id: string): ConfigType => ({
    method: "delete",
    url: `/faqs/${id}`,
  }),

  deleteSingleFaqById: (id: string): ConfigType => ({
    method: "delete",
    url: `/faqs/single/${id}`,
  }),

  submitQuery: (data: {
    email: string;
    subject: string;
    message: string;
  }): ConfigType => ({
    method: "post",
    url: `/faq-form`,
    data,
  }),
};

export default faqsConfig;
