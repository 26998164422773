import { ColorText, Image, Typography } from "@shared/designComponents";
import theme from "@shared/theme";
import downloadPNG from "../../../assets/images/userDashboard/Download.png";
import { AttachmentStyled, ButtonsStyled } from "./style";

export const grantTable = {
  header: [
    { name: "Grants", label: "grants" },
    { name: "Building Type", label: "building_types" },
    { name: "Relief Amount", label: "relief_amount" },
    { name: "Status", label: "status" },
    { name: "Attachments", label: "attachments" },
    { name: "Updated Date", label: "updated_date" },
  ],
  body: [
    [
      {
        label: "grants",
        value: (
          <div>
            <Typography
              color={theme.colors.footerBlack}
              size={"sm"}
              style={{ marginBottom: "12px" }}
            >
              Heat Pump Air to Air
            </Typography>
            <Typography color={theme.colors.gray8A} size={"sm"}>
              Created at 03/27/23 13:59
            </Typography>
          </div>
        ),
      },
      {
        label: "building_types",
        value: (
          <div>
            <Typography size={"sm"}>N/A</Typography>
          </div>
        ),
      },
      {
        label: "attachments",
        value: (
          <AttachmentStyled>
            <Typography size={"sm"}> Test_PDF.pdf</Typography>
            <Image src={downloadPNG} width={14} height={14} />
          </AttachmentStyled>
        ),
      },

      {
        label: "relief_amount",
        value: (
          <div>
            <Typography size={"sm"}>€ 1000.00</Typography>
          </div>
        ),
      },
      {
        label: "status",
        value: (
          <>
            <ButtonsStyled>
              <Typography
                size={"sm"}
                fontWeight="normal"
                color={theme.colors.white}
              >
                In Progress
              </Typography>
            </ButtonsStyled>
          </>
        ),
      },
    ],
    [
      {
        label: "grants",
        value: (
          <div>
            <Typography
              color={theme.colors.footerBlack}
              size={"sm"}
              style={{ marginBottom: "12px" }}
            >
              Heat Pump Systems
            </Typography>
            <Typography color={theme.colors.gray8A} size={"sm"}>
              Created at 03/27/23 13:59
            </Typography>
          </div>
        ),
      },
      {
        label: "building_types",
        value: (
          <div>
            <Typography size={"sm"}>All Houses</Typography>
          </div>
        ),
      },
      {
        label: "attachments",
        value: (
          <AttachmentStyled>
            <Typography size={"sm"}> Test_PDF.pdf</Typography>
            <Image src={downloadPNG} width={14} height={14} />
          </AttachmentStyled>
        ),
      },

      {
        label: "relief_amount",
        value: (
          <div>
            <Typography size={"sm"}>€ 1000.00</Typography>
          </div>
        ),
      },
      {
        label: "status",
        value: (
          <>
            <ButtonsStyled>
              <Typography
                size={"sm"}
                fontWeight="normal"
                color={theme.colors.white}
              >
                In Progress
              </Typography>
            </ButtonsStyled>
          </>
        ),
      },
    ],
    [
      {
        label: "grants",
        value: (
          <div>
            <Typography
              color={theme.colors.footerBlack}
              size={"sm"}
              style={{ marginBottom: "12px" }}
            >
              Heat Pump Air to Air
            </Typography>
            <Typography color={theme.colors.gray8A} size={"sm"}>
              Created at 03/27/23 13:59
            </Typography>
          </div>
        ),
      },
      {
        label: "building_types",
        value: (
          <div>
            <Typography size={"sm"}>N/A</Typography>
          </div>
        ),
      },
      {
        label: "attachments",
        value: (
          <AttachmentStyled>
            <Typography size={"sm"}> Test_PDF.pdf</Typography>
            <Image src={downloadPNG} width={14} height={14} />
          </AttachmentStyled>
        ),
      },

      {
        label: "relief_amount",
        value: (
          <div>
            <Typography size={"sm"}>€ 1000.00</Typography>
          </div>
        ),
      },
      {
        label: "status",
        value: (
          <>
            <ButtonsStyled>
              <Typography
                size={"sm"}
                fontWeight="normal"
                color={theme.colors.white}
              >
                In Progress
              </Typography>
            </ButtonsStyled>
          </>
        ),
      },
    ],
  ],
};
