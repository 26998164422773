import styled, { css } from "styled-components";
import Typography from "@shared/designComponents/Typography";
import theme from "@shared/theme";
import { Link } from "react-router-dom";

export const FooterStyled = styled.footer`
  background-color: ${({ theme }) => theme.colors.footerBlack};
  padding: 50px 0;
`;

export const WrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 30px;
  margin-inline: auto;

  @media (${theme.devices.tablet}) {
    grid-template-columns: 1.5fr 1fr 2fr;
    gap: 0px 50px;
  }

  @media (${theme.devices.laptop}) {
    grid-template-columns: 1fr 1fr 2fr;
    gap: 0px 150px;
  }
`;

export const LeftColStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${theme.devices.tablet}) {
    justify-self: start;
  }
`;

export const CenterColStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const RightColStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-self: center;

  @media (${theme.devices.tablet}) {
    justify-self: end;
  }
`;

export const FooterLinkStyled = styled(Link)`
  text-decoration: none;
  width: fit-content;

  &:hover {
    color: red;
  }
`;

export const EmailLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    text-decoration: underline;
  }
`;

export const SocialWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 12px;
`;

export const FormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FooterLinkTextStyled = styled(Typography)<{
  $noHover?: boolean;
  $isTitle?: boolean;
}>`
  color: ${({ theme }) => theme.colors.disable};
  pointer-events: ${({ $noHover }) => ($noHover ? "none" : "auto")};
  line-height: ${({ theme }) => theme.sizes.md};
  width: fit-content;
  ${({ $isTitle }) =>
    $isTitle &&
    css`
      margin-bottom: 4px;
    `}
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

FooterLinkTextStyled.defaultProps = {
  size: ["xs", "xs", "sm"],
  fontWeight: "normal",
};
