import styled from "styled-components";
import { screenSizes } from "@shared/theme/screen";

export const StepperStyled = styled.div`
  .step {
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
    @media (min-width: ${screenSizes.lg}) {
      padding-right: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.primary};
      top: 0%;
      bottom: 0px;
      left: 16px;
      z-index: -10;

      @media (min-width: ${screenSizes.lg}) {
        height: 2px;
        width: 100%;
        top: 16px;
        left: 60px;
      }
      @media (min-width: ${screenSizes.xl}) {
        left: 75px;
      }
    }

    &.inactive::after {
      background-color: ${({ theme }) => theme.colors.border};
    }

    &:last-child::after {
      background-color: transparent;
    }
    & .step-status {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      border: 1px solid ${({ theme }) => theme.colors.border};
      width: 34px;
      height: 34px;
      padding: 7px;
    }

    & .step-title {
      @media (min-width: ${screenSizes.xl}) {
        width: 150px;
      }
    }

    @media (min-width: ${screenSizes.lg}) {
      display: flex;
      flex-direction: column;
      text-align: center;
    }
  }

  @media (min-width: ${screenSizes.lg}) {
    display: flex;
    max-width: 1000px;
  }
`;
