import { useRoot } from "@shared/context/RootProvider";
import { Typography } from "@shared/designComponents";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import userDashboardConfig from "@shared/apiConfigs/userDashboard";
import { requestAPI } from "@shared/utils";
import { formatDocumentsTableData } from "./helperFunction";
import AdditionalTab from "./Additional";
import CertificateTab from "./Certificate";
import ContractTab from "./Contract";
import DocumentTab from "./DocumentTab";
import GrantsTab from "./Grants";
import ReportTab from "./Report";
import { DocumentTabStyled } from "./style";

export interface DataType {
  _id: string;
  leadId: string;
  name: string;
  documentType: string;
  documentURL: string;
  createdAt: string;
  updatedAt: string;
}
const documentTabItems = (data: DataType[], activeTab: number) => [
  {
    label: "Report",
    children: (
      <div>
        <ReportTab data={formatDocumentsTableData(data, activeTab)} />
      </div>
    ),
  },
  {
    label: "Grants",
    children: (
      <div>
        <GrantsTab data={formatDocumentsTableData(data, activeTab)} />
      </div>
    ),
  },
  {
    label: "Contract",
    children: (
      <div>
        <ContractTab data={formatDocumentsTableData(data, activeTab)} />{" "}
      </div>
    ),
  },
  {
    label: "Certificate",
    children: (
      <div>
        <CertificateTab data={formatDocumentsTableData(data, activeTab)} />
      </div>
    ),
  },
  {
    label: "Additional",
    children: (
      <div>
        <AdditionalTab data={formatDocumentsTableData(data, activeTab)} />
      </div>
    ),
  },
];

const Document = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState<DataType[]>([]);

  const { auth } = useRoot();
  const { _id: id } = auth;

  const handleUserDocuments = async () => {
    try {
      const { data } = await requestAPI(
        userDashboardConfig.getDocumentByUserId(id)
      );
      setData(data);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    handleUserDocuments();
  }, []);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };
  return (
    <DocumentTabStyled>
      <div className="tab">
        {data.length > 0 ? (
          <DocumentTab
            tabItems={documentTabItems(data, activeTab)}
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
        ) : (
          <Typography>No data to show</Typography>
        )}
      </div>
    </DocumentTabStyled>
  );
};

export default Document;
