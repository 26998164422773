import { configureStore } from "@reduxjs/toolkit";
import exampleReducer from "./slices/exampleSlice";
import leadReducer from "./slices/leadSlice";
import regionReducer from "./slices/regionSlice";
import cityReducer from "./slices/citySlice";

const store = configureStore({
  reducer: {
    example: exampleReducer,
    lead: leadReducer,
    region: regionReducer,
    city: cityReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
