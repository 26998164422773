import { ContentWrapper, ImageWrapper, SectionWrapper } from "./style";
import Typography from "@shared/designComponents/Typography";
import SectionTitle from "@shared/designComponents/SectionTitle";
import ColorText from "@shared/designComponents/SectionTitle/ColorText";
import theme from "@shared/theme";
import { Container, Hotspot, Image } from "@shared/designComponents";
import { hotspot } from "./hotspot";

const HotspotsSection = () => {
  return (
    <Container>
      <SectionWrapper>
        <ImageWrapper>
          <Image
            src="/assets/images/home.png"
            alt="home image"
            objectFit="contain"
          />
          {hotspot.map((hSpot, index) => (
            <Hotspot
              key={index}
              position={{
                left: hSpot.position.left,
                top: hSpot.position.top,
              }}
              modelContent={hSpot.content}
            />
          ))}
        </ImageWrapper>
        <ContentWrapper>
          <SectionTitle>
            What measures improve <br />
            my{" "}
            <ColorText color={theme.colors.primary}>
              Home’s Energy Rating?
            </ColorText>
          </SectionTitle>
          <Typography>
            Click on the hotspots to find out how to enjoy a warmer home,
            electric car and reduce your energy costs.
          </Typography>
        </ContentWrapper>
      </SectionWrapper>
    </Container>
  );
};

export default HotspotsSection;
