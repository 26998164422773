import { useRoot } from "@shared/context/RootProvider";
import {
  Button,
  ColorText,
  Container,
  Input,
  SectionTitle,
  Typography,
} from "@shared/designComponents";
import Checkbox from "@shared/designComponents/Checkbox";
import Select from "@shared/designComponents/Select";
import theme from "@shared/theme";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import leadConfig from "@shared/apiConfigs/leads";
import { requestAPI } from "@shared/utils";
import {
  CheckboxStyled,
  FormWrapper,
  HeaderStyled,
  Interested,
  ProfileWrapperStyled,
} from "./style";
import cityConfig from "@shared/apiConfigs/cities";

type cityType = {
  _id: string;
  value: string;
  label: string;
  name: string;
};
const ber = [
  { label: "A1", value: "A1" },
  { label: "A2", value: "A2" },
  { label: "A3", value: "A3" },
  { label: "B1", value: "B1" },
  { label: "B2", value: "B2" },
  { label: "B3", value: "B3" },
  { label: "C1", value: "C1" },
  { label: "C2", value: "C2" },
  { label: "C3", value: "C3" },
  { label: "D1", value: "D1" },
  { label: "D2", value: "D2" },
  { label: "E1", value: "E1" },
  { label: "E2", value: "E2" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
  { label: "I don't know my rating", value: "I don't know my rating" },
];
const typeOfProperty = [
  { label: "Bungalow", value: "Bungalow" },
  { label: "Detached", value: "Detached" },
  { label: "Semi Detached", value: "Semi Detached" },
  { label: "Mid Terrace", value: "Mid Terrace" },
  { label: "Apartment", value: "Apartment" },
  { label: "Multi Unit", value: "Multi Unit" },
  { label: "Commercial", value: "Commercial" },
  { label: "Others", value: "Others" },
];

const ageOfProperty = [
  { label: "Pre 1990", value: "Pre 1990" },
  { label: "1960 1980", value: "1960 1980" },
  { label: "1980 2000", value: "1980 2000" },
  { label: "2001 2011", value: "2001 2011" },
  { label: "2011 onwards", value: "2011 onwards" },
  { label: "Don't Know", value: "Don't know" },
  { label: "Don't know the year", value: "Don't know the year0" },
];

const interestData = [
  { label: "Refrub" },
  { label: "Extension" },
  { label: "Insulation" },
  { label: "Filler One" },
  { label: "Filler Two" },
  { label: "Filler Three" },
  { label: "Filler Four" },
  { label: "Filler Five" },
];

interface ValuesTypes {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  eirCode: string;
  cityId: string;
  ageOfProperty: string;
  typeOfProperty: string;
  mprn: string;
  ber: string;
}
const Profile = () => {
  const [selectedBER, setSelectedBER] = useState("");
  const [isNotify, setIsNotify] = useState(false);
  const [interest, setInterest] = useState([]);
  const [userData, setUserData] = useState(null);
  const [cities, setCities] = useState([{ value: "", label: "" }]);

  const dispatch = useDispatch();
  const { auth } = useRoot();
  const { _id: id } = auth;

  const handleInterestChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: { label: string }
  ) => {
    if (e.target.checked) {
      // If the checkbox is checked, add data.label to interestedWork
      setInterest((prevState) => [...prevState, data.label]);
    } else {
      // If the checkbox is unchecked, remove data.label from interestedWork
      setInterest((prevState) => [
        ...prevState.filter((item) => item !== data.label),
      ]);
    }
  };

  useEffect(() => {
    const userData = async () => {
      try {
        const data = await requestAPI(leadConfig.getLeadById(id));

        setUserData(data);
        setInterest(data.interestedWork);
        setIsNotify(data.emailNotification);
      } catch (error) {
        console.error(error);
      }
    };

    userData();
  }, []);
  const handleUpdate = async (values: ValuesTypes) => {
    try {
      await requestAPI(
        leadConfig.updateLead(id, {
          firstName: values.firstName,
          lastName: values.lastName,
          mobileNumber: values.phone,
          addressOne: values.addressLine1,
          addressTwo: values.addressLine2,
          ber: values.ber,
          cityId: values.cityId,
          ageOfProperty: values.ageOfProperty,
          typeOfProperty: values.typeOfProperty,
          eirCode: values.eirCode,
          mprn: values.mprn,
          interestedWork: interest,
          emailNotification: isNotify,
        })
      );
      toast.success("Updated successfully");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong, please try again");
    }
  };
  const fetchCityData = async () => {
    try {
      const response = await requestAPI(
        cityConfig.getAllCities({
          limit: 100,
        })
      );
      const data = response.data;
      const newData = data.map((item: cityType) => {
        return {
          value: item._id,
          label: item.name,
        };
      });
      setCities(newData);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCityData();
  }, []);

  return (
    <Container marginVertical={20}>
      <ProfileWrapperStyled>
        <HeaderStyled>
          {" "}
          <SectionTitle>
            My <ColorText color={theme.colors.primary}>Account</ColorText>
          </SectionTitle>
          <Typography>
            Update your profile details below. Make changes to any field and
            click
            <ColorText color={theme.colors.primary}> save </ColorText>to update
            your information
          </Typography>
        </HeaderStyled>
        <Formik
          initialValues={{
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            email: userData?.email,
            phone: userData?.mobileNumber,
            addressLine1: userData?.addressOne,
            addressLine2: userData?.addressTwo,
            eirCode: userData?.eirCode,
            cityId: userData?.cityId,
            ageOfProperty: userData?.ageOfProperty,
            typeOfProperty: userData?.typeOfProperty,
            mprn: userData?.mprn,
            ber: userData?.ber,
          }}
          enableReinitialize
          onSubmit={(values) => handleUpdate(values)}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <FormWrapper>
                  <div>
                    <Typography>First Name</Typography>
                    <Input
                      name="firstName"
                      value={values.firstName}
                      onChange={(e) =>
                        setFieldValue("firstName", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Typography>Last Name</Typography>
                    <Input
                      name="lastName"
                      value={values.lastName}
                      onChange={(e) =>
                        setFieldValue("lastName", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Typography>Email</Typography>
                    <Input
                      iconRight={<img src="/assets/icons/mail.svg" />}
                      name="email"
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                      disabled
                    />
                  </div>
                  <div>
                    <Typography>Phone</Typography>
                    <Input
                      name="phone"
                      value={values.phone}
                      onChange={(e) => setFieldValue("phone", e.target.value)}
                    />
                  </div>
                  <div>
                    <Typography>Address Line 1</Typography>
                    <Input
                      name="addressLine1"
                      value={values.addressLine1}
                      onChange={(e) =>
                        setFieldValue("addressLine1", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Typography>
                      Address Line 2{" "}
                      <ColorText color={theme.colors.error}>*</ColorText>
                    </Typography>

                    <Input
                      name="addressLine2"
                      value={values.addressLine2}
                      onChange={(e) =>
                        setFieldValue("addressLine2", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Typography>
                      City/County{" "}
                      <ColorText color={theme.colors.error}>*</ColorText>
                    </Typography>
                    <Select
                      options={cities}
                      onSelect={(selectedOption) => {
                        setFieldValue("cityId", selectedOption);
                      }}
                      selected={values.cityId}
                      placeholder="Select City"
                      onChange
                    />
                  </div>
                  <div>
                    <Typography>
                      Type Of Property{" "}
                      <ColorText color={theme.colors.error}>*</ColorText>
                    </Typography>
                    <Select
                      options={typeOfProperty}
                      onSelect={(selectedOption) => {
                        setFieldValue("typeOfProperty", selectedOption);
                      }}
                      selected={values.typeOfProperty}
                      onChange
                    />
                  </div>
                  <div>
                    <Typography>
                      Age Of Property{" "}
                      <ColorText color={theme.colors.error}>*</ColorText>
                    </Typography>
                    <Select
                      options={ageOfProperty}
                      onSelect={(selectedOption) => {
                        setFieldValue("ageOfProperty", selectedOption);
                      }}
                      selected={values.ageOfProperty}
                      onChange
                    />
                  </div>
                  <div>
                    <Typography>Eir Code</Typography>
                    <Input
                      name="eirCode"
                      value={values.eirCode}
                      onChange={(e) => setFieldValue("eirCode", e.target.value)}
                    />
                  </div>
                  <div>
                    <Typography>BER</Typography>
                    <Select
                      options={ber}
                      onSelect={(selectedBER) => {
                        setSelectedBER(selectedBER);
                        setFieldValue("ber", selectedBER);
                      }}
                      selected={values.ber}
                    />
                  </div>
                  <div>
                    <Typography>Home Build Prior to 20</Typography>
                    <Input />
                  </div>
                  <div>
                    <Typography>MPRN</Typography>
                    <Input
                      name="mprn"
                      value={values.mprn}
                      onChange={(e) => setFieldValue("mprn", e.target.value)}
                    />
                  </div>
                </FormWrapper>

                <Typography style={{ marginBottom: "15px" }}>
                  Are you interested in any other renovation works ?
                </Typography>
                <Interested>
                  {interestData.map((data, idx) => (
                    <CheckboxStyled key={idx}>
                      <Checkbox
                        onChange={(e) => handleInterestChange(e, data)}
                        isChecked={interest.includes(data.label)}
                      />
                      {data.label}
                    </CheckboxStyled>
                  ))}
                </Interested>
                <Typography style={{ marginBottom: "15px" }}>
                  Email Preference
                </Typography>
                <CheckboxStyled>
                  <Checkbox
                    onChange={() => setIsNotify(!isNotify)}
                    isChecked={isNotify}
                  />
                  I would like to receive email notifications on any updates
                  from my HouseBuild Retrofit Account.
                </CheckboxStyled>

                <Button
                  style={{
                    marginBottom: "30px",
                  }}
                >
                  Save
                </Button>
              </Form>
            );
          }}
        </Formik>
      </ProfileWrapperStyled>
    </Container>
  );
};

export default Profile;
