import Table from "@shared/designComponents/Table";
import theme from "@shared/theme";
import { additionalTable } from "./grantsData";
import { AdditionalStyled } from "./style";
import { DataType } from "..";

const AdditionalTab = ({ data }: { data: DataType[] }) => {
  return (
    <AdditionalStyled>
      <Table
        data={{
          header: additionalTable.header,
          body: data,
        }}
        style={{ backgroundColor: theme.colors.grayFA }}
      />
    </AdditionalStyled>
  );
};

export default AdditionalTab;
