import {
  VideoSection,
  SubmitInterest,
  HeroSection,
  HomeCarouselSection,
} from "../../components/Home";
import { HotspotsSection } from "../../components/Shared";

const Home = () => {
  return (
    <>
      <HeroSection />
      <VideoSection />
      <HomeCarouselSection />
      <SubmitInterest />
      <HotspotsSection />
    </>
  );
};

export default Home;
