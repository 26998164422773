import { ReactNode, useState } from "react";
import Button from "../Button";
import HotspotSVG from "@web-assets/icons/hotspot.svg";
import styled from "styled-components";
import Modal from "../Modal";
import HotspotModal from "./HotspotModal";

type Props = {
  position: {
    left: string;
    top: string;
  };
  modelContent: {
    image: string;
    title: string;
    description: string;
    onClick: () => void;
  };
  modelOffset?: {
    top: string;
    left: string;
  };
};

function HotSpot({ modelContent, position, modelOffset }: Props) {
  const [enableModal, setEnableModal] = useState(false);

  const handleHotSpotClick = () => {
    setEnableModal(true);
  };

  return (
    <>
      <StyledButton
        onClick={handleHotSpotClick}
        $left={position.left}
        $top={position.top}
      >
        <img
          src={HotspotSVG}
          alt="hot-spot"
          style={{ height: "22px", width: "22px" }}
        />
      </StyledButton>
      <HotspotModal
        open={enableModal}
        onClose={() => setEnableModal(false)}
        description={modelContent.description}
        image={modelContent.image}
        onClick={modelContent.onClick}
        title={modelContent.title}
        offset={modelOffset}
      />
    </>
  );
}

export default HotSpot;

const StyledButton = styled(Button)<{ $top: string; $left: string }>`
  all: unset;
  position: absolute;
  top: ${({ $top }) => $top || "0px"};
  left: ${({ $left }) => $left || "0px"};
  z-index: 1000;
  cursor: pointer;

  &:hover {
    color: transparent;
    background-color: transparent;
  }
`;
