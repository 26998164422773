import { ConfigType } from "../utils/requestAPI";

type LoginData = {
  email: string;
  password: string;
};
export type updateData = {
  token?: string;
  email?: string;
  password: string;
  passwordConfirmation: string;
};

export type changePassword = {
  currentPassword: string;
  newPassword: string;
};

const authConfig = {
  login: (data: LoginData): ConfigType => ({
    method: "post",
    url: "/leads/log-in",
    data,
  }),

  updatePassword: (data: updateData): ConfigType => ({
    method: "post",
    url: "/leads/set-user-password",
    data,
  }),

  changePassword: (data: changePassword): ConfigType => ({
    method: "post",
    url: "/admin-users/change-password",
    data,
  }),
};

export default authConfig;
