import { ReactNode, useState } from "react";
import { TabStyled } from "./style";
import { Typography } from "@shared/designComponents";
import theme from "@shared/theme";

interface Props {
  tabItems: {
    label: string;
    children: ReactNode;
  }[];
  activeTab: number;
  onTabChange: (index: number) => void;
}

export default function Tab({ tabItems, activeTab, onTabChange }: Props) {
  return (
    <>
      <TabStyled>
        {tabItems.map((tabItem, index) => (
          <button
            key={tabItem.label}
            className={`tab-item ${activeTab === index ? "active" : ""}`}
            type="button"
            onClick={() => {
              onTabChange(index);
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              color={
                activeTab === index
                  ? theme.colors.secondary
                  : theme.colors.gray8A
              }
              size={["xs", "xs", "md"]}
              fontWeight="semibold"
              className="title"
            >
              {tabItem.label}
            </Typography>
            <span />
          </button>
        ))}
      </TabStyled>
      <div>{tabItems[activeTab].children}</div>
    </>
  );
}
