import { styled } from "styled-components";

export const WrapperStyled = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const ImageStyled = styled.img`
  height: 325px;
  width: 100%;
  object-fit: cover;
  max-width: 1440px;
`;
