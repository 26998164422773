import { useRef } from "react";
import {
  SectionTitle,
  ColorText,
  Button,
  Container,
  Carousel,
} from "@shared/designComponents";
import { cardItems } from "./cardData";
import theme from "@shared/theme";
import { CarouselWrapperStyled, Section, NavWrapper } from "./style";
import AliceCarousel from "react-alice-carousel";

const HomeCarouselSection = () => {
  const carouselRef = useRef<AliceCarousel>(null);

  const handleNextSlide = () => {
    carouselRef?.current?.slideNext();
  };

  const handlePrevSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  return (
    <Container marginVertical={120}>
      <CarouselWrapperStyled>
        <Section>
          <SectionTitle variant="h2" className="section-header">
            8 steps away from a{" "}
            <ColorText color={theme.colors.primary}> Greener</ColorText> and{" "}
            <ColorText color={theme.colors.yellowGreen}>Warmer </ColorText>
            home.
          </SectionTitle>
          <NavWrapper>
            <Button variant="icon" onClick={handlePrevSlide} reverseIcon />
            <Button variant="icon" onClick={handleNextSlide} />
          </NavWrapper>
        </Section>

        <Carousel carouselRef={carouselRef} items={cardItems} />
      </CarouselWrapperStyled>
    </Container>
  );
};

export default HomeCarouselSection;
