import { Image, Typography } from "@shared/designComponents";
import theme from "@shared/theme";
import { AttachmentStyled, ButtonsStyled } from "./style";
import downloadPNG from "../../../../assets/images/userDashboard/Download.png";

export const reportTable = {
  header: [
    { name: "Title", label: "title" },
    { name: "Document", label: "document" },
    { name: "Updated Date", label: "updated_date" },
    // { name: "Action", label: "action" },
  ],
  body: [
    [
      {
        label: "title",
        value: (
          <div>
            <Typography
              color={theme.colors.footerBlack}
              size={"sm"}
              style={{ marginBottom: "12px" }}
            >
              BER Report{" "}
            </Typography>
          </div>
        ),
      },

      {
        label: "document",
        value: (
          <AttachmentStyled>
            <Typography size={"sm"}> Test_PDF.pdf</Typography>
            <Image src={downloadPNG} width={14} height={14} />
          </AttachmentStyled>
        ),
      },

      {
        label: "updated_date",
        value: (
          <div>
            <Typography size={"sm"}>03/27/2023 02:03 pm</Typography>
          </div>
        ),
      },
      {
        label: "action",
        value: (
          <>
            <ButtonsStyled>
              <Typography
                size={"sm"}
                fontWeight="normal"
                color={theme.colors.white}
              >
                View{" "}
              </Typography>
            </ButtonsStyled>
          </>
        ),
      },
    ],
  ],
};
