import styled from "styled-components";
import Typography from "../Typography";
import { screenSizes } from "../../theme/screen";

export const Typo = styled(Typography)`
  font-size: ${({ theme }) => theme.sizes.xl};
  line-height: 30px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};

  @media (min-width: ${screenSizes.md}) {
    font-size: ${({ theme }) => theme.sizes._4xl};
    line-height: 48px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  @media (min-width: ${screenSizes.xl}) {
    font-size: ${({ theme }) => theme.sizes._5xl};
    line-height: 60px;
  }
`;
