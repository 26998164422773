import IndividualCardSection from "./individualCardSection";
import insulationPNG from "../../../assets/images/homeCardIndividualPage-img/insulation.png";
import { ColorText, Typography } from "@shared/designComponents";
import { useTheme } from "styled-components";
import { EnergyfixCTASection } from "../../Shared";

const InsulationCard = () => {
  const theme = useTheme();
  return (
    <div>
      <IndividualCardSection
        imgUrl={insulationPNG}
        sn="02"
        title="Insulation"
        description={
          <>
            <Typography size={"sm"} style={{ marginBottom: 20 }}>
              Upgrading insulation can significantly decrease heating expenses
              and enhance warmth. Upgrading your roof or attic insulation can
              cut heat loss by up to 30%, making it one of the most
              cost-effective energy-saving measures. Avail yourself of the SEAI
              grant for attic insulation, offering support ranging from €800 to
              €1,500.
            </Typography>
            <Typography size={"sm"}>
              Insulate your external walls to reduce heat loss by up to
              <Typography fontWeight="semibold" variant="span">
                <ColorText color={theme.colors.primary}> 35%</ColorText>
              </Typography>
              . Cavity, external and/or internal insulation can be used.
            </Typography>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Typography size={["md", "md", "lg"]} fontWeight="semibold">
                <ColorText color={theme.colors.secondary}>
                  The SEAI grant available range from
                </ColorText>
                <Typography fontWeight="bold" variant="span">
                  <ColorText color={theme.colors.primary}>
                    {" "}
                    €700 - €8,000
                  </ColorText>
                </Typography>
                .
              </Typography>
            </div>
          </>
        }
      />
      <EnergyfixCTASection />
    </div>
  );
};

export default InsulationCard;
