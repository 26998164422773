import { Link } from "react-router-dom";
import styled from "styled-components";

export const WrapperStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export const IconWrapper = styled(Link)`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;
