import { ReactNode, useEffect, useRef, useState } from "react"
import { StyledDropdownWrapper } from "./style";


type TypeDropdown = {
    trigger: ReactNode,
    children: ReactNode,
    className?: {trigger?: string; children?: string;}
}

export default function Dropdown({trigger, children, className} : TypeDropdown) {
    const [show, setShow] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const listener = (e : Event) => {
            if(!dropdownRef.current) return;

            if(!dropdownRef.current.contains(e.target as Node)) {
                setShow(false);
            }
        }
        
        window.addEventListener("mousedown", listener);

        return () => {
            window.addEventListener("mousedown", listener);
        }
    }, [])

  return (
    <StyledDropdownWrapper ref={dropdownRef}>
        <div className={`dropdown-trigger ${className?.trigger ?? "" }`} onClick={() => setShow(!show)}>
            {trigger}
          </div>
          
        {show && <div className={`dropdown-children ${className?.children ?? "" }`} onClick={() => setShow(!show)}>
            {children}
        </div>}

    </StyledDropdownWrapper>
  )
}
