import { useRef, useState } from "react";
import {
  Button,
  ColorText,
  SectionTitle,
  Typography,
} from "@shared/designComponents";
import Container from "@shared/designComponents/Container";
import theme from "@shared/theme";
import {
  CustomerCharterDataType,
  customerCharterData,
} from "./CustomerCharterData";
import { CustomerCharterWrapperStyle, MainWrapperStyle } from "./style";

const CustomerCharter: React.FC = () => {
  const [activeCharter, setActiveCharter] = useState<number>(1);

  const handleClick = (id: number) => {
    setActiveCharter(id);
    const ref = refs[id];

    window.scrollTo({
      top: ref.current?.offsetTop,
      behavior: "smooth",
    });
  };

  const refs: { [key: number]: React.RefObject<HTMLDivElement> } =
    customerCharterData.reduce((acc, item) => {
      acc[item.id] = useRef<HTMLDivElement>(null);
      return acc;
    }, {} as { [key: number]: React.RefObject<HTMLDivElement> });

  return (
    <Container marginVertical={50}>
      <MainWrapperStyle className="main-wrapper">
        <div className="large-device-content">
          <Typography size="md" fontWeight="medium">
            Table of contents
          </Typography>
          {customerCharterData.map((item) => (
            <Button
              variant="link"
              onClick={() => handleClick(item.id)}
              key={item.id}
            >
              <Typography
                size="sm"
                fontWeight="normal"
                color={
                  activeCharter === item.id
                    ? theme.colors.primary
                    : theme.colors.secondary
                }
              >
                {item.title}
              </Typography>
            </Button>
          ))}
        </div>
        <div>
          <SectionTitle className="section-title">
            Customer <ColorText color={theme.colors.primary}>Charter</ColorText>
          </SectionTitle>
          <CustomerCharterWrapperStyle>
            <div className="container">
              <Typography size="md" fontWeight="medium">
                Table of contents
              </Typography>
              {customerCharterData.map((item) => (
                <Button
                  variant="link"
                  onClick={() => handleClick(item.id)}
                  key={item.id}
                >
                  <Typography
                    size="sm"
                    fontWeight="normal"
                    color={
                      activeCharter === item.id
                        ? theme.colors.primary
                        : theme.colors.secondary
                    }
                  >
                    {item.title}
                  </Typography>
                </Button>
              ))}
            </div>
          </CustomerCharterWrapperStyle>
          <div className="content">
            {customerCharterData.map((item: CustomerCharterDataType) => (
              <div key={item.id} className="title-content" ref={refs[item.id]}>
                <Typography size="lg" fontWeight="normal">
                  {item.title}
                </Typography>
                <Typography size="sm" fontWeight="light">
                  {item.content}
                </Typography>
              </div>
            ))}
            <div>
              <Typography size="sm" fontWeight="bold">
                or send us a letter to
              </Typography>
              <Typography size="sm" fontWeight="light">
                36 Mount Street Upper
              </Typography>
              <Typography size="sm" fontWeight="light">
                D02 X5F7
              </Typography>
            </div>
          </div>
        </div>
      </MainWrapperStyle>
    </Container>
  );
};

export default CustomerCharter;
