import wholeHousePNG from "../../assets/images/ourServicesImg/Whole-house-retrofit.png";
import energyFixHousePNG from "../../assets/images/ourServicesImg/Enegryfix-House.png";
import commercialPNG from "../../assets/images/ourServicesImg/Commerical-Image.png";

const service = {
  key: "our-services",
  title: "OUR SERVICES",
  description:
    "As a One Stop Shop, EnergyFix offers tailored services to meet the unique needs of homeowners across Ireland. All our services are conducted by our in-house team of experienced retrofit advisors, experts, and engineers. With their expertise, we aim to make your home warmer, more comfortable, and energy-efficient, resulting in reduced carbon emissions. Our dedicated team also assists you in accessing the very best energy upgrade grants provided by SEAI (Sustainable Energy Authority of Ireland).",
  quote:
    "We aim to make your home warmer, more comfortable, and energy-efficient, resulting in reduced carbon emissions.",
  section: [
    {
      title: "Whole House Retrofit",
      description:
        "Our whole house retrofit service is specifically designed for houses constructed before 2005. To improve your home’s Building Energy Rating (BER), we begin with a comprehensive home survey. This survey enables us to gather detailed information about your home and tailor a retrofit plan accordingly. We then provide you with a fully costed Energy Report outlining the best value-for-money energy upgrades for your entire home. The installation of an air source heat pump, which serves as a core element, may be complemented by improvements like wall & attic insulation, windows, airtightness, advanced ventilation and Solar PV.",
      image: wholeHousePNG,
    },
    {
      title: "Multiples",
      subtitle: "Local Authority & Approved Housing Bodies",
      description:
        "For approved housing bodies and non-corporate landlords, HouseBuild Energy Retrofit can provide a full one-stop shop service that can avail of the SEAI grants for houses that were built and occupied before 2011 and have an existing BER of B3 or lower. SEAI grant-supported retrofit measures include insulation upgrades (including roofs, walls,and floors), replacement doors and windows, mechanical ventilation, airtightness, solar PV, and heat pumps.",
      image: energyFixHousePNG,
    },
    {
      title: "Commercial Retrofit",
      description:
        "With a proven track record in the delivery of retrofitting, refurbishment and conservation projects across all sectors Energyfix is your go to partner when upgrading your current commercial building. At Energyfix we value the importance of upgrading current buildings to meet the current building regulations to provide significant economic, environmental, and social benefits to building owners, occupants, and society. Among the measures typically taken during a deep energy retrofit upgrade to insulation, HVAC systems, lighting, windows, and doors, as well as the installation of renewable energy systems.",
      image: commercialPNG,
    },
  ],
};

export default service;
