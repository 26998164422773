import { Link } from "react-router-dom";
import image1 from "../../../assets/images/Rectangle-259.png";
import image2 from "../../../assets/images/Rectangle-260.png";
import image3 from "../../../assets/images/Rectangle-261.png";
import Button from "@shared/designComponents/Button";
import Container from "@shared/designComponents/Container";
import SectionTitle from "@shared/designComponents/SectionTitle";
import ColorText from "@shared/designComponents/SectionTitle/ColorText";
import theme from "@shared/theme";
import {
  Description,
  RetrofitBannerStyle,
  RetrofitBannerWrapperStyle,
} from "./style";
import { REGISTER_INTEREST_PATH } from "../../../routes/routePaths";

const RetrofitBanner = () => {
  return (
    <Container marginVertical={50}>
      <RetrofitBannerWrapperStyle>
        <SectionTitle>
          What is{" "}
          <ColorText color={theme.colors.primary}>Retrofitting</ColorText>?
        </SectionTitle>
        <RetrofitBannerStyle>
          <div className="img-wrapper">
            <img src={image1} alt="Retrofit-Image1" className="img-1" />
            <img src={image2} alt="Retrofit-Image2" className="img-2" />
            <img src={image3} alt="Retrofit-Image3" className="img-3" />
          </div>
          <div className="container">
            <Description>
              Retrofitting is the process of upgrading existing buildings to
              improve energy efficiency, comfort, and sustainability. It
              involves making strategic improvements to insulation, ventilation,
              heating and cooling systems, and overall energy usage. By
              retrofitting your home, you can reduce energy consumption, lower
              utility bills, and create a more comfortable living environment
              for you and your family. This can include installing
              energy-efficient windows and doors, upgrading insulation, sealing
              air leaks, installing more efficient heating and cooling systems
              such as heat pumps, and integrating renewable energy sources like
              solar panels. Retrofitting is an important strategy for reducing
              carbon emissions and improving the sustainability of buildings,
              particularly older ones that may not meet modern energy efficiency
              standards.
            </Description>
            <Link to={REGISTER_INTEREST_PATH}>
              <Button variant="linkWithArrow">Enquire Now</Button>
            </Link>
          </div>
        </RetrofitBannerStyle>
      </RetrofitBannerWrapperStyle>
    </Container>
  );
};

export default RetrofitBanner;
