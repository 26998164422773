import Table from "@shared/designComponents/Table";
import theme from "@shared/theme";
import { certificateTable } from "./grantsData";
import { CertificateStyled } from "./style";
import { DataType } from "..";

const CertificateTab = ({ data }: { data: DataType[] }) => {
  return (
    <CertificateStyled>
      <Table
        data={{
          header: certificateTable.header,
          body: data,
        }}
        style={{ backgroundColor: theme.colors.grayFA }}
      />
    </CertificateStyled>
  );
};

export default CertificateTab;
