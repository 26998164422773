import { HTMLAttributes } from "react";
import { css, styled } from "styled-components";
import { ThemeType } from "../../theme";

export interface IButtonProps extends HTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "link" | "linkWithArrow" | "icon";
  reverseIcon?: boolean;
  size?: "sm" | "md";
  isDisabled?: boolean;
  fullWidth?: boolean;
}

const getSize = (theme: ThemeType, size: string, variant: string) => {
  if (variant === "icon") {
    return `
      height: ${size === "sm" ? "32px" : "42px"};
      width: ${size === "sm" ? "32px" : "42px"};`;
  }
  switch (size) {
    case "sm":
      return `
        font-size: ${theme.sizes.xs};
        line-height: 1.125rem;
        height: 34px;
      `;
    case "md":
      return `
        font-size: ${theme.sizes.md};
        line-height: 1.5rem;
        height: 44px;
      `;
    default:
      return `
        font-size: ${theme.sizes.md};
        line-height: 1.5rem;
        height: 44px;
      `;
  }
};

const getColor = (theme: ThemeType, variant: IButtonProps["variant"]) => {
  switch (variant) {
    case "link":
      return theme.colors.primary;
    case "linkWithArrow":
      return theme.colors.secondary;
    default:
      return theme.colors.white;
  }
};

const getHoverColor = (theme: ThemeType, variant: IButtonProps["variant"]) => {
  switch (variant) {
    case "link":
      return theme.colors.primaryHover;
    case "linkWithArrow":
      return theme.colors.secondaryHover;
    default:
      return theme.colors.white;
  }
};

const getBackgroundColor = (
  theme: ThemeType,
  variant: IButtonProps["variant"]
) => {
  switch (variant) {
    case "primary":
      return theme.colors.primary;
    case "link":
      return theme.colors.transparent;
    case "linkWithArrow":
      return theme.colors.transparent;
    case "icon":
      return theme.colors.primary;
    default:
      return theme.colors.primary;
  }
};

const getBackgroundHoverColor = (
  theme: ThemeType,
  variant: IButtonProps["variant"]
) => {
  switch (variant) {
    case "primary":
      return theme.colors.primaryHover;
    case "icon":
      return theme.colors.primaryHover;
  }
};

export const StyledButton = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
  outline: none;
  border: none;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  color: ${({ theme, variant }) => getColor(theme, variant)};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  border-radius: ${({ variant }) =>
    variant === "icon" ? "50%" : variant === "primary" ? "4px" : "0"};
  background-color: ${({ theme, variant }) =>
    getBackgroundColor(theme, variant)};
  padding: ${({ variant }) => (variant === "linkWithArrow" ? "0" : "5px 12px")};
  border-bottom: ${({ theme, variant }) =>
    variant === "linkWithArrow" && `1px solid ${theme.colors.secondary}`};
  ${({ theme, size, variant }) =>
    getSize(theme, size || "md", variant || "primary")};

  svg > path {
    fill: ${({ theme, variant }) =>
      variant === "icon"
        ? `${theme.colors.white}`
        : `${theme.colors.secondary}`};
  }

  &:hover {
    color: ${({ theme, variant }) => getHoverColor(theme, variant)};
    background-color: ${({ theme, variant }) =>
      getBackgroundHoverColor(theme, variant)};
    svg > path {
      fill: ${({ theme, variant }) =>
        variant === "linkWithArrow" && `${theme.colors.secondaryHover}`};
    }
  }

  &:disabled {
    background-color: ${({ theme, variant }) =>
      variant === "primary" || variant === "icon"
        ? theme.colors.disableButtonText
        : ""};

    color: ${({ theme, variant }) =>
      variant === "link" ? theme.colors.disableButtonText : ""};
  }

  ${({ reverseIcon, variant }) =>
    reverseIcon &&
    variant === "icon" &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;
