import { useTheme } from "styled-components";
import { Button, ColorText, SectionTitle } from "@shared/designComponents";
import { RetroBottomStyle, Typo } from "./style";
import { Link } from "react-router-dom";
import { REGISTER_INTEREST_PATH } from "../../../routes/routePaths";

const EnergyfixCTASection = () => {
  const theme = useTheme();
  return (
    <RetroBottomStyle>
      <div className="container">
        <div className="wrapper">
          <div className="text-wrapper">
            <SectionTitle>
              Ready to{" "}
              <ColorText color={theme.colors.secondary}>Transform</ColorText>{" "}
              your <ColorText color={theme.colors.primary}> Home</ColorText>?
            </SectionTitle>
            <Typo fontWeight="light" size="md">
              Let’s begin your
              <ColorText color={theme.colors.primary}>
                {" "}
                EnergyFix{" "}
              </ColorText>{" "}
              Journey.
            </Typo>
          </div>
          <div className="links">
            <Link to={REGISTER_INTEREST_PATH} className="link-text">
              <Button variant="linkWithArrow">Get Started</Button>
            </Link>
            <img src="/assets/images/hand.png" alt="hand" className="img" />
          </div>
        </div>
      </div>
    </RetroBottomStyle>
  );
};

export default EnergyfixCTASection;
