import GrantsTable from "../../components/CostAndGrants/SEAIGrants/GrantsTable";
import SEAIGrantsHeader from "../../components/CostAndGrants/SEAIGrants/SEAIGrantsHeader";

export default function CostAndGrants() {
  return (
    <>
      <SEAIGrantsHeader />
      <GrantsTable />
    </>
  );
}
