import {
  AboutSection,
  CardSection,
  ImageSection,
} from "../../components/About";

const AboutUs = () => {
  return (
    <>
      <AboutSection />
      <ImageSection />
      <CardSection />
    </>
  );
};

export default AboutUs;
