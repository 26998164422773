import { ConfigType } from "../utils/requestAPI";

const subscriptionConfig = {
  subscriptionLink: (email: string): ConfigType => ({
    method: "post",
    url: `/newsletters`,
    data: {
      email,
    },
  }),
};

export default subscriptionConfig;
