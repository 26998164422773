import Table from "@shared/designComponents/Table";
import theme from "@shared/theme";
import { DataType } from "..";
import { reportTable } from "./reportData";
import { ReportStyled } from "./style";

const ReportTab = ({ data }: { data: DataType[] }) => {
  return (
    <ReportStyled>
      <Table
        data={{
          header: reportTable.header,
          body: data,
        }}
        style={{ backgroundColor: theme.colors.grayFA }}
      />
    </ReportStyled>
  );
};
export default ReportTab;
